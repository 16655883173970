import React, { createRef, forwardRef, useEffect, useState } from 'react'
import type { ComponentProps, ReactNode, Ref } from 'react'

import './Accordion.styles.scss'


type AccordionProps = {
  label: string,
  children: ReactNode,
  opened?: boolean
} & ComponentProps<'div'>

export const Accordion = ({
  label,
  children,
  opened: _opened = false,
  
  className,
  ...others
}: AccordionProps) => {
  const [ opened, setOpened ] = useState(_opened)
  const contentRef = createRef<HTMLDivElement>()

  const resizeListener = () => {
    contentRef.current.style.height = `${contentRef.current.scrollHeight}px`
  }

  useEffect(() => {
    if(opened) {
      contentRef.current.style.height = `${contentRef.current.scrollHeight + 20}px`
      contentRef.current.style.paddingBottom = '20px'

      window.addEventListener('resize', resizeListener)
    } else {
      contentRef.current.style.height = '0px'
      contentRef.current.style.paddingBottom = null

      window.removeEventListener('resize', resizeListener)
    }

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [ opened ])

  return (
    <div className={`AccordionContainer ${className || ''}`} {...others}>
      <div className='Toggle' onClick={() => setOpened(!opened)}>
        {label}
        
        <button className={opened ? 'opened' : ''} />
      </div>

      <div className='Content' ref={contentRef}>
        {children}
      </div>
    </div>
  )
}
