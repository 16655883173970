import { Page } from '../Page';
import './MintHomePage.scss';

import MintHomeLayout from './layouts/MintHomeLayout';
import { Carousel } from 'react-responsive-carousel';
import ItemFeatured, { ItemFeaturedProps } from './components/ItemFeatured';
import Item from './components/Item';
import Banner from './components/Banner';
import { useEffect, useState } from 'react';
import Sale from '@common/Sale';
import { TransitioningSection } from '@components/transitioning-section';
import { mainSuite } from '@services/ServiceFactory';
import { SaleDateFlag, getSaleDateFlagsFromTime, salePrioWeightSorter } from '@common/SaleUtils';
import { SaleState } from '@common/SaleState';
import { fetchSaleState } from '@common/SalePicker';
import { debug } from '@common/LogWrapper';

const log = debug('app:pages:MintHomePage');

type SaleItemTypeMap = Record<string, ItemFeaturedProps['type']>;

export function MintHomePage() {
  const { saleService } = mainSuite;
  const defaultUserUrl = 'https://opensea.io/collection/storyverse-founders-pass';

  const [featuredSales, setFeaturedSales] = useState<Sale[]>(null);
  const [sales, setSales] = useState<Sale[]>(null);
  const [saleItemTypeMap, setSaleItemTypeMap] = useState<SaleItemTypeMap>(null);

  useEffect(() => {
    async function loadSales() {
      // only collections
      const fullList = await saleService.fetchAllSales();
      log('fullList length', fullList.length);
      const collectionSales = fullList.filter((sale) => sale.saleType === 'collection');

      log('collectionSales length', collectionSales.length);
      const sortedSales = salePrioWeightSorter(collectionSales);
      log('sortedSales length', sortedSales.length);
      const typeMap: SaleItemTypeMap = {};
      const availableSales: Sale[] = [];

      for (const sale of sortedSales) {
        const saleDateFlags = getSaleDateFlagsFromTime(sale);
        // only show "during" sales
        switch (saleDateFlags) {
          case SaleDateFlag.DuringWhitelistSignUp:
            typeMap[sale.saleId] = 'register';
            availableSales.push(sale);
            break;
          case SaleDateFlag.DuringPublicMint:
          case SaleDateFlag.DuringWhitelistMint:
            typeMap[sale.saleId] = 'live';
            availableSales.push(sale);
            break;
        }
      }
      log('availableSales length', availableSales.length);

      log('availableSales', availableSales);

      // featured
      const fSales = availableSales.filter((sale) => sale.featured);

      log('fSales length', fSales.length);

      setSaleItemTypeMap(typeMap);
      setFeaturedSales(fSales);
      setSales(availableSales);
    }

    loadSales();
  }, []);

  const loaded = Boolean(sales);
  const title = `Collect & play to watch your story unfold`;

  return (
    <Page className="container mb-5" title="Collectible Stories" introBg>
      <MintHomeLayout className="home-state-page-layout" title="Storyverse" subtitle={title}>
        {loaded && (
          <div className="home-layout-columns">
            <div className="home-layout-column">
              <h3 className="home-layout-column-title live-header">Live</h3>
              <Carousel showThumbs={false} showStatus={false} autoPlay={true} interval={4000} infiniteLoop={true} swipeable={true} emulateTouch={true}>
                {featuredSales.map((sale) => (
                  <ItemFeatured
                    key={sale.saleId}
                    id={sale.saleId}
                    title={sale.saleName}
                    userUrl={defaultUserUrl}
                    mint="OPEN MINT"
                    coll=""
                    type={saleItemTypeMap[sale.saleId]}
                  />
                ))}
              </Carousel>

              <Banner />

              <div className="home-layout-items">
                <h3 className="home-layout-column-title">Featured</h3>
                {sales.map((sale) => (
                  <Item
                    key={sale.saleId}
                    id={sale.saleId}
                    title={sale.saleName}
                    userUrl={defaultUserUrl}
                    mint="OPEN MINT"
                    coll=""
                    type={saleItemTypeMap[sale.saleId]}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
        {!loaded && <TransitioningSection />}
      </MintHomeLayout>
    </Page>
  );
}
