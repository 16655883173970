import { HostedApp } from '@components/hosted-app/HostedApp';
import { environment } from '@environment';
import { usePassthroughParams } from '@hooks/usePassthroughParams';
import { Page } from '../Page';
import './ViewPage.scss';
import { useAppUrl } from '@hooks/useAppUrl';
import { GameSiteUrlParams } from '@storyverseco/svs-consts';
import { useParamsToSearchParams } from '@hooks/useParamsToSearchParams';
import LoadingVideoOverlay from '@components/loading-video-overlay/LoadingVideoOverlay';
import { useEffect, useMemo, useState } from 'react';
import { mainSuite } from '@services/ServiceFactory';
import { useUserHook } from '@hooks/useUserHook';
import { LoadingVideo, LoadingVideoRequestedOpts, SvsNavbarEvent, SvsProvider } from '@storyverseco/svs-navbar';
import { S3 } from '@storyverseco/svs-consts';
import { getConfig } from '@common/GetConfig';
import { posters } from '@assets/mint/story-posters';
import { GameMintBox } from '@components/MintBox/GameMintBox';
import { MintActionType, useMintDispatch, useMintState } from '@context/mint/MintContext';
import { fetchSaleState } from '@common/SalePicker';

const predefinedSearchParams = [{ key: 'iframed', value: '1' }];

// page param to search param mapping for story links
const storyMapping = {
  [GameSiteUrlParams.WalletAddress]: GameSiteUrlParams.WalletAddress,
  [GameSiteUrlParams.StoryId]: GameSiteUrlParams.StoryId,
  [GameSiteUrlParams.TokenID]: GameSiteUrlParams.TokenID,
  [GameSiteUrlParams.DeeplinkTimelineId]: GameSiteUrlParams.DeeplinkTimelineId,
  [GameSiteUrlParams.DeeplinkStoryId]: GameSiteUrlParams.DeeplinkStoryId,
  [GameSiteUrlParams.DeeplinkChoiceId]: GameSiteUrlParams.DeeplinkChoiceId,
};

interface ViewPageProps {
  walletAddress?: string;
  storyId?: string;
  isNFT?: boolean;
  isAi?: boolean;
}
// allows empty strings, since empty strings are falsy
function isStrDefined(str: string | null) {
  return typeof str === 'string';
}

export function ViewPage(props: ViewPageProps) {
  const navbarBridge = mainSuite.navbarService;
  const [showLoadingVideo, setShowLoadingVideo] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState<LoadingVideo | string | null>(null);
  const [deferNftStoryLoading, setDiferNftStoryLoading] = useState(props.isNFT);
  const [nftVideo, setNftVideo] = useState<{ src: string; poster?: string }>();

  const { sale } = useMintState();
  const mintDispatch = useMintDispatch();

  // Not sure what this does on its own
  useUserHook({ providerType: SvsProvider.WalletConnect });

  // convert page params to search params
  let convertedParams = useParamsToSearchParams(storyMapping);

  // combine search params
  const includedParams = useMemo(() => {
    let updatedParams = [...convertedParams];

    // wallet address override
    if (props.walletAddress) {
      const newParam = { key: GameSiteUrlParams.WalletAddress, value: props.walletAddress };
      const index = updatedParams.findIndex((param) => param.key === GameSiteUrlParams.WalletAddress);
      if (index === -1) {
        updatedParams.push(newParam);
      } else {
        // we're replacing the item, not changing its value, to avoid possible side effects
        updatedParams[index] = newParam;
      }
    }

    // story ID override
    if (props.storyId) {
      const newParam = { key: GameSiteUrlParams.StoryId, value: props.storyId };
      const index = updatedParams.findIndex((param) => param.key === GameSiteUrlParams.StoryId);
      if (index === -1) {
        updatedParams.push(newParam);
      } else {
        // we're replacing the item, not changing its value, to avoid possible side effects
        updatedParams[index] = newParam;
      }
    }

    if (props.isNFT) {
      updatedParams = [...updatedParams, { key: GameSiteUrlParams.Nft, value: 'true' }];
    }

    if (props.isAi) {
      updatedParams = [...updatedParams, { key: GameSiteUrlParams.Ai, value: 'true' }];
    }

    return [...updatedParams, ...predefinedSearchParams];
  }, [props, JSON.stringify(convertedParams)]);

  // combine passthrough params with page's search params
  const searchParamsStr = usePassthroughParams(environment.passthroughParams, includedParams);

  const { authorAddress, storyId, tokenId } = useMemo(() => {
    if (!searchParamsStr) {
      return {};
    }
    const params = new URLSearchParams(searchParamsStr);
    const authorAddress = params.get(GameSiteUrlParams.WalletAddress);
    const storyId = params.get(GameSiteUrlParams.StoryId);
    const tokenId = params.get(GameSiteUrlParams.TokenID);
    return {
      authorAddress,
      storyId,
      tokenId,
    };
  }, [searchParamsStr]);

  // If we don't have a sale for nft viewer, grab it using query param values
  useEffect(() => {
    if (!props.isNFT || !authorAddress || !storyId || Boolean(sale?.saleId)) {
      return;
    }

    const loadSale = async () => {
      const sale = await mainSuite.saleService.fetchSaleBy({ authorAddress, storyId });
      if (!sale) {
        console.error(`Error (getVideoSettings): Could not find sale for authorAddress '${authorAddress}' and storyId '${storyId}'. Skipping...`);
        return;
      }
      const saleState = await fetchSaleState(sale, mainSuite.saleService);
      mintDispatch({
        type: MintActionType.UpdateSale,
        sale,
        saleState,
      });
    };

    if (tokenId) {
      loadSale();
    } else {
      setDiferNftStoryLoading(false);
    }
  }, [sale?.saleId, authorAddress, storyId, tokenId]);

  // appUrl override if enabled
  const url = useAppUrl(environment.viewUrl, searchParamsStr);

  useEffect(() => {
    async function onLoadingVideoEvent(opts: LoadingVideoRequestedOpts) {
      if (!opts) {
        return;
      }

      if (opts.show) {
        setLoadingVideo(opts.loadingVideo ?? null);
      }
      setShowLoadingVideo(opts.show);
    }

    navbarBridge.on(SvsNavbarEvent.LoadingVideoRequested, onLoadingVideoEvent);

    return () => {
      navbarBridge.off(SvsNavbarEvent.LoadingVideoRequested, onLoadingVideoEvent);
    };
  }, [setShowLoadingVideo, navbarBridge, setLoadingVideo]);

  // Handle getting the correct video to show for story nft (if any)
  useEffect(() => {
    if (!deferNftStoryLoading || !sale?.saleId) {
      return;
    }

    const getSettings = async () => {
      const config = await getConfig();
      const baseUrl = `${config.globals.urls.media}/media/${authorAddress}/${storyId}`;
      const settings = {
        src: `${baseUrl}/${S3.media.files.INTRO_VIDEO_FILE}`,
        poster: posters[sale.saleId],
      };

      setShowLoadingVideo(true);
      setNftVideo(settings);
      setDiferNftStoryLoading(false);
    };

    getSettings();
  }, [deferNftStoryLoading, sale?.saleId]);

  // only allow HostedApp to be shown if url and searchParamsStr are defined
  // to prevent loading twice
  return (
    <Page title="Viewer" className="view-page no-scroll" hideFooter fullHeight hideNavbar analyticsManualEntryFinal>
      {url && isStrDefined(searchParamsStr) && !deferNftStoryLoading && <HostedApp src={url} />}
      <LoadingVideoOverlay show={showLoadingVideo} video={loadingVideo} loop nftVideoSettings={nftVideo} />
      {sale?.saleId && <GameMintBox />}
    </Page>
  );
}
