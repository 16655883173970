import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import type { ComponentProps } from 'react'

import './Link.styles.scss'


type LinkProps = ComponentProps<typeof RouterLink>

export const Link = ({
  children,
  className,
  ...others
}: LinkProps) => (
  <RouterLink className={`Link ${className ? className : ''}`} {...others}>
    {children}
  </RouterLink>
)
