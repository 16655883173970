const LS_CAPABILITY_KEY = 'svs.mainSite.lsCapability:1.0.0';

/**
 * Backup for local storage
 */
class MemLocalStorage implements Storage {
  private memory: Record<string, string> = {};

  get length(): number {
    return Object.keys(this.memory).length;
  }

  clear(): void {
    this.memory = {};
  }
  getItem(key: string): string {
    return this.memory[key];
  }
  key(index: number): string {
    const keys = Object.keys(this.memory);
    return keys[index];
  }
  removeItem(key: string): void {
    delete this.memory[key];
  }
  setItem(key: string, value: string): void {
    this.memory[key] = value;
  }
}

/**
 * Safe local storage class to swap to a memory-based local storage
 * if window.localStorage is not available.
 */
class SafeLocalStorage implements Storage {
  private storage: Storage;

  constructor() {
    try {
      window.localStorage.setItem(LS_CAPABILITY_KEY, 'capable');
      // local storage test passed
      this.storage = window.localStorage;
    } catch (e) {
      console.warn('window.localStorage not available, triggered by error:', e);
      this.storage = new MemLocalStorage();
    }
  }

  get length(): number {
    return this.storage.length;
  }

  clear(): void {
    this.storage.clear();
  }
  getItem(key: string): string {
    return this.storage.getItem(key);
  }
  key(index: number): string {
    return this.storage.key(index);
  }
  removeItem(key: string): void {
    return this.storage.removeItem(key);
  }
  setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }
}

/**
 * localStorage that is safe to use, even when window.localStorage
 * is not available (either Safari private browsing, sandboxed iframe, etc.).
 * Swaps to a memory-based storage to keep some caching working.
 */
export const safeLocalStorage = new SafeLocalStorage();

export default safeLocalStorage;
