import type { MintPageState } from '@context/mint/MintContext';

export type TpToken = {
  id: string;
  qty: number;
};

export type TpAccount = {
  address: string;
  tokens: Record<string, TpToken[]>;
};

export enum TpStatus {
  Idle = 'idle',
  Authenticated = 'authenticated',
  Rejected = 'rejected',
  Closed = 'closed',
  Unknown = 'unknown',
}

export enum TpReason {
  Idle = 'idle',
  AuthenticatedAccount = 'authenticated_account',
  IneligibleCredential = 'ineligible_credential',
  ExpiredSession = 'expired_session',
  RevokedCredential = 'revoked_credential',
  TamperedCredential = 'tampered_credential',
  ModalClosed = 'modal_closed',
  Unknown = 'unknown',
}

export function isTpToken(obj: any): obj is TpToken {
  return obj && typeof obj === 'object' && typeof obj.id === 'string' && typeof obj.qty === 'number';
}

export function isTpAccount(obj: any): obj is TpAccount {
  return obj && typeof obj === 'object' && typeof obj.address === 'string' && typeof obj.tokens === 'object' && Object.values(obj.tokens).every(isTpToken);
}

export interface TokenproofService {
  init(): Promise<void>;
  isLoggedIn(): boolean;
  getAccount(address?: string): TpAccount | null;
  getAccountMap(): Record<string, TpAccount>;
  getNonce(): string | null;
  getStatus(): string | null;
  getReason(): string | null;
  login(opts?: {
    appId?: string;
    env?: string;
    mintPageState?: MintPageState;
    tokenType?: string;
    policyId?: string;
    force?: boolean;
  }): Promise<TpAccount | null>;
  logout(): Promise<void>;
}
