import { waitForMs } from '@common/WaitUtils';
import { environment } from '@environment';
import { MockStorageService } from '../mock/MockStorageService';
import {
  ClaimPayload,
  ClaimResponse,
  FetchClaimStatusPayload,
  FetchClaimStatusResponse,
  MBMintPayload,
  MBMintResponse,
  SaleService,
  TpHolding,
  TpSignMintPayload,
  TpSignMintResponse,
  TpWhitelistPayload,
  TpWhitelistResponse,
} from './SaleService';
import { DebounceWaiter } from '@common/DebounceWaiter';
import { debug } from '@common/LogWrapper';
import { waitForTx } from '../mock/MockTxScreen';
import { AlreadyClaimedError, MintingError, NotWinnerError, SignForMintError, WaitForMintingOfError } from '@src/errors';
import type Sale from '@common/Sale';
import { SaleState } from '@common/SaleState';
import { salePrioWeightPicker } from '@common/SalePicker';
import type { MockSale } from '@common/Sale';
import { getConfig } from '@common/GetConfig';
import { extendSales, mergeData } from '@common/SaleUtils';
import { WalletAddress } from '@storyverseco/svs-types';

const log = debug('app:service:MockSaleService');

const mockStoryKey = 'stories/0x0/42bd692d5322f1780fdf732bf9dfed4e8ba666e716ea500488210455a94b5f80.json';
const mockContractAddress = '0xMockContractAddress';
const mockMintedContractAddress = '0xMockMintedAddress';

let nftIdPool = 0;

function generateNft() {
  const id = `${nftIdPool++}`;
  const contractAddress = mockContractAddress;
  return {
    id,
    contractAddress,
    imageUrl: `https://picsum.photos/seed/${id}-${contractAddress}/500`,
  };
}

function generateImageUrl(id: string, tokenType: string) {
  return `https://picsum.photos/seed/${id}-${tokenType}/500`;
}

function generateMinted() {
  const id = `${nftIdPool++}`;
  const contractAddress = mockMintedContractAddress;
  return {
    id,
    contractAddress,
  };
}

enum MockSaleParam {
  ErrorOnSign = 'errorOnSign',
  ErrorOnMinting = 'errorOnMinting',
  ErrorOnWaitingForMintOf = 'errorOnWaitingForMintOf',
  NotWinner = 'notWinner',
  Claimed = 'claimed',
}

function hasParam(param: string) {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.forEach((value, key) => {
    urlParams.set(key.toLowerCase(), value);
  });
  return urlParams.has(param.toLowerCase());
}

export class MockSaleService implements SaleService {
  private sales: MockSale[] = null;
  private saleMap: Record<string, MockSale> = null;

  private whitelist = new Set<string>();

  private fetchCurSaleWaiter = new DebounceWaiter<{ sale: Sale; saleState: SaleState }>();
  private addToWlWaiter = new DebounceWaiter<TpWhitelistResponse>();
  private signForMintWaiter = new DebounceWaiter<TpSignMintResponse>();
  private mintWithSigWaiter = new DebounceWaiter<MBMintResponse>();
  private populateSalesWaiter = new DebounceWaiter<Sale[]>();

  private mintSigs = new Set<string>();

  private claimedWallets: Record<string, Set<String>> = {};

  constructor(private mockService: MockStorageService) {}

  async fetchCurrentSale(saleType?: string): Promise<{ sale: Sale; saleState: SaleState }> {
    await this.populateSales();
    return await this.fetchCurSaleWaiter.wrap(async () => {
      const sales = saleType ? this.sales.filter((sale) => sale.saleType === saleType) : this.sales;
      const result = await salePrioWeightPicker(sales, this);
      return result;
    });
  }

  async fetchSale(saleId: string): Promise<Sale> {
    await this.populateSales();
    return this.saleMap[saleId];
  }

  async fetchAllSales(): Promise<Sale[]> {
    await this.populateSales();
    return this.sales.slice();
  }

  async fetchWhitelistRemainingSpots(opts: { saleId: string }): Promise<number> {
    await this.populateSales();
    await waitForMs(500);
    const sale = this.saleMap[opts.saleId];
    if (!sale) {
      throw new Error(`Invalid sale ID "${opts.saleId}"`);
    }

    return sale.whitelistSpots - this.whitelist.size;
  }

  async fetchWhitelistMintRemainingSupply(opts: { saleId: string }): Promise<number> {
    const sale = this.saleMap[opts.saleId];
    if (!sale) {
      throw new Error(`Invalid sale ID "${opts.saleId}"`);
    }

    return sale.whitelistSupply ?? 10;
  }
  async fetchPublicMintRemainingSupply(opts: { saleId: string }): Promise<number> {
    await this.populateSales();
    const sale = this.saleMap[opts.saleId];
    if (!sale) {
      throw new Error(`Invalid sale ID "${opts.saleId}"`);
    }

    return sale.publicSupply ?? 10;
  }

  async addToWhitelist({ saleId, whitelistPayload }: { saleId: string; whitelistPayload: TpWhitelistPayload }): Promise<TpWhitelistResponse> {
    await this.populateSales();

    return await this.addToWlWaiter.wrap(async () => {
      await waitForMs(3000);
      const sale = this.saleMap[saleId];
      if (!sale) {
        throw new Error('Invalid token type');
      }

      const nonce = whitelistPayload.nonce;
      if (this.whitelist.has(nonce)) {
        log('addToWhitelist: already in whitelist');
        return { added: 0, count: 1 };
      }

      if (sale.whitelistSpots - this.whitelist.size <= 0) {
        log('addToWhitelist: no more spots');
        return { added: 0, count: 0 };
      }

      this.whitelist.add(nonce);
      log('addToWhitelist: added to whitelist');
      return { added: 1, count: 1, storyKey: mockStoryKey };
    });
  }

  async fetchClaimStatus({
    saleId,
    getClaimStatusPayload,
  }: {
    saleId: string;
    getClaimStatusPayload: FetchClaimStatusPayload;
  }): Promise<FetchClaimStatusResponse> {
    await this.populateSales();
    await waitForMs(500);

    const sale = this.saleMap[saleId];
    if (!sale) {
      throw new Error(`Invalid saleId "${saleId}"`);
    }

    if (!this.claimedWallets[saleId]) {
      this.claimedWallets[saleId] = new Set<string>();
    }

    if (hasParam(MockSaleParam.NotWinner)) {
      return {
        winner: false,
        claimed: false,
      };
    }

    const walletAddress = getClaimStatusPayload.walletAddress.toLowerCase();
    return {
      winner: true,
      claimed: hasParam(MockSaleParam.Claimed) || this.claimedWallets[saleId].has(walletAddress),
    };
  }

  async claim({ saleId, claimPayload }: { saleId: string; claimPayload: ClaimPayload }): Promise<ClaimResponse> {
    await this.populateSales();
    await waitForMs(1000);

    const sale = this.saleMap[saleId];
    if (!sale) {
      throw new Error(`Invalid saleId "${saleId}"`);
    }

    if (!this.claimedWallets[saleId]) {
      this.claimedWallets[saleId] = new Set<string>();
    }

    const walletAddress = claimPayload.walletAddress.toLowerCase();
    if (hasParam(MockSaleParam.Claimed) || this.claimedWallets[saleId].has(walletAddress)) {
      throw new AlreadyClaimedError();
    }

    if (hasParam(MockSaleParam.NotWinner)) {
      throw new NotWinnerError();
    }

    this.claimedWallets[saleId].add(walletAddress);

    return {};
  }

  async signForMint(opts: { saleId: string; payload: TpSignMintPayload }): Promise<TpSignMintResponse> {
    await this.populateSales();

    return await this.signForMintWaiter.wrap(async () => {
      await waitForMs(environment.mockLoadingMs * 10);

      if (hasParam(MockSaleParam.ErrorOnSign)) {
        throw new SignForMintError('Mock error');
      }

      const rawMessage = [opts.payload.coldWallet, opts.payload.hotWallet, 1, 1] as [string, string, number, number];
      const signature = Buffer.from(JSON.stringify(rawMessage)).toString('base64');
      const storyKey = mockStoryKey;
      const response = {
        rawMessage,
        signature,
        storyKey,
        signedTokens: Array.from({ length: 3 }, () => `${nftIdPool++}`),
      };
      this.mintSigs.add(signature);
      this.signForMintWaiter.set(response);
      return response;
    });
  }
  async mintWithSignature({ saleId, payload }: { saleId: string; payload: MBMintPayload }): Promise<MBMintResponse> {
    await this.populateSales();
    return await this.mintWithSigWaiter.wrap(async () => {
      await waitForTx('Signing for mint');
      await waitForMs(environment.mockLoadingMs * 10);

      if (!this.mintSigs.has(payload.signature)) {
        throw new MintingError('Invalid signature (missing)');
      }

      // "validate" signature
      const decoded = Buffer.from(payload.signature, 'base64').toString('utf8');
      if (decoded !== JSON.stringify(payload.rawMessage)) {
        throw new MintingError('Invalid signature (non-matching)');
      }

      if (hasParam(MockSaleParam.ErrorOnMinting)) {
        throw new MintingError('Mock error');
      }

      if (hasParam(MockSaleParam.ErrorOnWaitingForMintOf)) {
        throw new WaitForMintingOfError('Mock error');
      }

      const nfts = Array.from({ length: payload.rawMessage[3] }, () => generateMinted());

      const response = { nfts };

      log('Received twitter:', payload.twitter);

      this.mintWithSigWaiter.set(null);

      return null;
    });
  }

  async publicSignAndMint({ saleId, walletAddress }: { saleId: string; walletAddress: WalletAddress }): Promise<MBMintResponse> {
    return this.signForMint({
      saleId,
      payload: {
        hotWallet: walletAddress,
      },
    }).then((response) =>
      this.mintWithSignature({
        saleId,
        payload: {
          hotWallet: walletAddress,
          rawMessage: response.rawMessage,
          signature: response.signature,
          platform: response.platform,
        },
      }),
    );
  }
  async fetchThumbnails({ saleId, tokenIds }: { saleId: string; tokenIds: string[] }): Promise<Record<string, string>> {
    await this.populateSales();
    const sale = this.saleMap[saleId];
    if (!sale) {
      throw new Error('Invalid sale ID');
    }
    return Object.fromEntries(tokenIds.map((tokenId) => [tokenId, generateImageUrl(tokenId, sale.tokenType)]));
  }
  async fetchNFTContractAddress({ saleId }: { saleId: string }): Promise<string> {
    await this.populateSales();
    const sale = this.saleMap[saleId];
    if (!sale) {
      throw new Error('Invalid sale ID');
    }

    return sale.tokenContractAddress;
  }

  private async populateSales() {
    if (this.sales) {
      return this.sales;
    }
    return await this.populateSalesWaiter.wrap(async () => {
      const cfg = await getConfig();
      const cfgSales = Object.values(cfg.saleData);
      const module = await import('@assets/data/saleData');
      const { sales: envSales, saleOverrides } = module.saleExtra;

      const sales = extendSales(mergeData(cfgSales, envSales), saleOverrides) as MockSale[];
      this.sales = sales;
      this.saleMap = sales.reduce<Record<string, MockSale>>(
        (map, sale) => ({
          ...map,
          [sale.saleId]: sale,
          [sale.saleId.toLowerCase()]: sale,
        }),
        {},
      );
      return sales;
    });
  }

  contestStatus(opts: { saleId: string; walletAddress: string }): Promise<any> {
    throw new Error('Method not implemented.');
  }

  async fetchSaleContractInfo({ authorAddress, storyId }: { authorAddress: WalletAddress; storyId: string }) {
    throw new Error('Method not implemented.');
    return {
      openMint: false,
      contractKey: '',
      contractAddress: '' as WalletAddress,
    };
  }
  async fetchSaleBy(opts: { authorAddress: string; storyId: string }): Promise<Sale | undefined> {
    throw new Error('Method not implemented.');
  }
}
