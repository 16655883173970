export class MintDateUnavailable extends Error {
  readonly mintDateUnavailable = true;
  name = 'MintDateUnavailable';
  constructor(message = 'Mint date is unavailable') {
    super(message);
  }

  static isError(error: any): error is MintDateUnavailable {
    return Boolean(error.mintDateUnavailable);
  }
}

export class InsufficientFundsError extends Error {
  readonly insufficientFundsError = true;
  name = 'InsufficientFundsError';
  constructor(public insufficientFunds = true, message = 'This account has insufficient funds') {
    super(message);
  }

  static isError(error: any): error is InsufficientFundsError {
    return Boolean(error.insufficientFundsError);
  }
}

export class SignForMintError extends Error {
  readonly signForMintError = true;
  name = 'SignForMintError';
  constructor(message = 'Signing for mint error occurred') {
    super(message);
  }

  static isError(error: any): error is SignForMintError {
    return Boolean(error.signForMintError);
  }
}

export class MbaasError extends Error {
  readonly mbaasError = true;
  name = 'MbaasError';
  constructor(message = 'Mbaas API error') {
    super(message);
  }

  static isError(error: any): error is MbaasError {
    return Boolean(error.mbaasError);
  }
}

export class RestApiError extends Error {
  readonly restApiError = true;
  name = 'RestApiError';
  responseJson: any;
  constructor(public statusCode: number, message = 'Rest API error', public responseText?: string) {
    super(message);
    if (responseText) {
      try {
        this.responseJson = JSON.parse(responseText);
      } catch (e) {
        // do nothing
      }
    }
  }

  static isError(error: any): error is RestApiError {
    return Boolean(error.restApiError);
  }
}

export class PrepareMintError extends Error {
  readonly prepareMintError = true;
  name = 'PrepareMintError';
  constructor(message = 'Prepare mint error occurred') {
    super(message);
  }

  static isError(error: any): error is PrepareMintError {
    return Boolean(error.prepareMintError);
  }
}

export class MintingError extends Error {
  readonly mintingError = true;
  name = 'MintingError';
  constructor(message = 'Minting error occurred') {
    super(message);
  }

  static isError(error: any): error is MintingError {
    return Boolean(error.mintingError);
  }
}

export class MintingReceiptError extends Error {
  readonly mintingReceiptError = true;
  name = 'MintingReceiptError';
  constructor(message = 'Minting receipt error occurred') {
    super(message);
  }

  static isError(error: any): error is MintingReceiptError {
    return Boolean(error.mintingReceiptError);
  }
}

export class WaitForMintingOfError extends Error {
  readonly waitForMintingOfErrored = true;
  name = 'WaitForMintingOfError';
  constructor(message = 'Post mint error occurred') {
    super(message);
  }

  static isError(error: any): error is WaitForMintingOfError {
    return Boolean(error.waitForMintingOfErrored);
  }
}

export class SvsAuthError extends Error {
  readonly authError = true;
  name = 'SvsAuthError';
  constructor(message = 'Auth error occurred') {
    super(message);
  }

  static isError(error: any): error is SvsAuthError {
    return Boolean(error.authError);
  }
}

export class SvsAuthDeniedError extends Error {
  readonly authDeniedError = true;
  name = 'SvsAuthDeniedError';
  constructor(message = 'User denied auth') {
    super(message);
  }

  static isError(error: any): error is SvsAuthDeniedError {
    return Boolean(error.authDeniedError);
  }
}

export class AlreadySubbedError extends Error {
  readonly alreadySubbedError = true;
  name = 'AlreadySubbedError';
  constructor(message = 'Already subscribed') {
    super(message);
  }

  static isError(error: any): error is AlreadySubbedError {
    return Boolean(error.alreadySubbedError);
  }
}

export class AlreadyClaimedError extends Error {
  readonly alreadyClaimedError = true;
  name = 'AlreadyClaimedError';
  constructor(message = 'Already claimed') {
    super(message);
  }

  static isError(error: any): error is AlreadyClaimedError {
    return Boolean(error?.alreadyClaimedError);
  }
}

export class NotWinnerError extends Error {
  readonly notWinnerError = true;
  name = 'NotWinnerError';
  constructor(message = 'Not a winner') {
    super(message);
  }

  static isError(error: any): error is NotWinnerError {
    return Boolean(error?.notWinnerError);
  }
}

export class ChainwatcherTimeoutError extends Error {
  readonly chainwatcherTimeoutError = true;
  constructor() {
    super(`Tokens created! But blockchain is busy at the moment. Your token will be in your wallet in a few minutes`);
  }

  static isError(error: any): error is ChainwatcherTimeoutError {
    return Boolean(error?.chainwatcherTimeoutError);
  }
}
