import React, { MouseEventHandler } from 'react';

import './Alert.scss';

export type AlertProps = {
  className?: string,
  children?: React.ReactNode,
  dismissible?: boolean,
  onDismiss?: MouseEventHandler<HTMLButtonElement>
};

function createAlert(alertType?: string): React.FC<AlertProps> {
  const baseClassName = 'alert' + (alertType ? (' ' + alertType) : '');
  return (props: AlertProps) => (
    <div className={`${baseClassName} ${props.className ?? ''} ${props.dismissible ? 'alert-dismissible' : ''}`} role="alert">
      {props.children}
      {props.dismissible && (
        props.onDismiss ? (
          <button type="button" className="btn-close" aria-label="Close" onClick={props.onDismiss}></button>
        ) : (
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        )
      )}
    </div>
  );
}

export const Alert = {
  Base: createAlert(),
  Primary: createAlert('alert-primary'),
  Secondary: createAlert('alert-secondary'),
  Success: createAlert('alert-success'),
  Danger: createAlert('alert-danger'),
  Warning: createAlert('alert-warning'),
  Info: createAlert('alert-info'),
  Light: createAlert('alert-light'),
  Dark: createAlert('alert-dark')
}
