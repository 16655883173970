import michaelCarter from './MichaelCarter.png'
import justinWaldron from './JustinWaldron.png'
import hartford from './Hartford.png'
import dpid from './Dpid.png'
import kanto from './Kanto.png'
import mondo from './Mondo.png'
import holmes from './Holmes.png'
import herberh2 from './Herberh2.png'
import Kakarotto from './Kakarotto.png'
import anastasiia from './Anastasiia.png'
import elko from './Elko.png'
import jb from './JB.png'
import cheshirecatalyst from './cheshirecatalyst.png'
import mukunda from './Mukunda.png'
import probablyKira from './probably_kira.png'


export const ProfilePictures = {
  'Michael Carter': michaelCarter,
  'Justin Waldron': justinWaldron,
  Hartford: hartford,
  Dpid: dpid,
  Kanto: kanto,
  Mondo: mondo,
  Holmes: holmes,
  Herberh2: herberh2,
  Kakarotto: Kakarotto,
  Anastasiia: anastasiia,
  Elko: elko,
  JB: jb,
  cheshirecatalyst: cheshirecatalyst,
  Mukunda: mukunda,
  probably_kira: probablyKira
}
