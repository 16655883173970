import React from 'react'
import { useClassName } from '../../hooks/useClassName';

import './GradientTitle.styles.scss'

export type GradientTitleProps = React.HTMLProps<HTMLHeadingElement> & {
  children?: React.ReactNode,
  className?: string,
};

export function GradientTitle({
  children,
  className,
  ...props
}: GradientTitleProps) {
  const classNames = useClassName('GradientTitle', className);

  return <h1 className={classNames} {...props}>
    {children}
  </h1>;
}
