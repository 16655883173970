import './WhitepaperPage.scss';
import { TopLink } from '../../components/top-link/TopLink';
import { whitepaperUrl } from '../../consts';
import { Page } from '../Page';

export function WhitepaperPage() {
  return <Page title="Whitepaper" className="container intro-main-content" introBg>
    <h1>Whitepaper</h1>
    <TopLink to={whitepaperUrl} />
  </Page>;
};
