import React, { useState, createRef, useEffect, ChangeEvent } from 'react'
import type { MouseEvent, FormEvent } from 'react'

import { mainSuite } from '@services/ServiceFactory'
import { AnalyticsEventName } from '@services/analytics/AnalyticsEventName'
import TopImage from '@assets/landing-page-v3/landing/TopImage.mp4'
import TopImagePoster from '@assets/landing-page-v3/landing/TopImage-poster.jpg'
import { Section } from '@components/Section'
import { Link as StyledLink } from '@components/Link'
import { GradientTitle } from '@components/GradientTitle'
import VideoPlayer from '@components/VideoPlayer'
import TokenproofButton from '@components/tokenproof-button'
import { AppActionType, useAppDispatch, useAppState } from '@context/AppContext'
import { debug } from '@common/LogWrapper';

import { Link } from 'react-router-dom'

import './LandingSection.styles.scss'

const log = debug('app:LandingSection')

async function subscribeEmail(email: string) {
  try {
    const response = await fetch('/emailsub', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
    if (!response.ok) {
      const errorData = await response.json()
      log('Error response from subscribing email:', errorData)
      return false
    }
  } catch (e) {
    log('Error occurred while subscribing:', e)
    return false
  }

  return true
}

export function LandingSection() {
  const appState = useAppState()
  const appDispatch = useAppDispatch()
  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const [email, setEmail] = useState('')
  const [submitText, setSubmitText] = useState('Subscribe')
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [checked, setChecked] = useState(false)
  const [checkboxDisabled, setCheckboxDisabled] = useState(false)
  const [emailDisabled, setEmailDisabled] = useState(false)

  const formRef = createRef<HTMLFormElement>()

  function onClick() {
    mainSuite.analyticsService.track(AnalyticsEventName.ButtonPress, {
      buttonName: 'start_creating_now'
    });
  }

  function setEmailPopupOpen(open: boolean) {
    appDispatch({
      type: AppActionType.UpdateSignUpEmail,
      email: '',
      showSignUpModal: open,
    })
  }

  function emailSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault() // Prevent page from reloading

    setEmailDisabled(true)
    setCheckboxDisabled(true)
    setSubmitDisabled(true)
    setSubmitText('Subscribing...')
    setErrorMsg(null)

    subscribeEmail(email)
      .then((success) => {
        if (success) {
          setEmail('')
          setEmailDisabled(true)
          setCheckboxDisabled(true)
          setSubmitDisabled(true)
          setSubmitText('Subscribed')
          setErrorMsg(null)
        } else {
          setEmailDisabled(false)
          setCheckboxDisabled(false)
          setSubmitDisabled(false)
          setSubmitText('Subscribe')
          setErrorMsg('Error occurred.')
        }
      })
  }

  function formChange() {
    if (!formRef.current) {
      return
    }
    setSubmitDisabled(!formRef.current.checkValidity())
    setErrorMsg(null)
  }

  function emailChange(event: ChangeEvent<HTMLInputElement>) {
    setEmail(event.currentTarget.value)
  }

  function checkboxChange(event: ChangeEvent<HTMLInputElement>) {
    setChecked(event.currentTarget.checked)
  }

  function emailPopupClickHandler(event: MouseEvent<HTMLElement>) {
    const target = event.target as HTMLElement

    if(!['DIV', 'H1'].includes(target.nodeName)) {
      return
    }

    setEmailPopupOpen(false)
  }

  useEffect(() => {
    setEmail(appState.signUpEmail)
    formChange()
  }, [appState.signUpEmail])

  useEffect(() => {
    if (appState.showSignUpModal) {
      setChecked(false)
      setCheckboxDisabled(false)
      setEmailDisabled(false)
      setSubmitText('Subscribe')
    }
  }, [appState.showSignUpModal])

  return (
    <Section className='LandingSection'>
      <VideoPlayer
        src={TopImage}
        poster={TopImagePoster}
        muted
        loop
        autoPlay
        playsInline
        preload='auto'
        controls={false}
        className='LandingImage'
      />

      <GradientTitle>Storyverse</GradientTitle>
      <p className='TextBodyLarge'>Create, animate, and share stories with your NFTs. </p>

      <Link className={'Button'} to='/create' onClick={onClick}>Start Creating</Link>
      { appState.features.showSignUpModal && (
        <StyledLink to='#' onClick={() => setEmailPopupOpen(true)}>Sign up for future updates</StyledLink>
      )}
      { appState.features.showTokenproof && (
        <TokenproofButton />
      )}

      <div className={`emailPopup ${appState.showSignUpModal ? 'open' : ''}`} onClick={emailPopupClickHandler}>
        <GradientTitle>Sign up for <br/> future updates</GradientTitle>

        <form onSubmit={emailSubmit} onChange={formChange} ref={formRef}>
          <input type='email' placeholder='Your Email Address' required disabled={emailDisabled} value={email} onChange={emailChange} />

          <div>
            <input type='checkbox' id='emailCollectCheck' required disabled={checkboxDisabled} checked={checked} onChange={checkboxChange} />
            <label htmlFor='emailCollectCheck'>I agree to receive marketing emails.</label>
          </div>

          <button type='submit' disabled={submitDisabled}>{submitText}</button>
          {errorMsg && (
            <p className='text-danger'>{errorMsg}</p>
          )}
        </form>
      </div>
    </Section>
  )
}
