import { ApiType } from '../ApiType';
import { MetaMaskUserService } from '../user/MetaMaskUserService';
import { UserService } from '../user/UserService';
import { BaseSuite } from './BaseSuite';
import { registerSuite, Suite } from './Suite';

class MetaMaskSuite extends BaseSuite {
  readonly apiType = ApiType.MetaMask;

  private _userService: MetaMaskUserService;

  get userService(): UserService {
    if (!this._userService) {
      this._userService = new MetaMaskUserService(this.mainSuite.chainalysisService);
    }
    return this._userService;
  }
}

export const metaMaskSuite = new MetaMaskSuite();
registerSuite(metaMaskSuite);
