import { useClassName } from '@hooks/useClassName';
import { Spinner } from '@components/spinner/Spinner';
import './MintVideoLayout.scss';

export type MintVideoLayoutProps = {
  videoContent?: React.ReactNode,
  middleContent?: React.ReactNode,
  className?: string,
  children?: React.ReactNode,
  showSpinner?: boolean,
};

export function MintVideoLayout({
  videoContent,
  middleContent,
  className,
  children,
  showSpinner,
}: MintVideoLayoutProps) {
  const classNames = useClassName('mint-video-layout', className);
  return <div className={classNames}>
    <div className="mint-video-layout-video">
      {videoContent}
    </div>
    {middleContent}
    <div className="mint-video-layout-content">
      <div className="mint-video-children-con">
        {children}
      </div>
      {showSpinner && <Spinner />}
    </div>
  </div>;
}

export default MintVideoLayout;
