export enum SocialHandleParam {
  Email = 'email',
  Twitter = 'twitterHandle',
  Discord = 'discordHandle',
  HotWallet = 'hotWallet',
  ColdWallet = 'coldWallet',
}

export enum IsSubscribedTwitterStatus {
  NotRegistered = 'notRegistered',
  NotMatching = 'notMatching',
  Matching = 'matching',
}

export const socialHandleParams = Object.values(SocialHandleParam).filter((value) => typeof value === 'string');

export type SocialHandlesOpts = Partial<Record<SocialHandleParam, string>>;
export type SubscribeOpts = { socialHandles: SocialHandlesOpts; campaign?: string; captchaToken?: string };
export type SubscribeResult = { email?: boolean; socials?: boolean };

export type IsSubscribedOpts = { campaign: string; walletAddress: string; twitterHandle?: string };

export type IsSubscribedResult = { exists: boolean; twitterStatus?: IsSubscribedTwitterStatus };

export interface EmailSubService {
  subscribe(opts: SubscribeOpts): Promise<SubscribeResult>;
  subscribeEmail(opts: SubscribeOpts): Promise<SubscribeResult>;
  subscribeSocials(opts: SubscribeOpts): Promise<SubscribeResult>;
  isSubscribed(opts: IsSubscribedOpts): Promise<IsSubscribedResult>;
}
