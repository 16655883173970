import debug from 'debug';
import { PlotData, PlotsService } from './PlotsService';
import * as ethers from 'ethers';
import { MockStorageService } from '../mock/MockStorageService';
import { UserService } from '../user/UserService';
import { waitForMs } from '../../common/WaitUtils';
import { UserAccount } from '../../common/UserAccount';
import { environment } from '../../environments/environment';
import { waitForTx } from '../mock/MockTxScreen';
import { daysToMs, hoursToMs } from '../../common/NumberUtils';
import { PlotsSaleData, PlotsState } from '../../common/PlotsState';
import { PlotsDispatch, PlotsActionType } from '../../context/plots/PlotsContext';
import { UserPlotsState, UserPlotsDispatch, UserPlotsActionType } from '../../context/plots/UserPlotsContext';
import { ConditionWaiter } from '../../common/ConditionWaiter';
import { LoadState } from '../../common/LoadState';
import { notEligible } from '../../common/UserPlotsState';
import { mockChainId } from '../../common/ProviderUtils';

const log = debug('app:services:MockPlotsService');

type AccountData = {
  address: string,
  eligible: number,
  max: number,
  plots: PlotData[]
};

type MockPlotsData = {
  saleActive: boolean,
  mintDateISO: string,
  plotPrice: string, // wei
  accountData: Record<string, AccountData>,
  maxSupply: number,
  mintedAmount: number,
  plotIdPool: number
};

const plotsDataKey = 'plotsData';
const endTimeDelta = daysToMs(7);

function createDefaultData(): MockPlotsData {
  const mintDate = new Date(Date.now() - hoursToMs(1));
  return {
    saleActive: true,
    mintDateISO: mintDate.toISOString(),
    plotPrice: ethers.utils.parseUnits('6000', 'wei').toString(),
    accountData: {},
    maxSupply: 500,
    mintedAmount: 0,
    plotIdPool: 0,
  };
}

function createMockPlot(numId: number): PlotData {
  return {
    id: `mock-plot-${numId}`
  };
}

export class MockPlotsService implements PlotsService {
  private data: MockPlotsData;
  private userAccount: UserAccount;
  private plotsStateWaiter = new ConditionWaiter<PlotsState>();
  private plotsDispatchWaiter = new ConditionWaiter<PlotsDispatch>();
  private userPlotsStateWaiter = new ConditionWaiter<UserPlotsState>();
  private userPlotsDispatchWaiter = new ConditionWaiter<UserPlotsDispatch>();

  private saleData: PlotsSaleData = undefined;

  constructor(
    private mockStorageService: MockStorageService,
    private userService: UserService
  ) {
    try {
      this.data = {
        ...createDefaultData(),
        ...(mockStorageService.getJson(plotsDataKey) ?? {}),
      }
    } catch (e) {
      this.data = createDefaultData();
      mockStorageService.putJson(plotsDataKey, this.data);
    }
  }
  async initSale(force = false): Promise<void> {
    await this.fetchSaleData(force);
  }
  setUserState(state: UserAccount): void {
    this.userAccount = state;
    if (!state.loggedIn) {
      this.userPlotsDispatchWaiter.wait().then(dispatch => {
        dispatch({
          type: UserPlotsActionType.Clear
        });
      });
    }
  }
  setPlotsState(state: PlotsState): void {
    this.plotsStateWaiter.set(state);
  }
  setPlotsDispatch(dispatch: PlotsDispatch): void {
    this.plotsDispatchWaiter.set(dispatch);
  }
  setUserPlotsState(state: UserPlotsState): void {
    this.userPlotsStateWaiter.set(state);
  }
  setUserPlotsDispatch(dispatch: UserPlotsDispatch): void {
    this.userPlotsDispatchWaiter.set(dispatch);
  }
  async fetchSaleIsActive(): Promise<boolean> {
    await this.fetchSaleData();
    await waitForMs(environment.mockLoadingMs);
    return this.data.saleActive;
  }
  async fetchMintDate(): Promise<string> {
    await this.fetchSaleData();
    await waitForMs(environment.mockLoadingMs);
    return this.data.mintDateISO;
  }
  async fetchMintEndDate(): Promise<string> {
    await this.fetchSaleData();
    const mintEndDate = new Date(Date.parse(this.data.mintDateISO) + daysToMs(3));
    await waitForMs(environment.mockLoadingMs);
    return mintEndDate.toISOString();
  }
  async fetchPlotPrice(): Promise<string> {
    await this.fetchSaleData();
    await waitForMs(environment.mockLoadingMs);
    const plotPriceBN = ethers.utils.parseUnits(this.data.plotPrice, 'wei');
    return ethers.utils.formatEther(plotPriceBN);
  }
  async fetchEligiblePlots(walletAddress?: string): Promise<number> {
    if (!walletAddress) {
      // await this.userService.checkIfLinked(); // ensures address is set
      const currentAddress = await this.getCurrentAddress();
      if (!currentAddress) {
        throw new Error('Login required if address is not defined');
      }
      walletAddress = currentAddress;
    }

    const dispatch = await this.userPlotsDispatchWaiter.wait();

    dispatch({
      type: UserPlotsActionType.UpdateEligiblePlotsState,
      loadState: LoadState.Loading,
    });

    const accountData = this.data.accountData[walletAddress];

    await waitForMs(environment.mockLoadingMs);

    const eligiblePlots = accountData?.eligible ?? notEligible;

    log('fetch eligible plots', walletAddress, accountData);

    dispatch({
      type: UserPlotsActionType.UpdateEligiblePlots,
      eligiblePlots,
      maxPlots: accountData?.max ?? 0,
    });

    return eligiblePlots;
  }
  async fetchPlots(walletAddress?: string): Promise<PlotData[]> {
    if (!walletAddress) {
      const currentAddress = await this.getCurrentAddress();
      if (!currentAddress) {
        throw new Error('Login required if address is not defined');
      }
      walletAddress = currentAddress;
    }

    const dispatch = await this.userPlotsDispatchWaiter.wait();

    dispatch({
      type: UserPlotsActionType.UpdatePlotIdsState,
      loadState: LoadState.Loading,
    });

    const accountData = this.data.accountData[walletAddress];

    const plots = accountData?.plots ?? [];

    dispatch({
      type: UserPlotsActionType.UpdatePlotIds,
      plotIds: plots.map(plot => plot.id),
    });

    return plots;
  }
  async fetchRemainingSupply(): Promise<number> {
    await this.fetchSaleData();
    return this.data.maxSupply - this.data.mintedAmount;
  }
  async mintPlots(amount: number): Promise<PlotData[]> {
    const address = await this.getCurrentAddress();
    if (!address) {
      throw new Error('Login required for minting');
    }
    if (amount < 1) {
      throw new Error('Cannot mint 0 or less plots');
    }

    const accountData = this.data.accountData[address] ?? {
      address,
      eligible: 0,
      max: 0,
      plots: [],
    };
    const eligible = accountData?.eligible ?? 0;
    if (amount > eligible) {
      throw new Error(`Cannot mint more than eligible plots (${amount} of ${eligible})`);
    }

    log('Asking for transaction...');
    await waitForTx(`Allow minting ${amount} plots?`);
    log('Allowed transaction.');

    const newPlots = [];
    for (let i = 0; i < amount; i++) {
      log(`Minting ${i + 1} of ${amount}...`);
      const plotNumId = this.data.plotIdPool++;
      newPlots.push(createMockPlot(plotNumId));
      await waitForMs(environment.mockLoadingMs * 2);
    }
    const newEligible = eligible - amount;
    const newMintedAmount = this.data.mintedAmount + amount;
    accountData.eligible = newEligible;
    accountData.plots = accountData.plots.concat(newPlots);
    this.data.mintedAmount = newMintedAmount;
    this.data.accountData[address] = accountData;
    log(`Minted ${amount} plots. (New eligible: ${newEligible}, new mint amount: ${newMintedAmount})`);
    this.saveData();
    this.fetchSaleData(true);
    this.clearUserPlotsData();

    return newPlots;
  }

  async setMintDate(dateISO: string): Promise<void> {
    this.data.mintDateISO = dateISO;
    this.saveData();
    this.fetchSaleData(true);
  }

  async setEligiblePlots(eligible: number, walletAddress: string): Promise<void> {
    if (eligible < 0) {
      throw new Error('Cannot set negative eligible');
    }

    const accountData = this.data.accountData[walletAddress] ?? {
      address: walletAddress,
      eligible: 0,
      max: 0,
      plots: [],
    };

    accountData.eligible = eligible;
    accountData.max = eligible;
    this.data.accountData[walletAddress] = accountData;
    this.saveData();
  }

  async setRemainingSupply(supply: number): Promise<void> {
    if (supply < 0) {
      throw new Error('Cannot set negative supply');
    }

    this.data.mintedAmount = this.data.maxSupply - supply;
    this.saveData();
    this.fetchSaleData(true);
  }

  async setPlotPrice(ethPrice: string): Promise<void> {
    const weiBN = ethers.utils.parseEther(ethPrice);
    this.data.plotPrice = ethers.utils.formatUnits(weiBN, 'wei');
    this.saveData();
    this.fetchSaleData(true);
  }

  async setSaleActive(active: boolean): Promise<void> {
    this.data.saleActive = active;
    this.saveData();
    this.fetchSaleData(true);
  }

  private async clearUserPlotsData() {
    const dispatch = await this.userPlotsDispatchWaiter.wait();
    dispatch({ type: UserPlotsActionType.Clear });
  }

  private async fetchSaleData(force = false): Promise<PlotsSaleData> {
    if (!force && this.saleData) { return this.saleData; }

    const dispatch = await this.plotsDispatchWaiter.wait();

    dispatch({ type: PlotsActionType.UpdateLoadState, loadState: LoadState.Loading });

    await waitForMs(environment.mockLoadingMs);

    const startDate = new Date(this.data.mintDateISO);
    const endDate = new Date(startDate.getTime() + endTimeDelta);

    const plotPriceBN = ethers.utils.parseUnits(this.data.plotPrice, 'wei');

    this.saleData = {
      active: this.data.saleActive,
      endtime: Math.floor(endDate.getTime() / 1000),
      id: 0,
      maxPLOTs: this.data.maxSupply,
      maxQuantity: 0,
      merkleRoot: undefined,
      mintedPLOTs: this.data.mintedAmount,
      presale: 0,
      price: plotPriceBN.toNumber(),
      startTokenIndex: 0,
      starttime: Math.floor(startDate.getTime() / 1000),
      volume: 0,
    };
    
    dispatch({
      type: PlotsActionType.UpdateState,
      plotsSaleData: this.saleData,
      chainId: mockChainId,
    });

    return this.saleData;
  }

  private saveData(): void {
    this.mockStorageService.putJson(plotsDataKey, this.data);
  }

  private async getCurrentAddress(): Promise<string | null> {
    return this.userAccount.address;
  }

  private get plotsState(): PlotsState {
    return this.plotsStateWaiter.get();
  }

  private get plotsDispatch(): PlotsDispatch {
    return this.plotsDispatchWaiter.get();
  }

  private get userPlotsState(): UserPlotsState {
    return this.userPlotsStateWaiter.get();
  }

  private get userPlotsDispatch(): UserPlotsDispatch {
    return this.userPlotsDispatchWaiter.get();
  }
}
