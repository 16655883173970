import './community-guidelines.scss';
import { Page } from '../Page';
import { mainSuite } from '../../services/ServiceFactory'
import { AnalyticsEventName } from '../../services/analytics/AnalyticsEventName'
import { useEffect } from 'react'
import { debug } from '@common/LogWrapper'

const log = debug('app:pages:CommunityGuidelines');

export function CommunityGuidelinesPage() {
  useEffect(() => {
    mainSuite.analyticsService.userTrack({})
    mainSuite.analyticsService.track(AnalyticsEventName.EntryFinal)
  }, []);

  return (
    <Page title='Community Guidelines' className='community-guidelines-page' analyticsPageName='community-guidelines' analyticsManualEntryFinal>
      <section>
        <h1>Community Guidelines</h1>
        <p>
          Storyverse is a community for everyone to bring stories to life. As a community, we want to encourage quality, creative, engaging, diverse, and collaborative content. In order to balance encouraging freedom of expression, maintaining a welcoming environment, and meeting legal obligations, we want to be as clear as possible about what is and what is not ok in Storyverse.
          Importantly, these guidelines will evolve as the Storyverse project and community evolve.
        </p>
        <h2>
          Let's get to it.
        </h2>
        <p>
          Content that is illegal, violent, abusive, or harmful to other community members is not allowed on the Platform. These Community Guidelines are intended to further explain what kinds of harmful content is not ok. These Community Guidelines apply to all content you bring or create on the Storyverse Platform, including the Stories you create or the messages you send to other community members.
        </p>
        <h2>
          Content that is not ok:
        </h2>
        <ul>
          <li>Encourages any illegal activity, or advocate, promote, or assist any unlawful act. This includes providing or referencing instructional information about illegal activities, as well as supporting or glorifying any terrorist or extremist organization and their actions.</li>
          <li>Threatens, promotes, or glorifies violence, harm, or injury against an individual or a group of people.</li>
          <li>Contains material that encourages hate or harassment of other people on the basis of race, ethnicity, national origin, caste, sexual orientation, gender, gender identity, religious affiliation, age, disability, or serious disease.</li>
          <li>Promotes, encourages, or depicts suicide or self-harm.</li>
          <li>Contains any material that is pornographic.</li>
          <li>Promotes or facilitates prostitution or websites that facilitate sex trafficking.</li>
          <li>Falsely states or suggests or otherwise misrepresent your identity, affiliation with, or authorization from a person or entity.</li>
          <li>Contains other people's private information (such as home phone number or address) without their express authorization and permission or threatens to expose private information or incentivizes others to do so.</li>
          <li>Contains or otherwise makes available any unsolicited advertising, promotion materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other form of solicitation.</li>
          <li>Violates the legal rights (including intellectual property rights or the rights of publicity and privacy) of others.</li>
          <li>Attempts to defraud StoryverseCo, Storyverse users, or any other person.</li>
          <li>Contains lotteries, gambling, casino-style games, or games of chance.</li>
          <li>Otherwise uses Storyverse for purposes other than those for which Storyverse was designed or in violation of the <a target='_blank' href='https://www.play.co/storyverse/tos'>Platform Terms</a> or <a target='_blank' href='https://www.play.co/storyverse/plots'>Plot Terms</a>.</li>
        </ul>
        <p>
          If you think you see something that you think violates these Community Guidelines, please let us know by contacting us at support@storyverse.xyz or opening a support ticket in the #support channel in the project's Discord server at <a target='_blank' href='http://discord.gg/storyverse'>http://discord.gg/storyverse</a>.
        </p>
        <h2>
          What happens if you violate this policy?
        </h2>
        <p>
          Consequences of violating the Community Guidelines will depend on the severity of the violation, the type of content, how it is accessed by others, applicable legal obligations, and where it is located. Violations of these guidelines may result in removal of the content, terminating or limiting the visibility of the content, providing warnings to the content poster or other users, or notifying law enforcement.
        </p>
        <p>
          For more information, see the <a target='_blank' href='https://www.play.co/storyverse/tos'>Storyverse Platform Terms</a> and <a target='_blank' href='https://www.play.co/storyverse/plots'>Plot Terms</a>.
        </p>
      </section>
    </Page>
  );
}
