
export enum UserActionType {
  UpdateCASafe = 'user/updateCASafe',
  UpdateChainId = 'user/updateChainId',
  UpdateAddress = 'user/updateAddress',
  UpdateName = 'user/updateName',
  UpdateWallet = 'user/updateWallet',
  UpdatePermissions = 'user/updatePermissions',
  AddPermissions = 'user/addPermissions',
  RemovePermissions = 'user/removePermissions',
  UpdateLoggedIn = 'user/updateLoggedIn',
  UpdateLoginState = 'user/updateLoginState',
  LogOut = 'user/logOut',
  LogIn = 'user/logIn'
};
