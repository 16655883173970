export const ethPlaces = 18;
export const ethRegex = /^\d+\.\d+$/;

export const playTokenPlaces = 6;
export const playTokenRegex = /^\d+\.\d+$/;

export const mockensPlaces = 18;
export const mockensRegex = /^\d+\.\d+$/;

export const momentVideoDelayMs = 3000;
export const momentVideoFirstDelayMs = 500;

export const cssDelay = 50; // ms

/**
 * Adjustment in pixels so the cards are behind the video pack opening.
 */
export const revealCardYOffset = -30;
/**
 * Delay in milliseconds between cards spreading after pack opening,
 * so the cards don't spread out at the same time.
 */
export const revealCardMoveDelay = 100;
/**
 * Card movement duration in milliseconds to be applied after initial setup.
 */
export const revealCardMoveDuration = 1000;
/**
 * Delay in milliseconds before making the cards visible behind the pack
 * opening video.
 */
export const revealCardStackedDelay = 500;
/**
 * Delay in milliseconds after stacked state, ideally sometime after pack
 * opening has started moving down.
 */
export const revealCardHiddenDelay = 1000;
/**
 * Delay in milliseconds after moment video has stopped playing.
 */
export const revealCardRevealedDelay = 500; // ms
/**
 * Delay in milliseconds after revealed state is set, preferrably
 * the same length as `revealCardMoveDuration`.
 */
export const revealCardMoveToBackDelay = 1000; // ms
/**
 * Delay in milliseconds to play video after the card has moved to the middle
 * for presentation, preferably the duration of the video background appearing
 */
export const revealCardPlaybackDelay = 500; // ms
/**
 * Delay in milliseconds to remove 'show' classname to let the fade transition
 * play out.
 */
export const revealCardBackdropFadeDelay = 500; // ms

export const whitepaperUrl = 'https://whitepaper.storyverse.xyz';
