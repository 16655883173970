import React from 'react';
import { Action, generateStoreContext } from '../GenerateContext';
import { UserPlotsActionType } from './UserPlotsActionType';
import type { UserPlotsState } from '../../common/UserPlotsState';
import { LoadState } from '../../common/LoadState';

const initialState: UserPlotsState = {
  eligiblePlotsState: LoadState.Idle,
  eligiblePlotsError: null,
  eligiblePlots: -1,
  maxPlots: -1,
  plotIdsState: LoadState.Idle,
  plotIdsError: null,
  plotIds: [],
};

export type UserPlotsActionProps = {
  type: UserPlotsActionType.Clear,
} | {
  type: UserPlotsActionType.UpdateEligiblePlots,
  eligiblePlots: number,
  maxPlots: number,
} | {
  type: UserPlotsActionType.UpdateEligiblePlotsState,
  loadState: LoadState,
  loadError?: string,
} | {
  type: UserPlotsActionType.UpdatePlotIds,
  plotIds: string[],
} | {
  type: UserPlotsActionType.UpdatePlotIdsState,
  loadState: LoadState,
  loadError?: string,
};

export type UserPlotsAction = Action<UserPlotsActionType, UserPlotsActionProps>;
export type UserPlotsDispatch = React.Dispatch<UserPlotsAction>;

function reducer(
  state: UserPlotsState,
  action: UserPlotsAction
): UserPlotsState {
  switch (action.type) {
  case UserPlotsActionType.UpdateEligiblePlots:
    if (!('eligiblePlots' in action)) {
      throw new Error('userplots-context: Missing eligiblePlots property');
    }
    if (!('maxPlots' in action)) {
      throw new Error('userplots-context: Missing maxPlots property');
    }
    return {
      ...state,
      eligiblePlotsState: LoadState.Loaded,
      eligiblePlotsError: null,
      eligiblePlots: action.eligiblePlots,
      maxPlots: action.maxPlots,
    };
  case UserPlotsActionType.UpdatePlotIds:
    if (!('plotIds' in action)) {
      throw new Error('userplots-context: Missing eligiblePlots property');
    }
    return {
      ...state,
      plotIdsState: LoadState.Loaded,
      plotIdsError: null,
      plotIds: action.plotIds,
    };
  case UserPlotsActionType.Clear:
    return {
      eligiblePlotsState: LoadState.Idle,
      eligiblePlotsError: null,
      eligiblePlots: -1,
      maxPlots: -1,
      plotIdsState: LoadState.Idle,
      plotIdsError: null,
      plotIds: [],
    };
  case UserPlotsActionType.UpdateEligiblePlotsState:
    if (!('loadState' in action)) {
      throw new Error('userplots-context: Missing loadState property');
    }
    return {
      ...state,
      eligiblePlotsState: action.loadState,
      eligiblePlotsError: action.loadError ?? null,
    };
  case UserPlotsActionType.UpdatePlotIdsState:
    if (!('loadState' in action)) {
      throw new Error('userplots-context: Missing loadState property');
    }
    return {
      ...state,
      plotIdsState: action.loadState,
      plotIdsError: action.loadError ?? null,
    };
  default:
    throw new Error(`userplots-context: Unknown action type "${(action as any).type}"`);
  }
}

const {
  StateContext,
  DispatchContext,
  ContextProvider,
  ContextConsumer,
  withContext,
  useContextState,
  useContextDispatch
} = generateStoreContext<UserPlotsState, UserPlotsActionType, UserPlotsActionProps>(
  reducer, initialState, 'userPlotsState', 'userPlotsDispatch'
);

export {
  StateContext as UserPlotsStateContext,
  DispatchContext as UserPlotsDispatchContext,
  ContextProvider as UserPlotsProvider,
  ContextConsumer as UserPlotsConsumer,
  withContext as withUserPlots,
  useContextState as useUserPlotsState,
  useContextDispatch as useUserPlotsDispatch,
  UserPlotsState,
  UserPlotsActionType
};
