import { ApiType } from '../ApiType';
import { BaseSuite } from './BaseSuite';
import { MetadataService } from '../metadata/MetadataService';
import { RestMetadataService } from '../metadata/RestMetadataService';
import { registerSuite, Suite } from './Suite';
import { ChainalysisService } from '../chainalysis/ChainalysisService';
import { RestChainalysisService } from '../chainalysis/RestChainalysisService';
import { TokenproofService } from '../tokenproof/TokenproofService';
import { RestTokenproofService } from '../tokenproof/RestTokenproofService';
import { SaleService } from '../sale/SaleService';
import { RestSaleService } from '../sale/RestSaleService';
import { EmailSubService } from '../emailsub/EmailSubService';
import { RestEmailSubService } from '../emailsub/RestEmailSubService';

class RestSuite extends BaseSuite {
  readonly apiType = ApiType.Rest;

  private _metadataService: RestMetadataService;
  private _chainalysisService: ChainalysisService;
  private _saleService: SaleService;
  private _tokenproofService: RestTokenproofService;
  private _emailSubService: RestEmailSubService;

  get metadataService(): MetadataService {
    if (!this._metadataService) {
      this._metadataService = new RestMetadataService();
    }
    return this._metadataService;
  }

  get chainalysisService(): ChainalysisService {
    if (!this._chainalysisService) {
      this._chainalysisService = new RestChainalysisService();
    }
    return this._chainalysisService;
  }

  get saleService(): SaleService {
    if (!this._saleService) {
      this._saleService = new RestSaleService(this.mainSuite.analyticsService);
    }
    return this._saleService;
  }

  get tokenproofService(): TokenproofService {
    if (!this._tokenproofService) {
      this._tokenproofService = new RestTokenproofService(this.mainSuite.analyticsService, this.mainSuite.saleService);
    }
    return this._tokenproofService;
  }

  get emailSubService(): EmailSubService {
    if (!this._emailSubService) {
      this._emailSubService = new RestEmailSubService();
    }
    return this._emailSubService;
  }
}

export const restSuite = new RestSuite();
registerSuite(restSuite);
