import React, { MouseEvent } from 'react';

import './Modal.scss';

export type ModalProps = {
  onDismiss?: () => void;
  show?: boolean;
  children?: React.ReactNode;
  className?: string;
  dialogClassName?: string;
  hideClose?: boolean;
  title?: React.ReactNode;
  childrenContainerClassName?: string;
  disableBackdrop?: boolean;
};

type ModalState = {
  animateShow: boolean;
  elementShow: boolean;
};

export class Modal extends React.Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);

    this.state = {
      animateShow: false,
      elementShow: false,
    };

    this.dismiss = this.dismiss.bind(this);
  }

  componentDidMount() {
    if (this.props.show) {
      this.showModal();
    }
  }

  componentDidUpdate(prevProps: Readonly<ModalProps>, prevState: Readonly<ModalState>) {
    if (!prevProps.show && this.props.show) {
      this.showModal();
    } else if (prevProps.show && !this.props.show) {
      this.hideModal();
    }
  }

  dismiss(e: MouseEvent<any>) {
    if (e.target !== e.currentTarget) {
      return;
    } // only allow close or backdrop
    this.hideModal();
    this.props.onDismiss?.();
  }

  showModal() {
    this.setState({ elementShow: true }, () => setTimeout(() => this.setState({ animateShow: true }), 50));
  }

  hideModal() {
    this.setState({ animateShow: false }, () => setTimeout(() => this.setState({ elementShow: false }), 500));
  }

  render() {
    if (!this.state.elementShow) {
      return null;
    }

    const modalClassName = ['modal', 'fade', this.state.animateShow && 'show', this.props.className].filter(Boolean).join(' ');

    return (
      <>
        <div className={`modal-backdrop fade${this.state.animateShow ? ' show' : ''}`}></div>
        <div
          className={modalClassName}
          role="modal"
          aria-modal="true"
          style={{ display: 'block' }}
          onClick={this.props.disableBackdrop ? undefined : this.dismiss}
        >
          <div className={`modal-dialog bg-dark ${this.props.dialogClassName ?? ''}`}>
            <div className="modal-content bg-dark">
              <div className="modal-header">
                {this.props.title}
                {!this.props.hideClose && <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={this.dismiss}></button>}
              </div>
              <div className={`modal-body ${this.props.childrenContainerClassName ?? ''}`}>{this.props.children}</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
