import { HostedApp } from '../../components/hosted-app/HostedApp';
import { environment } from '../../environments/environment';
import { Page } from '../Page';
import './MapsPage.scss';

export function MapsPage() {
  return <Page title="Plot Map" className="plot-map-page hosted-app-page"
    hideFooter
    fullHeight
    showWalletButton
    analyticsManualEntryFinal
  >
    <HostedApp src={environment.mapsUrl + '?iframed'} className="plot-map" />
  </Page>;
}
