import { environment } from '@environment';
import { Page } from '../Page';
import MintLayout from './layouts/MintLayout';
import { MintActionType, MintPageState, useMintDispatch, useMintState } from '@context/mint/MintContext';
import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import MintVideoLayout from './layouts/MintVideoLayout';
import VimeoReactPlayer from '@components/vimeo-react-player/VimeoReactPlayer';
import './MintSubPage.scss';
import GradientTitle from '@components/GradientTitle';
import { mainSuite } from '@services/ServiceFactory';
import { useUserState } from '@services/user/UserContext';
import { TpAccount, TpStatus } from '@services/tokenproof/TokenproofService';
import { TokenproofButton } from '@components/tokenproof-button';
import { NeedAccountModal } from './components/NeedAccountModal';
import { LoginState, loginStateInProgress } from '@common/LoginState';
import TransitioningSection from '@components/transitioning-section';
import { SvsInputText } from '@components/svs-input-text/SvsInputText';
import emailIcon from '@assets/svg/email-icon.svg';
import twitterIcon from '@assets/svg/twitter.svg';
import charPassImg from '@assets/mint/character_pass_placeholder.gif';
import { Link, useLocation } from 'react-router-dom';
import { MintVideoContent, MintVideoContentRef, MintVideoContentType } from './components/MintVideoContent';
import { SvsNavbarEvent, SvsProvider, TwtAuthVersion } from '@storyverseco/svs-navbar';
import { AnalyticsEventName, ShareType } from '@services/analytics/AnalyticsEventName';
import { minutesToMs, secondsToMs } from '@common/NumberUtils';
import { debug } from '@common/LogWrapper';
import { createMetaMaskDeeplink } from '@common/ProviderUtils';
import { EmailSignUpSection } from './sections/EmailSignUpSection';
import { TextNewLiner } from '@components/text-new-liner/TextNewLiner';
import { defaultVimeoUrl } from './components/MintVideoContent';
import { PremintFaqs } from './sections/PremintFaqs';
import { StoryKey, WalletAddress } from '@storyverseco/svs-types';
import { pipelineApiCall, PipelineEndpoint } from '@common/SvsRestApi';
import { tokenReplace, truncateAddress } from '@common/StringUtils';
import { useAppState } from '@context/AppContext';
import { SearchParam } from '@common/SearchParam';
import safeLocalStorage from '@common/SafeLocalStorage';
import { useUserHook } from '@hooks/useUserHook';
import { TwtAuthState } from '@common/TwtAuthState';

const log = debug('app:pages:MintSubPage');

const preRedirectDataKey = 'svs.mainSite.m.prd:1.0.0';

type PreRedirectData = {
  email?: string | null;
  twitter?: string | null;
};

function isPreRedirectData(obj: any): obj is PreRedirectData {
  return (
    obj &&
    (!('email' in obj) || typeof obj.email === 'string' || obj.email === null) &&
    (!('twitter' in obj) || typeof obj.twitter === 'string' || obj.twitter === null)
  );
}

function loadPreRedirectData(): PreRedirectData | null {
  const b64Data = safeLocalStorage.getItem(preRedirectDataKey);
  if (!b64Data) {
    return null;
  }

  let dataStr: string;
  try {
    dataStr = window.atob(b64Data);
  } catch (e) {
    log('Invalid post redirect data base-64:', e.message);
    return null;
  }

  let data: unknown;
  try {
    data = JSON.parse(dataStr);
  } catch (e) {
    log('Invalid post redirect data json', e.message);
    return null;
  }

  if (!isPreRedirectData(data)) {
    log('Invalid post redirect data format');
    return null;
  }

  return data;
}

function savePreRedirectData(data: PreRedirectData): void {
  const dataStr = JSON.stringify(data);
  const b64Data = window.btoa(dataStr);
  safeLocalStorage.setItem(preRedirectDataKey, b64Data);
}

function removePreRedirectData(): void {
  safeLocalStorage.removeItem(preRedirectDataKey);
}

enum LoginStep {
  Idle,
  LoginWithMetamask,
  LoginWithTokenproof,
  Done,
  NoMetamask,
}

function getVimeoUrl() {
  const mintState = useMintState();
  return mintState.sale?.saleMedia?.vimeoUrl || defaultVimeoUrl;
}

async function fetchViewerLink(storyKey: StoryKey): Promise<string> {
  const data = await pipelineApiCall({
    method: 'GET',
    endpoint: PipelineEndpoint.Preshare,
    tokens: {
      walletAddress: storyKey.address,
      storyId: storyKey.id,
    },
  });
  let preshareResponse: any;
  if (typeof data === 'string') {
    preshareResponse = JSON.parse(data);
  } else if (data && typeof data === 'object') {
    preshareResponse = data;
  } else {
    throw new Error('Unexpected data type for viewer link');
  }
  if (!preshareResponse?.viewer || typeof preshareResponse.viewer !== 'string') {
    throw new Error('No viewer link');
  }
  return preshareResponse.viewer;
}

function doAddressesMatch(addr1: string | null | undefined, addr2: string | null | undefined): boolean {
  // require both addresses to be non-empty and not null or undefined
  return Boolean(addr1 && addr2 && addr1.toLowerCase() === addr2.toLowerCase());
}

function useUserAddress(truncated = true): string | null {
  const userState = useUserState();
  return useMemo(() => {
    if (!userState.loggedIn) {
      return null;
    }
    if (!userState.address) {
      return null;
    }

    return truncated ? truncateAddress(userState.address) : userState.address;
  }, [userState.address, userState.loggedIn, truncated]);
}

function useHotWalletAddress(truncated = true): string | null {
  const mintState = useMintState();
  return useMemo(() => {
    if (!mintState.hotWallet) {
      return null;
    }

    return truncated ? truncateAddress(mintState.hotWallet) : mintState.hotWallet;
  }, [mintState.hotWallet]);
}

function NoMetamaskButton() {
  const location = useLocation();

  return (
    <a className="svs-big-btn metamask-btn" href={createMetaMaskDeeplink(location.pathname)} target="_blank">
      <span className="mobile">Download MetaMask</span>
      <span className="desktop">Install MetaMask</span>
    </a>
  );
}

function LoginButton({ setShowSpinner }: { setShowSpinner: (show: boolean) => void }) {
  const { tokenproofService, navbarService, chainalysisService } = mainSuite;
  const { logIn: mmLogIn, wallet: mmWallet, loginState: mmLoginState, ready: mmReady, userState } = useUserHook({ providerType: SvsProvider.WalletConnect });
  const mintDispatch = useMintDispatch();
  const mintState = useMintState();
  const [currentStep, setCurrentStep] = useState(LoginStep.Idle);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [needAccountModal, setNeedAccountModal] = useState(false);
  const [checkingMetamask, setCheckingMetamask] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const userAddress = useUserAddress();
  const showAddress = Boolean(currentStep === LoginStep.LoginWithMetamask && userAddress);

  async function startMintEntry() {
    const coldAccount = tokenproofService.getAccount();
    const coldWallet = coldAccount.address;
    const hotWallet = mmWallet.address;

    const isSafe = await chainalysisService.isWalletAddressSafe(coldWallet);
    if (!isSafe || !userState.caSafe) {
      // todo show error page
      log('Wallet address is not safe');
    } else {
      log('Wallet address is safe');
    }

    mintDispatch({
      type: MintActionType.StartMintEntry,
      coldWallet,
      hotWallet,
    });
  }

  function nextStep() {
    const step = currentStep + 1;
    if (step < LoginStep.Done) {
      setCurrentStep(step);
    } else {
      startMintEntry();
    }
  }

  async function tokenproofClicked() {
    if (tokenproofService.isLoggedIn()) {
      nextStep();
      return;
    }

    setShowSpinner(true);
    let account: TpAccount = undefined;
    try {
      account = await tokenproofService.login({
        mintPageState: mintState.pageState,
        tokenType: mintState.sale?.tokenType,
        policyId: mintState.sale?.tokenproofPolicyId,
        force: true, // always force login
      });
    } catch (e) {
      log('Error occurred while logging in with tokenproof', e);
      setErrorMessage('Error occurred while authenticating');
    } finally {
      setShowSpinner(false);
    }
    if (!account) {
      const status = tokenproofService.getStatus();
      if (status === TpStatus.Closed) {
        log('Cancelled tokenproof login');
        setNeedAccountModal(true);
      } else if (status === TpStatus.Rejected) {
        log('Rejected by tokenproof');
        setNeedAccountModal(true);
      } else if (status === TpStatus.Unknown) {
        log('Unknown tokenproof status');
      }
      return;
    }
    nextStep();
  }

  async function metamaskClicked() {
    if (mmWallet?.flags.loggedIn) {
      nextStep();
      return;
    }

    mmLogIn();
    setShowSpinner(true);
    setCheckingMetamask(true);
  }

  // metamask state changes
  useEffect(() => {
    if (currentStep !== LoginStep.LoginWithMetamask) {
      return;
    }

    const loginInProgress = loginStateInProgress(mmLoginState);

    if (!isLoggingIn && loginInProgress) {
      setIsLoggingIn(true);
      return;
    }
    // only check with falling edge for "login" state
    if (checkingMetamask && !loginInProgress && isLoggingIn) {
      if (mmWallet?.flags.loggedIn) {
        nextStep();
      }
      setCheckingMetamask(false);
      setShowSpinner(false);
      setIsLoggingIn(false);
      return;
    }
  }, [checkingMetamask, currentStep, mmLoginState, mmWallet, isLoggingIn]);

  // check if user logged out of metamask during tokenproof step
  useEffect(() => {
    if (currentStep !== LoginStep.LoginWithTokenproof) {
      return;
    }

    if (mmWallet?.flags.loggedIn) {
      return;
    }

    // go back to metamask
    setCurrentStep(LoginStep.LoginWithMetamask);
  }, [currentStep, mmWallet?.flags.loggedIn]);

  useEffect(() => {
    if (currentStep !== LoginStep.Idle) {
      return;
    }

    setShowSpinner(true);

    navbarService
      .isWeb3Capable()
      .then((capable) => {
        if (capable) {
          nextStep();
        } else {
          setCurrentStep(LoginStep.NoMetamask);
        }
      })
      .catch((e) => {
        log('isWeb3Capable error:', e);
      });

    return () => {
      setShowSpinner(false);
    };
  }, [currentStep, nextStep]);

  return (
    <div className="mint-login-btn-con">
      {currentStep === LoginStep.Idle && (
        <button className="svs-big-btn metamask-btn" disabled>
          Loading...
        </button>
      )}
      {currentStep === LoginStep.LoginWithTokenproof && <TokenproofButton overrideOnClick={tokenproofClicked} />}
      {currentStep === LoginStep.LoginWithMetamask && (
        <button className="svs-big-btn metamask-btn" onClick={metamaskClicked}>
          Connect with Metamask
        </button>
      )}
      {currentStep === LoginStep.NoMetamask && <NoMetamaskButton />}
      <p className="notice">
        {currentStep === LoginStep.LoginWithTokenproof && 'Authenticate with your cold wallet; This is the wallet that has your NFTs.'}
        {currentStep === LoginStep.LoginWithMetamask && 'Connect with your hot wallet; This is the wallet that will mint.'}
      </p>
      {currentStep === LoginStep.NoMetamask && (
        <>
          <p className="notice mobile">Mobile Users: Use MetaMask browser to continue</p>
          <p className="notice desktop">Desktop Users: Install MetaMask browser extension to continue</p>
        </>
      )}
      {showAddress && <p className="notice">Currently connected with {userAddress}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <p className="notice">
        By minting, you agree to Character Pass <Link to="/characterpassterms">Terms</Link>
      </p>
      {needAccountModal && <NeedAccountModal onClose={() => setNeedAccountModal(false)} />}
    </div>
  );
}

function MintStatePage() {
  const appState = useAppState();
  const mintState = useMintState();
  const mintDispatch = useMintDispatch();
  const userState = useUserState();
  const [showSpinner, setShowSpinner] = useState(false);
  const [redirectAuthProcessed, setRedirectAuthProcessed] = useState(false);
  const { tokenproofService, chainalysisService, navbarService } = mainSuite;
  const { twitterService } = navbarService.api;

  // logic to skip to share to mint page if redirected from twitter auth
  useEffect(() => {
    if (redirectAuthProcessed) {
      return;
    }
    if (!appState?.initialSearchParams) {
      return;
    }
    if (!appState.initialSearchParams.has(SearchParam.Auth)) {
      return;
    }

    // using pre redirect data as a prerequisite to detect redirect behavior
    const preRedirectData = loadPreRedirectData();
    log('preRedirectData', preRedirectData);
    if (!preRedirectData) {
      log('no preRedirectData');
      setRedirectAuthProcessed(true);
      return;
    }

    if (userState.loginState === LoginState.Idle) {
      setShowSpinner(true);
      return;
    }
    if (loginStateInProgress(userState.loginState)) {
      setShowSpinner(true);
      return;
    }
    if (userState.loginState === LoginState.LoggedOut) {
      setShowSpinner(false);
      setRedirectAuthProcessed(true);
      return;
    }

    let stale = false;
    let removePrd = false;
    async function startMintEntry() {
      const coldAccount = tokenproofService.getAccount();
      const coldWallet = coldAccount.address;
      const hotWallet = userState.address;

      const isSafe = await chainalysisService.isWalletAddressSafe(coldWallet);
      if (!isSafe || !userState.caSafe) {
        // todo show error page
        log('Wallet address is not safe');
      } else {
        log('Wallet address is safe');
      }

      log('going to StartMintEntry');
      removePrd = true;
      mintDispatch({
        type: MintActionType.StartMintEntry,
        coldWallet,
        hotWallet,
        email: preRedirectData?.email,
        twitter: preRedirectData?.twitter,
        skipToShare: true,
      });
    }

    setShowSpinner(true);

    Promise.all([
      twitterService.auth.canWrite(),
      tokenproofService.init(), // init existing session if not expired
    ]).then(([loggedIn]) => {
      if (stale) {
        return;
      }
      if (!loggedIn) {
        return;
      }
      if (!tokenproofService.isLoggedIn()) {
        return;
      }

      setRedirectAuthProcessed(true);
      startMintEntry();
    });
    return () => {
      stale = true;
      if (removePrd && preRedirectData) {
        log('about to delete prd');
        removePreRedirectData();
      }
    };
  }, [appState?.initialSearchParams, userState, redirectAuthProcessed]);

  return (
    <MintLayout title={mintState.sale.saleName} subtitle="In collaboration with tokenproof" className="mint-state-page-layout">
      <MintVideoLayout videoContent={<VimeoReactPlayer url={getVimeoUrl()} autoPlayType="inview" loop />} showSpinner={showSpinner}>
        <GradientTitle>The Mint is now live!</GradientTitle>
        <LoginButton setShowSpinner={setShowSpinner} />
      </MintVideoLayout>
      <PremintFaqs />
    </MintLayout>
  );
}

function MintEntryStatePage() {
  const userState = useUserState();
  const mintState = useMintState();
  const mintDispatch = useMintDispatch();
  const { saleService, tokenproofService } = mainSuite;
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [twitter, setTwitter] = useState(mintState.signUpTwitter ?? '');
  const [email, setEmail] = useState(mintState.signUpEmail ?? '');

  const matchingAddr = doAddressesMatch(userState.address, mintState.hotWallet);
  const userAddress = useUserAddress();
  const hotWalletAddress = useHotWalletAddress();

  // we use form event to take advantage of email validation
  function onSubmit(e?: FormEvent) {
    e?.preventDefault();
    mintDispatch({
      type: MintActionType.StartMintShare,
      email,
      twitter,
    });
  }

  useEffect(() => {
    if (mintState.mintAndSig) {
      return;
    }

    const { coldWallet, hotWallet } = mintState;
    const nonce = tokenproofService.getNonce();

    if (!coldWallet) {
      setErrorMsg('Missing cold wallet');
      return;
    }

    if (!hotWallet) {
      setErrorMsg('Missing hot wallet');
      return;
    }

    if (!nonce) {
      setErrorMsg('Missing tokenproof nonce');
      return;
    }

    let stale = false;
    // safe to call multiple times
    Promise.all([
      saleService.signForMint({
        saleId: mintState.sale.saleId,
        payload: {
          coldWallet,
          hotWallet,
          nonce,
        },
      }),
      saleService.fetchNFTContractAddress({
        saleId: mintState.sale.saleId,
      }),
    ])
      .then(([response, contractAddress]) => {
        if (stale) {
          log('signForMint stale');
          return;
        }
        log('signForMint response', response);
        if (!response) {
          throw new Error('No response');
        }

        const nftImageMap = response.nftImages;
        const signedTokens = response.signedTokens ?? [];

        // is it safe to confirm here?
        // if (!signedTokens.length) {
        //   throw new Error('We cannot confirm you have the required tokens.');
        // }

        const holdings = signedTokens.map((tokenId) => ({
          id: tokenId,
          contractAddress,
          imageUrl: charPassImg,
        }));

        // const account = tokenproofService.getAccount();
        mintDispatch({
          type: MintActionType.UpdateMintAndSig,
          mintAndSig: {
            rawMessage: response.rawMessage,
            signature: response.signature,
          },
          holdings,
          storyKey: response.storyKey,
          nftImageMap,
          platform: response.platform,
        });

        if (mintState.skipToShare) {
          onSubmit();
        }
      })
      .catch((e) => {
        log('Error occurred while signing for mint:', e);
        setErrorMsg(['Error occurred while fetching signature.', 'Please provide this info to developers:', e.message].join('\n'));
      });

    return () => {
      stale = true;
    };
  }, [mintState]);

  if (!mintState.mintAndSig || mintState.skipToShare) {
    if (errorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          <TextNewLiner text={errorMsg} />
        </div>
      );
    }
    return (
      <div className="mintentry-state-page-loading">
        <GradientTitle>Authenticating. This may take a minute.</GradientTitle>
        <TransitioningSection />
      </div>
    );
  }

  const nftName = mintState.sale.tokenNameSingular;

  return (
    <MintLayout title="Finalize Mint" subtitle="Fill in the following to mint" className="mintentry-state-page-layout">
      {errorMsg && (
        <div className="alert alert-danger" role="alert">
          {errorMsg}
        </div>
      )}
      <form onSubmit={onSubmit}>
        <div className="mintentry-body">
          <div className="nft-con">
            {mintState.holdings?.map((holding) => (
              <figure key={holding.id} className="nft">
                {holding.imageUrl && <img src={holding.imageUrl} alt={`${nftName} #${holding.id}`} />}
                {!holding.imageUrl && (
                  <div className="no-image">
                    <p>
                      {nftName} #{holding.id}
                    </p>
                  </div>
                )}
                <figcaption>
                  {nftName} #{holding.id}
                </figcaption>
              </figure>
            ))}
          </div>
          <div className="signup-con">
            <SvsInputText iconUrl={twitterIcon} type="text" placeholder="@your_handle" value={twitter} onChange={(e) => setTwitter(e.currentTarget.value)} />
            <SvsInputText iconUrl={emailIcon} type="email" placeholder="your@email.com" value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
          </div>
          {!matchingAddr && (
            <div className="error-notice-con">
              <p className="error-notice">Please connect with the same hot wallet you initially connected with.</p>
              <p className="error-notice">
                (Expected: {hotWalletAddress}, current: {userAddress})
              </p>
            </div>
          )}
          <div className="buttons-con">
            <button className="mint-btn-cta" type="submit" disabled={!matchingAddr}>
              NEXT
            </button>
          </div>
          <div className="notice-con">
            <p className="notice">
              By clicking next, you agree to our <Link to="/tos">Terms of Service</Link> and have read our <Link to="/privacy">Privacy Policy</Link>.
            </p>
          </div>
        </div>
      </form>
    </MintLayout>
  );
}

enum ViewerLoadState {
  Idle,
  Loading,
  LoadExpired,
  Loaded,
}

function MintShareStatePage() {
  const { analyticsService, navbarService } = mainSuite;
  const { twitterService } = navbarService.api;
  const userState = useUserState();
  const mintState = useMintState();
  const mintDispatch = useMintDispatch();
  const [shareClicked, setShareClicked] = useState(false);
  // const [loaded, setLoaded] = useState(false);
  const [loadState, setLoadState] = useState(ViewerLoadState.Idle);
  const [ref, setRef] = useState<MintVideoContentRef | null>(null);
  const [buttonLabel, setButtonLabel] = useState('SHARE TO MINT!');
  const [shareExpiration, setShareExpiration] = useState(-1);
  const [notice, setNotice] = useState<string | null>(null);
  const [twtAuthState, setTwtAuthState] = useState(TwtAuthState.Idle);

  const matchingAddr = doAddressesMatch(userState.address, mintState.hotWallet);
  const userAddress = useUserAddress();
  const hotWalletAddress = useHotWalletAddress();

  function onBack() {
    mintDispatch({
      type: MintActionType.UpdateMintPageState,
      pageState: MintPageState.MintEntry,
    });
  }

  async function tweetStory() {
    const baseTweetCopy = mintState.sale.saleMedia?.twitterCopy?.text ?? 'Check out my new story!\n<replacemywithvideolink>\n\nFrom @storyverse_xyz';
    const viewerLink = await fetchViewerLink(mintState.storyKey);
    const tweetCopy = tokenReplace(baseTweetCopy, { replacemywithvideolink: viewerLink }, true, true);
    const tweetId = await twitterService.share.story({
      message: tweetCopy,
      authorAddress: mintState.storyKey.address as WalletAddress,
      storyId: mintState.storyKey.id,
    });
    log('tweeted story:', tweetId);
  }

  async function onShare() {
    if (!mintState.alreadyMintShared && mintState.storyKey) {
      let loggedIn: boolean;
      if (twtAuthState !== TwtAuthState.Authorized) {
        // need to save this data for later
        savePreRedirectData({
          email: mintState.signUpEmail,
          twitter: mintState.signUpTwitter,
        });
        analyticsService.track(AnalyticsEventName.ButtonPress, { buttonName: 'mintTwitterAuth', tokenType: mintState.sale.tokenType });
        setShareClicked(true);
        const twtUser = await twitterService.auth.logIn({ authVersion: TwtAuthVersion.V1Write });
        loggedIn = Boolean(twtUser);
      } else {
        loggedIn = true;
      }

      if (!loggedIn) {
        return;
      }

      analyticsService.track(AnalyticsEventName.ButtonPress, { buttonName: 'mintShare', tokenType: mintState.sale.tokenType });
      setShareExpiration(Date.now() + secondsToMs(120));
      setNotice('Please be patient, sharing can take a minute.');
      setShareClicked(true);
      mintDispatch({
        type: MintActionType.UpdateMintedError,
        errorMsg: null,
      });

      // safe to kick off share asynchronously (purposefully not awaiting)
      tweetStory()
        .then(() => {
          analyticsService.track(AnalyticsEventName.Share, {
            type: ShareType.TWITTER,
            success: true,
          });
        })
        .catch((e) => {
          // thoughts: should we tell the user the tweeting didn't work at the end of the mint?
          analyticsService.track(AnalyticsEventName.Share, {
            type: ShareType.ERROR,
            success: false,
            message: e.message,
          });
          // just log
          log('Error occurred while tweeting story (continuing with mint):', e);
        });
      mintDispatch({
        type: MintActionType.StartMint,
        shared: true,
      });

      setShareClicked(false);
    } else {
      analyticsService.track(AnalyticsEventName.ButtonPress, { buttonName: 'mint', tokenType: mintState.sale.tokenType });
      setShareClicked(true);
      mintDispatch({
        type: MintActionType.StartMint,
        shared: mintState.alreadyMintShared,
      });
    }
  }

  // detect when viewer has loaded
  useEffect(() => {
    if (!ref) {
      return;
    }
    if (ref.type !== MintVideoContentType.Viewer || mintState.alreadyMintShared) {
      // if not viewer or they already shared, we don't need to wait for load
      setLoadState(ViewerLoadState.LoadExpired);
      return;
    }

    if (loadState === ViewerLoadState.Loaded) {
      // no need to wait for load, already loaded
      return;
    }

    function onHostedAppLoaded() {
      setLoadState(ViewerLoadState.Loaded);
    }

    navbarService.once(SvsNavbarEvent.HostedAppLoaded, onHostedAppLoaded);

    return () => {
      navbarService.off(SvsNavbarEvent.HostedAppLoaded, onHostedAppLoaded);
    };
  }, [ref, mintState.alreadyMintShared, loadState]);

  // enable mint button after 15 seconds if story hasn't loaded yet
  useEffect(() => {
    if (mintState.alreadyMintShared) {
      // don't need to expire if they've already shared
      return;
    }
    if (!ref) {
      return;
    }
    if (ref.type !== MintVideoContentType.Viewer) {
      // if its not viewer, we don't need to have a load expiration
      setLoadState(ViewerLoadState.LoadExpired);
      return;
    }
    if (loadState === ViewerLoadState.LoadExpired) {
      // don't need to expire if it already expired
      return;
    }
    if (loadState === ViewerLoadState.Loaded) {
      // don't need to expire if it already loaded
      return;
    }

    const timeoutId = setTimeout(() => {
      setLoadState(ViewerLoadState.LoadExpired);
    }, secondsToMs(15));

    return () => {
      clearTimeout(timeoutId);
    };
  }, [ref, mintState.alreadyMintShared, loadState]);

  // when share times out, start the share
  useEffect(() => {
    if (shareExpiration === -1) {
      return;
    }
    if (!shareClicked) {
      return;
    }
    function triggerStartMint(shared: boolean) {
      // checking if they changed addresses mid-share
      if (!matchingAddr) {
        // do not proceed if addresses do not match
        if (shared) {
          // mark as shared so they don't have to share again
          mintDispatch({
            type: MintActionType.UpdateAlreadyShared,
            shared: true,
          });
        }
        return;
      }
      mintDispatch({
        type: MintActionType.StartMint,
        shared,
      });
    }

    const delta = shareExpiration - Date.now();
    if (delta <= 0) {
      triggerStartMint(true);
      return;
    }

    const timeoutId = setTimeout(() => triggerStartMint(true), delta);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [shareExpiration, matchingAddr, shareClicked]);

  // check twitter login state (but not verify credentials)
  useEffect(() => {
    if (twtAuthState !== TwtAuthState.Idle) {
      return;
    }

    twitterService.auth.canWrite().then((loggedIn) => {
      if (loggedIn) {
        setTwtAuthState(TwtAuthState.Authorized);
      } else {
        setTwtAuthState(TwtAuthState.Unauthorized);
      }
    });
  }, [twtAuthState]);

  // set button label based on certain states
  useEffect(() => {
    if (mintState.alreadyMintShared || !mintState.storyKey) {
      // already shared or no story is returned
      setNotice(null);
      setButtonLabel('MINT!');
      return;
    }
    if (twtAuthState === TwtAuthState.Idle || twtAuthState === TwtAuthState.Checking) {
      setNotice(null);
      setButtonLabel('SHARE TO MINT!');
      return;
    }
    if (twtAuthState === TwtAuthState.Unauthorized) {
      setNotice('Sharing will require Twitter authorization.');
      setButtonLabel('SHARE TO MINT!');
      return;
    }
    if (loadState === ViewerLoadState.LoadExpired) {
      setNotice(null);
      setButtonLabel('SHARE TO MINT!');
      return;
    }
    if (loadState === ViewerLoadState.Loaded) {
      setNotice(null);
      setButtonLabel('SHARE TO MINT!');
      return;
    }
  }, [loadState, twtAuthState, mintState.alreadyMintShared]);

  const loaded = loadState === ViewerLoadState.LoadExpired || loadState === ViewerLoadState.Loaded;

  return (
    <MintLayout title={'Ready to enter\nStoryverse!'} subtitle="Share your story to mint your Characters" className="mintshare-state-page">
      <MintVideoContent ref={setRef} />
      <div className="notice-con">
        {matchingAddr && !notice && !mintState.mintedError && <p className="notice">&nbsp;</p>}
        {notice && <p className="notice">{notice}</p>}
        {mintState.mintedError && <p className="error-notice">{mintState.mintedError}</p>}
        {!matchingAddr && (
          <>
            <p className="error-notice">Please connect with the same hot wallet you initially connected with.</p>
            <p className="error-notice">
              (Expected: {hotWalletAddress}, current: {userAddress})
            </p>
          </>
        )}
      </div>
      <button className="mint-btn-cta mint-share-btn" onClick={onShare} disabled={shareClicked || !loaded || !matchingAddr}>
        {buttonLabel}
      </button>
      <button className="svs-btn back-btn" onClick={onBack}>
        BACK
      </button>
    </MintLayout>
  );
}

function MintingStatePage() {
  const { saleService, tokenproofService } = mainSuite;
  const mintState = useMintState();
  const mintDispatch = useMintDispatch();

  // we're sending the transaction, so there's no need to check for mismatched addresses
  useEffect(() => {
    if (!mintState.mintAndSig) {
      mintDispatch({
        type: MintActionType.MintFailed,
        errorMsg: 'Missing mint data.',
      });
      return;
    }
    let stale = false;
    const nonce = tokenproofService.getNonce();
    saleService
      .mintWithSignature({
        saleId: mintState.sale.saleId,
        payload: {
          rawMessage: mintState.mintAndSig.rawMessage,
          signature: mintState.mintAndSig.signature,
          nonce,
          hotWallet: mintState.hotWallet,
          platform: mintState.platform,
        },
      })
      .then(() => {
        if (stale) {
          return;
        }

        mintDispatch({
          type: MintActionType.MintFinished,
        });
      })
      .catch((e) => {
        if (stale) {
          return;
        }

        // we got past minting, but could not get receipt
        if (e.mintingReceiptError && environment.treatReceiptErrorAsSuccess) {
          log('We could not verify the transaction has completed:', e);
          mintDispatch({
            type: MintActionType.MintFinished,
            errorMsg: environment.showMintSuccessError ? 'Transaction has started, but we could not verify the transaction has completed.' : null,
          });
          return;
        }

        // we got past minting and got the receipt,
        // we just couldn't record to our backend
        if (e.waitForMintingOfErrored && environment.treatWaitForMintAsSuccess) {
          log('Transaction has been completed, but could not record success:', e);
          mintDispatch({
            type: MintActionType.MintFinished,
            errorMsg: environment.showMintSuccessError ? 'Transaction has been completed, but an error occurred while trying to confirm the success.' : null,
          });
          return;
        }

        log('Error occurred while minting:', e);
        mintDispatch({
          type: MintActionType.MintFailed,
          errorMsg: 'Minting failed. Please try again.',
        });
        mainSuite.analyticsService.track(AnalyticsEventName.MintError, {
          errorCode: e.code,
          errorMessage: e.message,
          errorStack: e.stack,
          tokenType: mintState.sale?.tokenType,
        });
      });

    return () => {
      stale = true;
    };
  }, [mintState.mintAndSig, mintState.sale]);

  return (
    <div className="minting-state-page">
      <GradientTitle>Minting&hellip;</GradientTitle>
      <TransitioningSection />
    </div>
  );
}

function MintedStatePage() {
  const mintState = useMintState();
  const { emailSubService, analyticsService } = mainSuite;

  const viewPath = useMemo(() => {
    if (!mintState.storyKey) {
      return null;
    }
    return `/view/${mintState.storyKey.address}/${mintState.storyKey.id}/ai`;
  }, [mintState.storyKey]);

  useEffect(() => {
    if (mintState.signUpEmail) {
      emailSubService
        .subscribeEmail({
          socialHandles: {
            email: mintState.signUpEmail,
          },
        })
        .catch(() => {
          // do nothing, just silence erro
        });
    }
  }, [mintState.signUpEmail]);

  useEffect(() => {
    analyticsService.track(AnalyticsEventName.MintSuccess, {
      added: mintState.eligibleAdded,
      count: mintState.eligibleTokens,
      hasStoryKey: !!mintState.storyKey,
      addedEmail: !!mintState.signUpEmail,
      tokenType: mintState.sale?.tokenType,
    });
  }, []);

  let nftName: string;
  if (mintState.holdings && mintState.sale?.tokenNameSingular && mintState.sale?.tokenNamePlural) {
    if (mintState.holdings.length === 1) {
      nftName = mintState.sale.tokenNameSingular;
    } else {
      nftName = mintState.sale.tokenNamePlural;
    }
  } else {
    nftName = 'NFT';
  }

  return (
    <MintLayout
      title={'Congrats! You minted\na Character Pass!'}
      subtitle={`Your ${nftName} will now be in collectible stories!`}
      className="minted-state-page"
    >
      <div className="minted-body">
        <VimeoReactPlayer url={getVimeoUrl()} autoPlayType="inview" loop className="video-300" />
        <div className="description">
          <p className="mint-layout-subtitle center">
            <b>Founder Pass</b> holders will have
          </p>
          <p className="mint-gradient-title center bottomText">
            <b>EARLY ACCESS</b> to Collectible Stories
          </p>
          <a href="https://opensea.io/collection/storyverse-founders-pass" className="mint-btn-cta btn-buy-now" target="_blank">
            VIEW IN OPENSEA
          </a>
        </div>
        {mintState.mintedError && <p className="error-notice">{mintState.mintedError}</p>}
      </div>
    </MintLayout>
  );
}

function MintNoSupplyStatePage() {
  const mintState = useMintState();
  const [showSpinner, setShowSpinner] = useState(false);
  return (
    <MintLayout title={mintState.sale.saleName} subtitle="No more supply. Sign up for future mints!" className="mintnosupply-state-page-layout">
      <MintVideoLayout videoContent={<VimeoReactPlayer url={getVimeoUrl()} autoPlayType="inview" loop />} showSpinner={showSpinner}>
        <EmailSignUpSection title="Waitlist Signup" setShowSpinner={setShowSpinner} hideCountdown />
      </MintVideoLayout>
    </MintLayout>
  );
}

function PostMintStatePage() {
  const mintState = useMintState();
  const [showSpinner, setShowSpinner] = useState(false);
  return (
    <MintLayout title={mintState.sale.saleName} subtitle="Mint period ended. Sign up for future mints!" className="postmint-state-page-layout">
      <MintVideoLayout videoContent={<VimeoReactPlayer url={getVimeoUrl()} autoPlayType="inview" loop />} showSpinner={showSpinner}>
        <EmailSignUpSection title="Waitlist Signup" setShowSpinner={setShowSpinner} hideCountdown />
      </MintVideoLayout>
    </MintLayout>
  );
}

function ErrorSection({ children }: { children?: React.ReactNode }) {
  return (
    <div className="alert alert-danger" role="alert">
      {children}
    </div>
  );
}

type StateConfig = {
  content: React.ReactNode;
  analyticsPageName: string;
  metamaskRequired: boolean;
};

const stateMap: Partial<Record<MintPageState, StateConfig>> = {
  [MintPageState.Mint]: {
    content: <MintStatePage />,
    analyticsPageName: 'mint',
    metamaskRequired: false,
  },
  [MintPageState.MintEntry]: {
    content: <MintEntryStatePage />,
    analyticsPageName: 'mintEntry',
    metamaskRequired: true,
  },
  [MintPageState.MintShare]: {
    content: <MintShareStatePage />,
    analyticsPageName: 'mintShare',
    metamaskRequired: true,
  },
  [MintPageState.Minting]: {
    content: <MintingStatePage />,
    analyticsPageName: 'minting',
    metamaskRequired: true,
  },
  [MintPageState.Minted]: {
    content: <MintedStatePage />,
    analyticsPageName: 'minted',
    metamaskRequired: true,
  },
  [MintPageState.MintNoSupply]: {
    content: <MintNoSupplyStatePage />,
    analyticsPageName: 'mintNoSupply',
    metamaskRequired: false,
  },
  [MintPageState.PostMint]: {
    content: <PostMintStatePage />,
    analyticsPageName: 'mintPost',
    metamaskRequired: false,
  },
};

export function MintSubPage() {
  const mintState = useMintState();
  const mintDispatch = useMintDispatch();
  const userState = useUserState();

  useEffect(() => {
    const stateConfig = stateMap[mintState.pageState] ?? null;
    if (!stateConfig) {
      return;
    }

    if (stateConfig.metamaskRequired && !userState.loggedIn) {
      mintDispatch({
        type: MintActionType.Reset,
      });
    }
  }, [mintState.pageState, userState.loggedIn]);

  const stateConfig = stateMap[mintState.pageState] ?? null;
  const content = stateConfig?.content ?? <ErrorSection>Unknown error occurred (mint).</ErrorSection>;
  const analyticsPageName = stateConfig?.analyticsPageName ?? 'mintUnknown';
  return (
    <Page title="Mint" className="mint-subpage container mb-5" showFooterDiscordOnly analyticsPageName={analyticsPageName}>
      {content}
    </Page>
  );
}

export default MintSubPage;
