import AvatarImg from './avatar.png';
import ThumbImg from './thumb.png';

export default {
  avatar: AvatarImg,
  thumb: ThumbImg,
  author: 'Dummy Anonymus',
  url: '/',
  footnote: 'Dummy footnote. It is indeed a footnote.',
};
