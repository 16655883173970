import './MintCollection.scss';

export type MintCollectionProps = {};

let minting = false;
let minted = false;
let buying = false;
let bought = false;

export function MintCollection({}: MintCollectionProps) {
  if (!minted) {
    return (
      <div className="coll-mint-container">
        <div className="coll-mint-price">
          <div className="coll-mint-price-title">Price</div>
          <div className="coll-mint-price-value-eth">FREE</div>
        </div>
        <div className="coll-mint-price">
          <div className="coll-mint-price-timer">Expires in 14d</div>
          <div className="coll-mint-price-value-dollar">126.45$</div>
        </div>

        <button type="submit" className="coll-mint-btn white" aria-label="Submit" disabled={minted || minting}>
          {minting ? 'Minting...' : 'Mint Now'}
        </button>

        <div className="coll-mint-gas">Gas: 31 Gwei / $23.75</div>
      </div>
    );
  } else {
    return (
      <div className="coll-mint-container">
        <div className="coll-mint-price">
          <div className="coll-mint-price-title">Price</div>
          <div className="coll-mint-price-value-eth">126.45$</div>
        </div>
        <div className="coll-mint-price">
          <div className="coll-mint-price-timer expired">Mint has expired</div>
        </div>

        <button type="submit" className="coll-mint-btn blue" aria-label="Submit" disabled={buying || bought}>
          {minting ? 'Buying...' : 'Buy Now'}
        </button>

        <div className="coll-mint-gas">Gas: 31 Gwei / $23.75</div>
      </div>
    );
  }
}

export default MintCollection;
