import { useParams } from 'react-router-dom';
import templateAliases from '../../assets/data/templatealias.json';
import { GameSiteUrlParams } from '@storyverseco/svs-consts';
import { Navigate } from 'react-router-dom';

const masterAliasUrl = '/view/0x0/ce47cc1cd025af8bafdb2f96836fd5af126cdba3cd5bba6a405f961e6a3fd641/ai';

interface ShortcutPageProps {
  isMaster?: boolean;
}

export function CommunityPageRedirect(props: ShortcutPageProps) {
  const params = useParams();
  const community = params[GameSiteUrlParams.CommunityShortcut];
  let newUrl = masterAliasUrl;
  if (!props.isMaster && templateAliases[community]?.aiLink) {
    newUrl = templateAliases[community]?.aiLink;
  }

  return <Navigate to={`${newUrl}`} />;
}
