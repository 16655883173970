import { debug } from './LogWrapper';
import { StoryKey, StoryKeyInfix, StoryKeyPrefix } from '@storyverseco/svs-types';

const log = debug('app:common:StoryKey');

export type StoryKeyObject = {
  address: string;
  id: string;
  prefix: StoryKeyPrefix;
  suffix: string;
  infix?: StoryKeyInfix;
};

export function isStoryKeyObject(obj: any): obj is StoryKeyObject {
  // also check for falsy empty strings for address and id
  return (
    obj &&
    typeof obj === 'object' &&
    typeof obj.address === 'string' &&
    Boolean(obj.address) &&
    typeof obj.id === 'string' &&
    Boolean(obj.id) &&
    typeof obj.prefix === 'string' &&
    (obj.prefix === '' || obj.prefix === 'stories' || obj.prefix === 'templates') &&
    typeof obj.suffix === 'string' &&
    (!('infix' in obj) || obj.infix === 'nfts')
  );
}

export function normalizeStoryKey(obj: unknown): StoryKey | null {
  if (!obj) {
    return null;
  }

  if (obj instanceof StoryKey) {
    return obj;
  }

  if (isStoryKeyObject(obj)) {
    return new StoryKey(obj.prefix, obj.address, obj.id, obj.suffix, obj.infix);
  }

  if (typeof obj === 'string') {
    const parts = obj.split('/');

    // prefix/walletAddress/infix/storyId.suffix
    if (parts.length === 4) {
      const [prefix, address, infix, idJson] = parts;
      const [id, suffix] = idJson.split('.');
      return new StoryKey(prefix as StoryKeyPrefix, address, id, suffix, infix as StoryKeyInfix);
    }

    // prefix/walletAddress/storyId.suffix
    if (parts.length === 3) {
      const [prefix, address, idJson] = parts;
      const [id, suffix] = idJson.split('.');
      return new StoryKey(prefix as StoryKeyPrefix, address, id, suffix);
    }

    // walletAddress/storyId
    if (parts.length === 2) {
      return new StoryKey('stories', parts[0], parts[1]);
    }
  }

  log('could not normalize story key:', obj);

  return null;
}
