import { useState, useMemo } from 'react';
import { waitForMs } from '../../common/WaitUtils';
import { environment } from '../../environments/environment';
import { MockModal, ModalCloseCallback } from './MockModal';
import { MockLoginUser, mockUsers } from './MockUtils';
import './MockLoginScreen.scss';

export enum MockLoginActionType {
  Add, Select, Close
}

export type MockLoginCallback = (actionType: MockLoginActionType, data?: any) => void;

export function MockLoginScreen({ callback }: { callback: MockLoginCallback }): JSX.Element {

  const users: MockLoginUser[] = useMemo(() => [...mockUsers], []);
  const [loggingIn, setLoggingIn] = useState(false);

  async function chooseUser(e) {
    const address = e.currentTarget.dataset.address;
    setLoggingIn(true);
    await waitForMs(environment.mockLoadingMs);
    const user = users.find(user => user.address === address);
    callback(MockLoginActionType.Select, user);
  }

  return !users ? (
    <div className="card-body">
      Loading...
    </div>
  ) : loggingIn ? (
    <div className="text-center p-3">
      <div className="spinner-border text-dark" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <ul className="list-group list-group-flush">
      { users.map(user => (
        <li key={user.address} className="list-group-item" onClick={chooseUser} data-address={user.address}>
          <p>{user.name}</p>
          <small className="text-muted">{user.address}</small>
        </li>
      ))}
    </ul>
  );
}

export function createLoginScreen(
  callback: MockLoginCallback,
  closeCallback: ModalCloseCallback,
  title?: string,
): MockModal {
  return new MockModal(
    title ?? 'Login', MockLoginScreen, {},
    'mock-login-screen', callback,
    closeCallback,
    environment.mockLoadingMs
  );
}
