import { debug } from '@common/LogWrapper';
import { removeUndefinedValues } from '@common/ObjectUtils';
import { UserAccount } from '@common/UserAccount';
import { AnalyticsEventName } from './AnalyticsEventName';
import { AnalyticsEventOptions, AnalyticsShareResults } from './AnalyticsEventOptions';
import { AnalyticsService } from './AnalyticsService';
import { AnalyticsUserOptions } from './AnalyticsUserOptions';

const log = debug('app:services:MockAnalyticsService');

export class MockAnalyticsService implements AnalyticsService {
  private isEntryFinalSent = false;

  private innerUserOptions: AnalyticsUserOptions = {};
  private persistedOptions: AnalyticsEventOptions = {};

  init(): void {
    log('client initted');
  }
  setPersistedOptions(options?: AnalyticsEventOptions): void {
    this.persistedOptions = removeUndefinedValues({
      ...this.persistedOptions,
      ...options,
    });
    log('PersistedOptions', this.persistedOptions);
  }
  setUserState(state: UserAccount): void {
    this.userTrack({
      appName: 'mintingWebsite',
      walletId: state.address,
    });
  }
  userTrack(userOptions: AnalyticsUserOptions): void {
    if (!this.isUserOptionsDiff(userOptions)) {
      return;
    }
    log('Set user props', userOptions);
    this.innerUserOptions = {
      ...this.innerUserOptions,
      ...userOptions,
    };
    log('innerUserOptions', this.innerUserOptions);
  }
  track(name: AnalyticsEventName, options?: AnalyticsEventOptions): void {
    if (name === AnalyticsEventName.EntryFinal) {
      if (this.isEntryFinalSent) {
        return;
      }
      this.isEntryFinalSent = true;
    }
    const combined = { ...this.persistedOptions, ...options };
    log('Track event', name, combined);
  }

  trackShareStatus(shareStatus: AnalyticsShareResults): void {
    log('Track share status', shareStatus);
  }

  private isUserOptionsDiff(userOptions: AnalyticsUserOptions): boolean {
    for (const key of Object.keys(userOptions)) {
      if (!(key in this.innerUserOptions)) {
        return true;
      }
      if (this.innerUserOptions[key] !== userOptions[key]) {
        return true;
      }
    }
    return false;
  }
}
