
function defaultCondition(value: any): boolean {
  return !!value;
}

export type Condition<T> = (value: T) => boolean;
export type Resolve<T> = (value: T) => void;
export type Reject = (reason: any) => void;

export class ConditionWaiter<T> {
  private value: T = undefined;
  private condition: Condition<T>;
  private resolves: Resolve<T>[] = [];
  private rejects: Reject[] = [];

  constructor(initialValue?: T, condition?: Condition<T>) {
    if (!condition) { condition = defaultCondition; }

    this.value = initialValue;
    this.condition = condition;
  }

  private checkAndMaybeResolve() {
    if (!this.condition(this.value)) { return; }

    this.resolves.forEach(resolve => resolve(this.value));
    this.resolves = [];
    this.rejects = [];
  }

  setCondition(condition: Condition<T>) {
    this.condition = condition;
  }

  set(value: T) {
    this.value = value;
    this.checkAndMaybeResolve();
  }

  get(): T {
    return this.value;
  }

  wait(): Promise<T> {
    if (this.condition(this.value)) { return Promise.resolve(this.value); }

    return new Promise((resolve, reject) => {
      this.resolves.push(resolve)
      this.rejects.push(reject);
    });
  }

  reject(reason: any): void {
    this.rejects.forEach(reject => reject(reason));
    this.resolves = [];
    this.rejects = [];
  }
}
