import { LoadState } from './LoadState';

export const notEligible = -1;

export type UserPlotsState = {
  eligiblePlotsState: LoadState,
  eligiblePlotsError: string,
  eligiblePlots: number,
  maxPlots: number,
  plotIdsState: LoadState,
  plotIdsError: string,
  plotIds: string[],
};
