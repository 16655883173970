import { useState, useEffect, useCallback } from 'react';
import { useMintState } from '@context/mint/MintContext';
import { mainSuite } from '@services/ServiceFactory';
import GradientTitle from '@components/GradientTitle';
import { Link } from 'react-router-dom';
import './WhitelistCheckedSection.scss';
import { MintCountdown } from './MintCountdown';
import { AnalyticsEventName, ShareType } from '@services/analytics/AnalyticsEventName';
import { SvsNavbarEvent } from '@storyverseco/svs-navbar';

export function WhitelistCheckedSection({ viewPath }: { viewPath?: string | null }) {
  const { analyticsService, emailSubService, navbarService } = mainSuite;
  const mintState = useMintState();
  const [emailSubbed, setEmailSubbed] = useState(false);

  const [shareBtnEnabled, setShareBtnEnabled] = useState(false);

  const [shareClicked, setShareClicked] = useState(false);

  useEffect(() => {
    if (emailSubbed) {
      return;
    }

    // empty strings are falsy too
    if (!mintState.signUpEmail) {
      setEmailSubbed(true);
      return;
    }

    // we don't really care if this was successful or not
    emailSubService.subscribeEmail({ socialHandles: { email: mintState.signUpEmail } }).finally(() => setEmailSubbed(true));
  }, [emailSubbed]);

  useEffect(() => {
    const enableShare = () => {
      navbarService.off(SvsNavbarEvent.HostedAppLoaded, enableShare);
      // The event we listen to might have some delay before showing the characters
      setTimeout(() => {
        setShareBtnEnabled(true);
      }, 2000);
    };

    navbarService.on(SvsNavbarEvent.HostedAppLoaded, enableShare);
  }, []);

  useEffect(() => {
    analyticsService.track(AnalyticsEventName.WhitelistAddSuccess, {
      added: mintState.eligibleAdded,
      count: mintState.eligibleTokens,
      hasStoryKey: !!mintState.storyKey,
      addedEmail: !!mintState.signUpEmail,
    });
  }, []);

  useEffect(() => {
    const trackShareResults = (shareResult: any) => {
      analyticsService.trackShareStatus(shareResult);
    };
    const trackShareResultsError = (e: any) => {
      analyticsService.track(AnalyticsEventName.Share, {
        type: ShareType.ERROR,
        success: false,
        message: e.message,
      });
    };
    navbarService.on(SvsNavbarEvent.TriggerShareResult, trackShareResults);
    navbarService.on(SvsNavbarEvent.TriggerShareResultError, trackShareResultsError);
    return () => {
      navbarService.off(SvsNavbarEvent.TriggerShareResult, trackShareResults);
      navbarService.off(SvsNavbarEvent.TriggerShareResultError, trackShareResultsError);
    };
  }, []);

  const onShareClick = useCallback(() => {
    analyticsService.track(AnalyticsEventName.ButtonPress, { buttonName: 'whitelistShare' });
    setShareClicked(true);
    navbarService.triggerClientShare();
  }, []);

  let successMessage: React.ReactNode = null;
  let content: React.ReactNode;
  // only show story if we've added new ones
  if (mintState.eligibleAdded > 0 && viewPath) {
    successMessage = (
      <p className="success-message">
        Success! You have {mintState.eligibleTokens} whitelist {mintState.eligibleTokens === 1 ? 'spot' : 'spots'}.
      </p>
    );
    let mainText: React.ReactNode;
    if (mintState.eligibleAdded === 1) {
      mainText = <GradientTitle>We&apos;ve created an OpenAI story with your Ape!</GradientTitle>;
    } else if (mintState.eligibleAdded > 3) {
      mainText = <GradientTitle>We&apos;ve created an OpenAI story with some of your Apes!</GradientTitle>;
    } else {
      // 2 or 3
      mainText = <GradientTitle>We&apos;ve created an OpenAI story with your Apes!</GradientTitle>;
    }
    content = (
      <>
        {mainText}
        <button className={`mint-btn-cta ${shareBtnEnabled ? '' : 'btn-disabled'} ${shareClicked ? 'btn-disabled' : ''}`} onClick={onShareClick}>
          SHARE YOUR STORY!
        </button>
      </>
    );
  } else if (mintState.eligibleAdded > 0) {
    successMessage = (
      <p className="success-message">
        Success! You have {mintState.eligibleTokens} whitelist {mintState.eligibleTokens === 1 ? 'spot' : 'spots'}.
      </p>
    );
    content = <GradientTitle>You are now in the whitelist!</GradientTitle>;
  } else if (mintState.eligibleTokens > 0) {
    successMessage = (
      <p className="success-message">
        You have {mintState.eligibleTokens} whitelist {mintState.eligibleTokens === 1 ? 'spot' : 'spots'}.
      </p>
    );
    content = <GradientTitle>You are already in the whitelist!</GradientTitle>;
  } else {
    content = <GradientTitle>You are not eligible for the whitelist.</GradientTitle>;
  }

  return (
    <div className="whitelist-checked-section">
      {successMessage}
      <MintCountdown />
      {content}
    </div>
  );
}
