import { AmpAnalyticsService } from '../analytics/AmpAnalyticsService';
import { AnalyticsService } from '../analytics/AnalyticsService';
import { ApiType } from '../ApiType';
import { BaseSuite } from './BaseSuite';
import { registerSuite } from './Suite';

class AmpSuite extends BaseSuite {
  readonly apiType = ApiType.Amp;

  private _analyticsService: AmpAnalyticsService;
  
  get analyticsService(): AnalyticsService {
    if (!this._analyticsService) {
      this._analyticsService = new AmpAnalyticsService();
    }
    return this._analyticsService;
  }
}

export const ampSuite = new AmpSuite();
registerSuite(ampSuite);
