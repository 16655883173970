export const bracketsRegex = /\$?\{\s*([a-zA-Z0-9_]+)\s*\}/gi;
export const ltgtRegex = /<\s*([a-zA-Z0-9_]+)\s*>/gi;

/**
 * Interpolates a string with a specified token map. Optionally strict that will throw an error if there is no matching string token in the mapping.
 *
 * The token pattern is `{tokenName}`.
 *
 * If `ltgt` is true, the token pattern is `<tokenName>`.
 *
 * @param str The string with string tokens.
 * @param tokens The token map to replace string tokens with.
 * @param strict If true, it will throw an error if there is a string token that is not in the specified token map.
 * @param ltgt If true, it will
 * @returns interpolated string.
 */
export function tokenReplace<TTokens extends Record<string, string> = Record<string, string>>(str: string, tokens: TTokens, strict = false, ltgt = false) {
  const regex = ltgt ? ltgtRegex : bracketsRegex;
  return str.replace(regex, (_substring, p1) => {
    if (p1 && p1 in tokens && typeof tokens[p1] === 'string') {
      return tokens[p1];
    }
    // if no match and strict, throw error
    if (strict) {
      throw new Error(`tokenReplace: Missing "${p1}" string token`);
    }
    // if no match, replace with empty string
    return '';
  });
}

export function truncateAddress(address: string | null | undefined): string | null {
  if (!address) {
    return null;
  }
  if (address.length <= 9) {
    return address;
  }
  // u2026 = ellipsis
  return address.slice(0, 5) + '\u2026' + address.slice(-4);
}
