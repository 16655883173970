import React from 'react';
import { debug } from '@common/LogWrapper';

import { UserAccount } from '../../common/UserAccount';
import { hasPermission, UserPermission } from '../../common/UserPermission';
import { Action } from '../../context/GenerateContext';
import { MetadataService } from '../metadata/MetadataService';
import { MockStorageService } from '../mock/MockStorageService';
import { UserService } from '../user/UserService';
import { AdminService } from './AdminService';
import * as ethers from 'ethers';

const log = debug('app:services:admin:MockAdminService');

const adminServiceKey = 'adminService';
const nftIdPoolKey = 'nftIdPool';

export class MockAdminService implements AdminService {
  private details: Play.ServiceAccountDetails;
  private userState: UserAccount;
  private userDispatch: React.Dispatch<Action>;
  private nftIdPool: number;

  constructor(
    private mockStorageService: MockStorageService,
    private metadataService: MetadataService,
    private userService: UserService
  ) {
    // account details
    try {
      this.details = mockStorageService.getJson(adminServiceKey) ?? {};
    } catch (e) {
      this.details = {};
      mockStorageService.putJson(adminServiceKey, this.details);
    }

    // nft id pool
    try {
      const nftIdPoolStr = mockStorageService.get(nftIdPoolKey);
      if (nftIdPoolStr) {
        this.nftIdPool = parseInt(nftIdPoolStr);
      } else {
        this.nftIdPool = 1;
      }
    } catch (e) {
      this.nftIdPool = 1;
      this.saveNftIdPool();
    }
  }
  private saveNftIdPool() {
    this.mockStorageService.put(nftIdPoolKey, this.nftIdPool.toString());
  }
  private saveDetails() {
    this.mockStorageService.putJson(adminServiceKey, this.details);
  }
  setUserDispatch(dispatch: React.Dispatch<Action>): void {
    this.userDispatch = dispatch;
  }
  setUserState(state: UserAccount): void {
    this.userState = state;
  }
  async linkService(): Promise<void> {
    if (!hasPermission(this.userState.permissions, UserPermission.Service)) { return; }

    if (!this.details.sale) {
      this.details.sale = [];
    }
    this.details.saleInitted = true;
    if (!this.details.balance) {
      this.details.balance = '0.000000';
    }
    if (!this.details.nfts) {
      this.details.nfts = [];
    }

    // this would also link the non-service account data, since they're one and the same
    // await this.userService.link();
    this.saveDetails();
  }
  async loadServiceSetup(): Promise<Play.ServiceAccountDetails> {
    if (!hasPermission(this.userState.permissions, UserPermission.Service)) { return undefined; }

    return {...this.details};
  }

  clearMockData(): void {
    this.mockStorageService.clear();
    this.mockStorageService.setAllowMockData(false);
  }

  allowMockData(): void {
    this.mockStorageService.clear();
    this.mockStorageService.setAllowMockData(true);
  }

  exportMockData(): string {
    const data = this.mockStorageService.exportData();
    return JSON.stringify(data, undefined, 2);
  }

  hasEditableChainBalance(): boolean {
    return !!this.userService.setChainBalance;
  }

  async addToChainBalance(receiverAddress: string, amount: string): Promise<void> {
    const chainBalance = this.userService.getChainBalance(receiverAddress, amount);
    const chainBalanceBN = ethers.utils.parseEther(chainBalance);
    const amountBN = ethers.utils.parseEther(amount);

    const newChainBalanceBN = chainBalanceBN.add(amountBN);
    this.userService.setChainBalance(receiverAddress, ethers.utils.formatEther(newChainBalanceBN));
  }
}
