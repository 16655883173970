import React, { useState, useEffect, MouseEvent, useMemo, useCallback } from 'react';
import { loginStateInProgress } from '@common/LoginState';
import TokenproofButton from '@components/tokenproof-button';
import { MintActionType, MintCheckType, useMintDispatch, useMintState } from '@context/mint/MintContext';
import { mainSuite } from '@services/ServiceFactory';
import { useUserState } from '@services/user/UserContext';
import { useUserService } from '@hooks/useUserService';
import { SvsInputText } from '@components/svs-input-text/SvsInputText';
import emailIcon from '@assets/svg/email-icon.svg';
import GradientTitle from '@components/GradientTitle';
import { TpStatus } from '@services/tokenproof/TokenproofService';
import { MintCountdown } from './MintCountdown';
import './WhitelistSignUpSection.scss';
import { NeedAccountModal } from '../components/NeedAccountModal';
import { debug } from '@common/LogWrapper';
import { SaleFollowSocial, SaleSocialType } from '@common/Sale';
import { ArrowIcon } from '../components/ArrowIcon';
import { StepBox } from '../components/StepBox';
import { AnalyticsEventName } from '@services/analytics/AnalyticsEventName';
import { useUserHook } from '@hooks/useUserHook';
import { SvsProvider } from '@storyverseco/svs-navbar';

const log = debug('app:pages:sections:WhitelistSignUpSection');

const getWalletConnectTitle = (address?: string) => {
  if (!address) {
    return 'Connect your wallet';
  }
  const addressStart = address.substring(0, 4);
  const addressEnd = address.substring(address.length - 5, address.length - 1);
  return `Hello, ${addressStart}...${addressEnd}`;
};

const getTwitterStepTitle = (followSocials: SaleFollowSocial[]) => (
  <>
    Follow,{' '}
    {followSocials
      .filter((social) => social.type === SaleSocialType.Twitter)
      .map((social) => (
        <React.Fragment key={`step-title-${social.type}-${social.userId}`}>
          <a href={`https://twitter.com/${social.userId}`} target="_blank">
            {social.userId || ''}
          </a>{' '}
        </React.Fragment>
      ))}
  </>
);

function ConnectWalletBox({ onConnected }: { onConnected: (walletAddress: string) => void }) {
  const { analyticsService } = mainSuite;
  const mintState = useMintState();
  const { wallet, logIn } = useUserHook({ providerType: SvsProvider.WalletConnect });

  const openWalletConnect = useCallback(() => {
    if (wallet.flags.loggedIn) {
      return;
    }
    analyticsService.track(AnalyticsEventName.ButtonPress, {
      buttonName: 'walletConnect',
      saleId: mintState.sale?.saleId,
    });
    open();
  }, [wallet.flags.loggedIn, mintState.sale?.saleId]);

  useEffect(() => {
    if (wallet.flags.loggedIn) {
      onConnected(wallet.address);
    }
  }, [wallet]);

  return (
    <StepBox
      disabled={false}
      btn={{
        show: true,
        disabled: wallet.flags.loggedIn,
        label: wallet.flags.loggedIn ? 'CONNECTED' : 'CONNECT',
        showArrow: !wallet.flags.loggedIn,
        onClick: openWalletConnect,
      }}
      connected={wallet.flags.loggedIn}
      title={getWalletConnectTitle(wallet.address)}
      subTitle={wallet.flags.loggedIn ? 'Wallet Connected' : 'Sign up for the giveaway'}
      icon="wallet"
      iconColor="#D4D2ED"
      done={wallet.flags.loggedIn}
    />
  );
}

function VerifyTwitterBox({
  socialUsers,
  onVerified,
  onError,
  onPreVerify,
  disabled,
}: {
  socialUsers: SaleFollowSocial[];
  onVerified: () => void;
  onError?: (e: any) => void;
  onPreVerify?: () => void;
  disabled?: boolean;
}) {
  const { analyticsService, navbarService } = mainSuite;
  const { twitterService } = navbarService.api;
  const mintState = useMintState();
  const [twitterVerifying, setTwitterVerifying] = useState(false);
  const [triedVerifyOnce, setTriedVerifyOnce] = useState(false);
  const [isTwitterVerified, setIsTwitterVerified] = useState(false);

  const onTwitterVerify = useCallback(async () => {
    if (isTwitterVerified) {
      return;
    }
    setTwitterVerifying(true);
    const verifyingId = setTimeout(() => {
      setTwitterVerifying(false);
    }, 2000);
    analyticsService.track(AnalyticsEventName.ButtonPress, {
      buttonName: 'twitterVerify',
      saleId: mintState.sale?.saleId,
    });
    onPreVerify?.();
    setTriedVerifyOnce(true);
    try {
      // if logged in and wallet connected, check if twitter account is already in raffle
      // if (isConnected) {
      //   const twitterUser = await navbarSuite.twitterService.fetchUser();
      //   if (twitterUser) {
      //     const result = await emailSubService.isSubscribed({
      //       campaign: mintState?.sale?.marketingCampaign,
      //       walletAddress: address,
      //       twitterHandle: twitterUser.userName,
      //     });
      //     if (alreadyRegisteredStatuses.includes(result.twitterStatus)) {
      //       throw new Error('Already entered with that Twitter account.');
      //     }
      //   }
      // }

      const userNames = socialUsers.map((social) => social.userId);
      const followMap = await twitterService.fetchIsFollowingAll({ userNames });
      const followMapLc: Record<string, boolean> = Object.entries(followMap).reduce(
        (map, [key, value]) => ({
          ...map,
          [key.toLowerCase()]: value,
        }),
        {},
      );
      if (!userNames.every((user) => followMapLc[user])) {
        throw new Error(`Follow all ${userNames.length} Twitter accounts to register.`);
      }
      setIsTwitterVerified(true);
    } catch (e) {
      setIsTwitterVerified(false);
      onError?.(e);
    } finally {
      setTwitterVerifying(false);
      clearTimeout(verifyingId);
    }
  }, [isTwitterVerified, triedVerifyOnce, mintState.sale?.saleId, socialUsers, onPreVerify, onVerified, onError]);

  useEffect(() => {
    if (!isTwitterVerified) {
      return;
    }
    onVerified();
  }, [isTwitterVerified, onVerified]);

  return (
    <StepBox
      disabled={disabled}
      btn={{
        show: true,
        disabled: isTwitterVerified || disabled || twitterVerifying,
        label: twitterVerifying ? 'VERIFYING...' : isTwitterVerified ? 'VERIFIED' : 'VERIFY',
        showArrow: !isTwitterVerified,
        onClick: onTwitterVerify,
      }}
      title={getTwitterStepTitle(socialUsers)}
      subTitle={isTwitterVerified ? 'Verified' : 'Connect your Twitter'}
      icon={'twitter'}
      done={isTwitterVerified}
    />
  );
}

export function WhitelistSignUpSection({ setShowSpinner }: { setShowSpinner: (show: boolean) => void }) {
  const { tokenproofService, analyticsService } = mainSuite;
  const userState = useUserState();
  const userService = useUserService();
  const mintState = useMintState();
  const mintDispatch = useMintDispatch();
  const [usingTokenproof, setUsingTokenproof] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const [needAccountModal, setNeedAccountModal] = useState(false);
  const [hotWallet, setHotWallet] = useState<string | null>(null);
  const [coldWallet, setColdWallet] = useState<string | null>(null);
  const [isTwitterVerified, setIsTwitterVerified] = useState(false);

  const socialUsers = useMemo(() => {
    if (!mintState?.sale?.followSocials) {
      return [];
    }

    return mintState.sale.followSocials.filter((social) => social.type === SaleSocialType.Twitter && social.required);
  }, [mintState?.sale]);

  function checkWithMetamask() {
    log('checkWithMetamask email', email);
    if (socialUsers.length > 0) {
      setColdWallet(userState.address);
    } else {
      mintDispatch({
        type: MintActionType.StartCheck,
        checkType: MintCheckType.Metamask,
        walletAddress: userState.address,
        email: email || null, // resolve empty string as null
      });
    }
  }

  function checkWithTokenproof(address: string) {
    setColdWallet(address);
  }

  function tokenproofClicked() {
    setErrorMessage(null);
    if (tokenproofService.isLoggedIn()) {
      checkWithTokenproof(tokenproofService.getAccount().address);
    } else {
      setUsingTokenproof(true);
    }
  }

  function closeButtonClicked() {
    setNeedAccountModal(false);
  }

  function onSubmit() {
    if (socialUsers.length && !isTwitterVerified) {
      return;
    }
    if (!coldWallet || !hotWallet) {
      return;
    }
    if (usingTokenproof) {
      mintDispatch({
        type: MintActionType.UpdateHotWallet,
        wallet: hotWallet,
      });
      mintDispatch({
        type: MintActionType.UpdateColdWallet,
        wallet: coldWallet,
      });
      mintDispatch({
        type: MintActionType.StartCheck,
        checkType: MintCheckType.Tokenproof,
        walletAddress: coldWallet,
        email: email || null, // resolve empty string as null
      });
      return;
    }

    setErrorMessage('Unknown login check method');
  }

  // tokenproof
  useEffect(() => {
    if (!usingTokenproof) {
      return;
    }

    if (tokenproofService.isLoggedIn()) {
      checkWithTokenproof(tokenproofService.getAccount().address);
      return;
    }

    let stale = false;

    setLoggingIn(true);
    log('tokenproof login calling');
    // this is safe to call multiple times
    tokenproofService
      .login({
        mintPageState: mintState.pageState,
        tokenType: mintState.sale?.tokenType,
        policyId: mintState.sale?.tokenproofPolicyId,
      })
      .then((account) => {
        log('tokenproof login callback called', stale, account);
        if (stale) {
          return;
        }
        if (account) {
          checkWithTokenproof(account.address);
          return;
        }
        setUsingTokenproof(false);
        const status = tokenproofService.getStatus();
        if (status === TpStatus.Closed) {
          // it was cancelled
          log('Cancelled tokenproof login');
          setNeedAccountModal(true);
        } else if (status === TpStatus.Rejected) {
          log('Rejected by tokenproof');
          setNeedAccountModal(true);
        } else if (status === TpStatus.Unknown) {
          log('Unknown error occurred');
        }
      })
      .catch((err) => {
        log('Error occurred while logging in with tokenproof', err);
        setErrorMessage('Error occurred while authenticating');
      })
      .finally(() => {
        setLoggingIn(false);
      });

    return () => {
      log('tokenproof stale');
      stale = true;
    };
  }, [usingTokenproof, checkWithTokenproof]);

  // there's a chance loginState will be updated before loggedIn does,
  // so we're guarding this against a UI flash
  const loginInProgress = loginStateInProgress(userState.loginState) || loggingIn;

  useEffect(() => {
    setShowSpinner(loginInProgress);
  }, [loginInProgress]);

  // unsets showSpinner when unmounting
  useEffect(
    () => () => {
      setShowSpinner(false);
    },
    [],
  );

  const submitAvailable = Boolean((!socialUsers.length || (socialUsers.length && isTwitterVerified)) && hotWallet && coldWallet);

  return (
    <div className="signup-section">
      <MintCountdown />
      <GradientTitle>Sign up to get whitelisted</GradientTitle>
      <div className="">
        {/* <SvsInputText type="text" iconUrl={walletIcon} placeholder="Your wallet address" /> */}
        <SvsInputText type="email" iconUrl={emailIcon} placeholder="your@email.com" value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
      </div>
      <p className="email-notice desktop">By entering your email you consent to be contacted with marketing and promotional emails.</p>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {mintState.checkError && <p className="error-message">{mintState.checkError}</p>}

      <ConnectWalletBox onConnected={setHotWallet} />

      <div className="text-center">
        <TokenproofButton overrideOnClick={tokenproofClicked} style={{ width: '100%' }} disabled={Boolean(coldWallet || !hotWallet)} />
      </div>

      {Boolean(socialUsers.length) && (
        <VerifyTwitterBox
          socialUsers={socialUsers}
          onPreVerify={() => setErrorMessage(null)}
          onVerified={() => setIsTwitterVerified(true)}
          onError={(e) => setErrorMessage(e.message)}
          disabled={!coldWallet}
        />
      )}

      <button type="submit" className="mint-btn-cta" aria-label="Submit" disabled={!submitAvailable} onClick={onSubmit}>
        SIGN UP{' '}
        <span className="arrow-wrapper">
          <ArrowIcon />
        </span>
      </button>

      {/* <div className="text-center mb-5">
              <ManualCheckSection />
            </div> */}
      <p className="email-notice mobile">By entering your email you consent to be contacted with marketing and promotional emails.</p>
      {needAccountModal && <NeedAccountModal onClose={closeButtonClicked} />}
    </div>
  );
}
