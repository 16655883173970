
export const secondInMs = 1000;
export const minuteInMs = 60 * 1000;
export const hourInMs = 60 * 60 * 1000;
export const dayInMs = 24 * 60 * 60 * 1000;

export function msToSeconds(ms: number): number {
  return ms / secondInMs;
}

export function msToMinutes(ms: number): number {
  return ms / minuteInMs;
}

export function msToHours(ms: number): number {
  return ms / hourInMs;
}

export function msToDays(ms: number): number {
  return ms / dayInMs;
}

export function secondsToMs(seconds: number): number {
  return seconds * secondInMs;
}

export function minutesToMs(minutes: number): number {
  return minutes * minuteInMs;
}

export function hoursToMs(hours: number): number {
  return hours * hourInMs;
}

export function daysToMs(days: number): number {
  return days * dayInMs;
}

export function clamp(value: number, min: number, max: number): number {
  return value < min ? min : value > max ? max : value;
}
