import { ConditionWaiter } from './ConditionWaiter';

const inProgressSymbol = Symbol('inProgress');

export class DebounceWaiter<T> extends ConditionWaiter<T> {
  private inProgress = false;

  constructor() {
    super(undefined, () => !this.inProgress);
  }
  setInProgress(inProgress: boolean): void {
    this.inProgress = inProgress;
    if (!inProgress) {
      super.set(undefined);
    }
  }
  isInProgress(): boolean {
    return this.inProgress;
  }

  async wrap(fn: () => Promise<T>): Promise<T> {
    if (this.isInProgress()) {
      return await this.wait();
    }
    this.setInProgress(true);
    try {
      const result = await fn();
      this.set(result);
      return result;
    } catch (e) {
      this.reject(e);
      throw e; // rethrow
    }
  }

  override set(value: T): void {
    this.inProgress = false;
    // we trigger the resolves, then unset
    super.set(value);
    super.set(undefined);
  }

  override reject(reason: any): void {
    this.inProgress = false;
    super.reject(reason);
  }
}
