import { useNavigate } from 'react-router-dom';
import './ItemFeatured.scss';

import ItemTag from './ItemTag';
import { ItemProps } from './Item';
import { collections } from '../../../assets/homepage';
import { MintActionType, useMintDispatch } from '../../../context/mint/MintContext';

export type ItemFeaturedProps = ItemProps & {};

export function ItemFeatured({ id, title, mint, coll, type }: ItemFeaturedProps) {
  const navigate = useNavigate();
  const mintDispatch = useMintDispatch();
  const collectionMedia = {
    ...collections.placeholders,
    ...(collections[id] || {}),
  };

  function clickFeaturedItem(e: React.MouseEvent, id: string) {
    e.stopPropagation();
    console.warn('>>> clicking on featured item', id);
    mintDispatch({
      type: MintActionType.Reset,
    });
    navigate(collectionMedia.url);
  }

  return (
    <div className="item-featured-container" onClick={(e) => clickFeaturedItem(e, id)}>
      <div className="picture-container">
        <div className="picture">
          <div className="overlay" style={{ backgroundImage: `url(${collectionMedia.thumb})` }} />
          <ItemTag type={type} />
        </div>
      </div>

      <div className="content">
        <div className="title">{title}</div>
        <div className="user">
          <div className="avatar" style={{ backgroundImage: `url(${collectionMedia.avatar})` }} />
          <div className="name">{collectionMedia.author}</div>
        </div>
        <div className="bottom">
          <button type="submit" className="button-blue" aria-label="Submit">
            {type == 'live' ? 'Mint Now' : 'Register'}
          </button>
          <div className="labels">
            <div className="mint">{mint}</div>
            {/*<div className="coll">{coll}</div>*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemFeatured;
