import './PremintSubPage.scss';
import { debug } from '@common/LogWrapper';
import { MintPageState, useMintState } from '@context/mint/MintContext';
import { Page } from '../Page';
import { useMemo, useState } from 'react';
import { GameSiteUrlParams } from '@storyverseco/svs-consts';
import { environment } from '@environment';
import { HostedApp } from '@components/hosted-app/HostedApp';
import VimeoReactPlayer from '@components/vimeo-react-player/VimeoReactPlayer';
import MintVideoLayout from './layouts/MintVideoLayout';
import MintLayout from './layouts/MintLayout';
import { CharPassDates } from './sections/CharPassDates';
import { PreWhitelistSection } from './sections/PreWhitelistSection';
import { WhitelistSignUpSection } from './sections/WhitelistSignUpSection';
import { WhitelistCheckingSection } from './sections/WhitelistCheckingSection';
import { PremintSignUpSection } from './sections/PremintSignUpSection';
import { WhitelistCheckedSection } from './sections/WhitelistCheckedSection';
import { PremintFaqs } from './sections/PremintFaqs';
import { useAppState } from '@context/AppContext';
import { EmailSignUpSection } from './sections/EmailSignUpSection';
import { defaultVimeoUrl } from './components/MintVideoContent';
import { ErrorSection } from './components/ErrorSection';

export const log = debug('app:pages:PremintSubPage');

function getAnalyticsPageName(pageState: MintPageState) {
  switch (pageState) {
    case MintPageState.PreWhitelist:
      return 'preWhitelist';
    case MintPageState.Whitelist:
    case MintPageState.WhitelistChecking:
    case MintPageState.WhitelistChecked:
      return 'whitelist';
    case MintPageState.WhitelistNoSpots:
    case MintPageState.PreMint:
      return 'preMint';
    default:
      return 'preMintUnknown';
  }
}

export function PremintSubPage() {
  const [showSpinner, setShowSpinner] = useState(false);
  const mintState = useMintState();
  const appState = useAppState();

  const [hostedAppUrl, viewPath] = useMemo(() => {
    if (!mintState.storyKey) {
      return [null, null];
    }

    const viewSearchParams = new URLSearchParams();
    viewSearchParams.append(GameSiteUrlParams.WalletAddress, mintState.storyKey.address);
    viewSearchParams.append(GameSiteUrlParams.StoryId, mintState.storyKey.id);
    viewSearchParams.append(GameSiteUrlParams.Ai, 'true');
    return [`${environment.viewUrl}?${viewSearchParams.toString()}`, `/view/${mintState.storyKey.address}/${mintState.storyKey.id}/ai`];
  }, [mintState.storyKey]);

  let videoContent: React.ReactNode;
  if (mintState.eligibleAdded > 0 && mintState.storyKey) {
    videoContent = <HostedApp src={hostedAppUrl} />;
  } else {
    videoContent = <VimeoReactPlayer url={defaultVimeoUrl} autoPlayType="inview" loop />;
  }

  let title: string;
  switch (mintState.pageState) {
    case MintPageState.PreMint:
      title = mintState.sale.whitelist ? 'Whitelist closed' : mintState.sale.saleName;
      break;
    case MintPageState.WhitelistNoSpots:
      title = 'Whitelist closed';
      break;
    default:
      title = mintState.sale.saleName;
  }

  let content: JSX.Element;
  if (mintState.pageState === MintPageState.PreWhitelist) {
    content = <PreWhitelistSection />;
  } else if (mintState.pageState === MintPageState.Whitelist) {
    if (!environment.showEmailSignUpForWhitelist || appState.features.showTokenproof) {
      content = <WhitelistSignUpSection setShowSpinner={setShowSpinner} />;
    } else {
      content = <EmailSignUpSection title="Sign up to get notified when the Whitelist is live!" setShowSpinner={setShowSpinner} />;
    }
  } else if (mintState.pageState === MintPageState.WhitelistChecking) {
    content = <WhitelistCheckingSection />;
  } else if (mintState.pageState === MintPageState.WhitelistChecked) {
    content = <WhitelistCheckedSection viewPath={viewPath} />;
  } else if (mintState.pageState === MintPageState.PreMint) {
    content = <PremintSignUpSection setShowSpinner={setShowSpinner} />;
  } else if (mintState.pageState === MintPageState.WhitelistNoSpots) {
    content = <PremintSignUpSection setShowSpinner={setShowSpinner} />;
  } else {
    log(`Unexpected pageState in premint subpage: ${mintState.pageState}`);
    content = <ErrorSection>Unknown error occurred (premint).</ErrorSection>;
  }

  const analyticsPageName = getAnalyticsPageName(mintState.pageState);

  return (
    <Page title="Mint" className="premint-subpage container mb-5" showFooterDiscordOnly analyticsPageName={analyticsPageName}>
      <MintLayout title={title} subtitle="In collaboration with Tokenproof" showDates className="premint-subpage-layout">
        <MintVideoLayout videoContent={videoContent} showSpinner={showSpinner}>
          {content}
        </MintVideoLayout>
        <CharPassDates md />
        <PremintFaqs />
      </MintLayout>
    </Page>
  );
}

export default PremintSubPage;
