import { useMemo } from 'react';
import './CharPassDates.scss';
import { useClassName } from '@hooks/useClassName';
import { MintPageState, useMintState } from '@context/mint/MintContext';

const showWhitelistPageStates = [MintPageState.PreWhitelist, MintPageState.Whitelist];

export function CharPassDates({ md, alwaysShow }: { md?: boolean; alwaysShow?: boolean }) {
  const mintState = useMintState();
  const classNames = useClassName('char-pass-dates-con', md && 'char-pass-dates-con-md', alwaysShow && 'char-pass-dates-con-any');

  const whitelistStartStr = useMemo(() => {
    if (!showWhitelistPageStates.includes(mintState.pageState) || !mintState.sale?.whitelist?.signUp?.startDate) {
      return null;
    }
    const date = new Date(mintState.sale.whitelist.signUp.startDate);
    return date.toLocaleDateString();
  }, [mintState.pageState, mintState.sale?.whitelist?.signUp]);

  const mintStartStr = useMemo(() => {
    if (!mintState.sale?.publicMint?.startDate) {
      return null;
    }
    const date = new Date(mintState.sale.publicMint.startDate);
    return date.toLocaleDateString();
  }, [mintState.sale?.publicMint]);

  return (
    <div className={classNames}>
      <div className="char-pass-dates">
        {whitelistStartStr && (
          <div className="whitelist-date entry">
            <div className="entry-name GradientTitle">Whitelist</div>
            <div className="entry-spacer svs-shimmer-bg"></div>
            <div className="entry-date GradientTitle">{whitelistStartStr}</div>
          </div>
        )}
        {mintStartStr && (
          <div className="mint-date entry">
            <div className="entry-name GradientTitle">Mint</div>
            <div className="entry-spacer svs-shimmer-bg"></div>
            <div className="entry-date GradientTitle">{mintStartStr}</div>
          </div>
        )}
      </div>
    </div>
  );
}
