import { CharPassDates } from '../sections/CharPassDates';
import './MintLayout.scss';
import { useClassName } from '@hooks/useClassName';
import React from 'react';
import { TextNewLiner } from '@components/text-new-liner/TextNewLiner';

export type MintLayoutProps = {
  title: string,
  subtitle: string,
  showDates?: boolean,
  children?: React.ReactNode,
  className?: string,
};

export function MintLayout({
  title,
  subtitle,
  showDates,
  className,
  children,
}: MintLayoutProps) {
  const classNames = useClassName('mint-layout', className);
  return <div className={classNames}>
    <header className="mint-layout-header">
      <div>
        <h1 className="mint-layout-title mint-gradient-title">
          <TextNewLiner text={title} />
        </h1>
        <p className="mint-layout-subtitle">{subtitle}</p>
      </div>
      {showDates && <CharPassDates />}
    </header>
    <div className="mint-layout-body">
      {children}
    </div>
  </div>;
}

export default MintLayout;
