import React, { forwardRef } from 'react'
import type { ComponentProps } from 'react'

import './Burger.styles.scss'


type BurgerProps = {
  opened: boolean
} & ComponentProps<'button'>

export const Burger = forwardRef<HTMLButtonElement, BurgerProps>(({
  opened,
  className,
  ...others
}, ref) => (
  <button ref={ref} className={`BurgerContainer ${className}`} {...others}>
    <div className={`InnerBurger ${opened ? 'opened' : ''}`}></div>
  </button>
))
