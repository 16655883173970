import { SvsNavbarEvent } from '@storyverseco/svs-navbar';

import React, { useEffect, useMemo, useState } from 'react';
import { useClassName } from '@hooks/useClassName';
import './HostedApp.scss';
import { Web3Unavailable } from '../web3-unavailable/Web3Unavailable';
import { Spinner } from '../spinner/Spinner';
import { mainSuite } from '@services/ServiceFactory';
import { AnalyticsEventName } from '@services/analytics/AnalyticsEventName';
import { debug } from '@common/LogWrapper';

const log = debug('app:hostedapp');

const iframeAllow = 'clipboard-read; clipboard-write';

export type HostedAppProps = {
  src: string;
  noBridge?: boolean;
  requiresWeb3?: boolean;
} & React.ComponentPropsWithoutRef<'iframe'>;

export function HostedApp(props: HostedAppProps): JSX.Element {
  const svsBridge = mainSuite.navbarService;
  const { src, noBridge, requiresWeb3, ...iframeProps } = props;
  const [iframe, setIframe] = useState<HTMLIFrameElement>(null);
  const [cacheBuster, setCacheBuster] = useState(Date.now());
  const classNames = useClassName('hosted-app', props.className);

  const [web3Capable, setWeb3Capable] = useState<boolean | undefined>(requiresWeb3 ? undefined : true);

  function updateCacheBuster() {
    setCacheBuster(Date.now());
  }

  useEffect(() => {
    if (noBridge) {
      return;
    }
    if (!iframe) {
      return;
    }

    log('Connecting...');
    svsBridge.connect(iframe).then(() => {
      log('Connected');
    });

    svsBridge.on(SvsNavbarEvent.RefreshIframeRequested, updateCacheBuster);

    const iframeLoaded = () => {
      mainSuite.analyticsService.userTrack({});
      mainSuite.analyticsService.track(AnalyticsEventName.EntryFinal);
    };

    iframe.addEventListener('load', iframeLoaded, false);

    return () => {
      svsBridge.disconnect();
      iframe.removeEventListener('load', iframeLoaded, false);
      svsBridge.off(SvsNavbarEvent.RefreshIframeRequested, updateCacheBuster);
    };
  }, [src, noBridge, iframe, cacheBuster]);

  useEffect(() => {
    if (requiresWeb3) {
      svsBridge.isWeb3Capable().then((capable) => {
        setWeb3Capable(capable);
      });
    }
  }, [requiresWeb3]);

  const actualSrc = useMemo(() => {
    const urlObj = new URL(src);
    urlObj.searchParams.append('v', cacheBuster.toString());
    return urlObj.toString();
  }, [src, cacheBuster]);

  if (web3Capable === undefined) {
    return <Spinner />;
  } else if (web3Capable) {
    return <iframe src={actualSrc} {...iframeProps} className={classNames} allow={iframeAllow} ref={setIframe} />;
  } else {
    return <Web3Unavailable />;
  }
}
