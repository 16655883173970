import React, { useEffect, useState } from 'react'
import { debug } from '@common/LogWrapper'

const log = debug('app:videoplayer')

function isRefCallback<T>(obj: React.Ref<T>): obj is React.RefCallback<T> {
  return typeof obj === 'function';
}

function isRefObject<T>(obj: React.Ref<T>): obj is React.MutableRefObject<T> {
  return Object.prototype.hasOwnProperty.call(obj, 'current');
}

function BaseVideoPlayer(
  { play, autoPlay, ...props }: { play?: boolean } & React.ComponentPropsWithoutRef<'video'>,
  ref: React.Ref<HTMLVideoElement>,
) {

  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(null)
  const [playing, setPlaying] = useState(false)
  const [autoPlayed, setAutoPlayed] = useState(false)

  function onRef(element: HTMLVideoElement) {
    setVideoElement(element);
    if (ref) {
      if (isRefCallback(ref)) {
        ref(element);
      } else if (isRefObject(ref)) {
        ref.current = element;
      }
    }
  }

  useEffect(() => {
    if (!videoElement) {
      return
    }

    // if manually playing, disable "autoplay" attempts
    const manualPlay = typeof play === 'boolean'
    if (manualPlay) {
      setAutoPlayed(true)
    }

    if (play || autoPlay) {
      // if already playing, we're already done
      if (playing) {
        return
      }

      if (!manualPlay && autoPlay && autoPlayed) {
        return
      }

      let unloaded = false

      function playVideo() {
        videoElement.play().then(() => {
          // prevent side effects (attempting to set state outside of lifecycle)
          if (!unloaded) {
            log('playing', props.src)
            setPlaying(true)
            if (autoPlay) {
              log('autoplayed', props.src)
              setAutoPlayed(true)
            }
          }
        }).catch((e) => {
          log('Playing failed:', e)
        })
      }

      // try to play right away
      playVideo()
      // keep trying to play
      const intervalId = setInterval(playVideo, 1000)

      return () => {
        clearInterval(intervalId)
        unloaded = true
      }
    } else {
      videoElement.pause()
      setPlaying(false)
    }
  }, [videoElement, play, playing, autoPlayed])

  return <video
    autoPlay
    ref={onRef}
    {...props}
  ></video>
}

export const VideoPlayer = React.forwardRef(BaseVideoPlayer);
