import { waitForMs } from '../../common/WaitUtils';
import { environment } from '../../environments/environment';
import { MockStorageService } from '../mock/MockStorageService';
import { MetadataService } from './MetadataService';
import { migrateMetadata } from '../mock/MockMigrate';
import samplePng from '../../assets/OpenSea_NFT_Listing_image.png';

const metadataServiceKey = 'metadataService';

export class MockMetadataService implements MetadataService {
  private metadataMap: Play.NFTMetadataMap;
  constructor(
    private mockService: MockStorageService
  ) {
    try {
      this.metadataMap = mockService.getJson(metadataServiceKey) ?? {};
    } catch (e) {
      this.metadataMap = {};
      mockService.putJson(metadataServiceKey, this.metadataMap);
    }
    this.migrate();
  }

  private migrate() {
    // Object.values(this.metadataMap).forEach(migrateMetadata);
    this.metadataMap = {};
    this.mockService.putJson(metadataServiceKey, this.metadataMap);
  }

  async putMetadatas(metadatas: Play.NFTMetadata[]): Promise<void> {
    metadatas.forEach(metadata => {
      this.metadataMap[metadata.id] = metadata;
    });
    this.mockService.putJson(metadataServiceKey, this.metadataMap);
  }

  async loadNFTMetadata(nftIds: string[]): Promise<Play.NFTMetadataMap> {
    const map = {};

    for (const id of nftIds) {
      map[id] = await this.loadSingleMetadata(id);
    }
    return map;
  }
  async loadSingleMetadata(nftId: string): Promise<Play.NFTMetadata> {
    await waitForMs(environment.mockLoadingMs);

    return Math.random() > 0.5 ? null : {
      id: nftId,
      name: 'meow',
      image: samplePng
    };
  }
}
