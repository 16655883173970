import React from 'react';
import { Action, generateStoreContext } from '../GenerateContext';
import type { PlotsSaleData, PlotsState } from '../../common/PlotsState';
import { PlotsActionType } from './PlotsActionType';
import { LoadState } from '../../common/LoadState';
import { ethers } from 'ethers';
import { noChainId } from '../../common/ProviderUtils';

function transform(action: Action<PlotsActionType, PlotsActionProps>): PlotsState {
  const plotsSaleData = action.plotsSaleData;
  let priceBN;
  if (!plotsSaleData.active) {
    priceBN = ethers.constants.MaxInt256;
  } else if (typeof plotsSaleData.price === 'bigint' || typeof plotsSaleData.price === 'number') {
    priceBN = ethers.BigNumber.from(plotsSaleData.price);
  } else {
    priceBN = ethers.utils.parseUnits((plotsSaleData.price as any).toString(), 'wei');
  }
  return {
    chainId: action.chainId ?? noChainId,
    loadState: action.loadState ?? LoadState.Loaded,
    loadError: action.loadError ?? null,
    active: plotsSaleData.active,
    endtime: plotsSaleData.endtime,
    id: plotsSaleData.id,
    maxPLOTs: plotsSaleData.maxPLOTs,
    maxQuantity: plotsSaleData.maxQuantity,
    mintedPLOTs: plotsSaleData.mintedPLOTs,
    presale: plotsSaleData.presale,
    price: plotsSaleData.price,
    priceEth: ethers.utils.formatEther(priceBN),
    starttime: plotsSaleData.starttime,
    volume: plotsSaleData.volume,
    remainingSupply: plotsSaleData.maxPLOTs - plotsSaleData.mintedPLOTs,
  };
}

const initialState: PlotsState = {
  chainId: noChainId,
  loadState: LoadState.Idle,
  loadError: null,
  active: false,
  endtime: 0,
  id: 0,
  maxPLOTs: 0,
  maxQuantity: 0,
  mintedPLOTs: 0,
  presale: 0,
  price: 0,
  priceEth: '0.0',
  starttime: 0,
  volume: 0,
  remainingSupply: 0,
};

export type PlotsActionProps = {
  loadState?: LoadState,
  plotsSaleData?: PlotsSaleData,
  loadError?: string,
  chainId?: number,
  maxPLOTs?: number,
  mintedPLOTs?: number,
};

export type PlotsAction = Action<PlotsActionType, PlotsActionProps>;
export type PlotsDispatch = React.Dispatch<PlotsAction>;

function reducer(
  state: PlotsState,
  action: PlotsAction
): PlotsState {
  switch (action.type) {
  case PlotsActionType.UpdateLoadState:
    if (!action.loadState) {
      throw new Error('plots-context: Missing loadState property');
    }
    return {
      ...state,
      loadState: action.loadState,
      loadError: action.loadError ?? null,
    };
  case PlotsActionType.UpdateState:
    if (!action.plotsSaleData) {
      throw new Error('plots-context: Missing plotsSaleData property');
    }
    return {
      ...state,
      ...transform(action),
    };
  case PlotsActionType.UpdateRemainingSupply:
    if (typeof action.maxPLOTs !== 'number') {
      throw new Error('plots-context: Missing/invalid maxPLOTs property');
    }
    if (typeof action.mintedPLOTs !== 'number') {
      throw new Error('plots-context: Missing/invalid mintedPLOTs property');
    }
    return {
      ...state,
      remainingSupply: action.maxPLOTs - action.mintedPLOTs
    }
  default:
    throw new Error(`plots-context: Unknown action type "${action.type}"`);
  }
}

const {
  StateContext,
  DispatchContext,
  ContextProvider,
  ContextConsumer,
  withContext,
  useContextState,
  useContextDispatch
} = generateStoreContext<PlotsState, PlotsActionType, PlotsActionProps>(
  reducer, initialState, 'plotsState', 'plotsDispatch'
);

export {
  StateContext as PlotsStateContext,
  DispatchContext as PlotsDispatchContext,
  ContextProvider as PlotsProvider,
  ContextConsumer as PlotsConsumer,
  withContext as withPlots,
  useContextState as usePlotsState,
  useContextDispatch as usePlotsDispatch,
  PlotsState,
  PlotsActionType,
};
