import { ConditionWaiter } from '@common/ConditionWaiter';
import { LoginState, loginStateFinished } from '@common/LoginState';
import { createLoginScreen, MockLoginActionType } from '../mock/MockLoginScreen';
import { MockModal } from '../mock/MockModal';
import { MockLoginUser } from '../mock/MockUtils';
import { TokenproofService, TpAccount, TpReason, TpStatus, TpToken } from './TokenproofService';
import { AnalyticsService } from '../analytics/AnalyticsService';
import { SaleService } from '../sale/SaleService';
import { AnalyticsEventName, AnalyticsUsageName } from '../analytics/AnalyticsEventName';
import { environment } from '../../environments/environment';
import { MintPageState } from '../../context/mint/MintContext';

function createNonce(): string {
  return Math.floor(Math.random() * 1_000_000).toString(36);
}

let nftIdPool = 0;
const mockContractAddress = '0xMockContractAddress';

function generateMockTokenMap(): Record<string, TpToken[]> {
  const tokens = Array.from({ length: Math.floor(Math.random() * 4 + 1) }, () => ({
    id: (nftIdPool++).toString(),
    qty: 1,
  }));
  return {
    [mockContractAddress.toLowerCase()]: tokens,
  };
}

export class MockTokenproofService implements TokenproofService {
  constructor(private analyticsService: AnalyticsService, private saleService: SaleService) {}

  private account: TpAccount | null = null;
  private accountMap: Record<string, TpAccount> = {};
  private status: TpStatus = TpStatus.Idle;
  private reason: TpReason = TpReason.Idle;
  private nonce: string | null = null;
  private loginStateWaiter = new ConditionWaiter(LoginState.Idle, loginStateFinished);
  private loginModal: MockModal | null = null;

  private dbAccountMap: Record<string, TpAccount> = {};

  init(): Promise<void> {
    // do nothing
    return Promise.resolve();
  }
  isLoggedIn(): boolean {
    return this.loginStateWaiter.get() === LoginState.LoggedIn;
  }
  getAccount(address?: string): TpAccount | null {
    if (!address) {
      return this.account ?? null;
    }
    return this.accountMap[address] ?? null;
  }
  getAccountMap(): Record<string, TpAccount> {
    return this.accountMap;
  }
  getNonce(): string | null {
    return this.nonce;
  }
  getStatus(): string {
    return this.status;
  }
  getReason(): string {
    return this.reason;
  }
  async login(opts?: { appId?: string; env?: string; mintPageState?: MintPageState; tokenType?: string; policyId?: string }): Promise<TpAccount> {
    const params = {
      usage: this.getAnalyticsUsage(opts?.mintPageState ?? MintPageState.Idle),
      tokenType: opts?.tokenType,
      policyId: opts?.policyId,
    };
    this.analyticsService.track(AnalyticsEventName.TokenproofStart, params);
    this.startLogin();
    await this.loginStateWaiter.wait();
    return this.account;
  }
  logout(): Promise<void> {
    this.account = null;
    this.accountMap = {};
    this.status = TpStatus.Idle;
    this.reason = TpReason.Idle;
    this.nonce = null;
    this.loginStateWaiter.set(LoginState.LoggedOut);
    return Promise.resolve();
  }

  getAnalyticsUsage(mintPageState: MintPageState): AnalyticsUsageName {
    let analyticsUsage: AnalyticsUsageName;

    switch (mintPageState) {
      case MintPageState.PreWhitelist:
      case MintPageState.Whitelist:
      case MintPageState.WhitelistChecking:
      case MintPageState.WhitelistChecked:
      case MintPageState.WhitelistNoSpots:
        analyticsUsage = 'whitelist';
        break;
      case MintPageState.PreMint:
      case MintPageState.Mint:
      case MintPageState.MintEntry:
      case MintPageState.MintShare:
      case MintPageState.Minting:
      case MintPageState.Minted:
      case MintPageState.MintNoSupply:
      case MintPageState.PostMint:
        analyticsUsage = 'mint';
        break;
      default:
        analyticsUsage = 'unknown';
        break;
    }

    return analyticsUsage;
  }

  private startLogin() {
    if (this.loginModal) {
      return;
    }

    this.loginStateWaiter.set(LoginState.LoggingIn);

    this.loginModal = createLoginScreen(this.loginCallback.bind(this), this.loginCloseCallback.bind(this), 'Login - Faux tokenproof');
  }

  private endLogin() {
    if (!this.loginModal) {
      return;
    }

    this.loginModal.destroy();
    this.loginModal = null;
  }

  private loginCallback(loginActionType: MockLoginActionType, data?: any) {
    switch (loginActionType) {
      case MockLoginActionType.Select:
        const loginUser: MockLoginUser = data;
        if (!this.dbAccountMap[loginUser.address]) {
          this.dbAccountMap[loginUser.address] = {
            address: loginUser.address,
            tokens: generateMockTokenMap(),
          };
        }
        this.accountMap = {
          [loginUser.address]: this.dbAccountMap[loginUser.address],
        };
        this.account = this.accountMap[loginUser.address];
        this.status = TpStatus.Authenticated;
        this.reason = TpReason.AuthenticatedAccount;
        this.nonce = createNonce();
        this.loginStateWaiter.set(LoginState.LoggedIn);
        break;
      case MockLoginActionType.Close:
        this.status = TpStatus.Closed;
        this.reason = TpReason.ModalClosed;
        this.nonce = null;
        this.loginStateWaiter.set(LoginState.LoggedOut);
        break;
      default:
        this.status = TpStatus.Unknown;
        this.reason = TpReason.Unknown;
        this.nonce = null;
        this.loginStateWaiter.set(LoginState.LoggedOut);
        break;
    }
    this.endLogin();
  }

  private loginCloseCallback() {
    this.status = TpStatus.Closed;
    this.reason = TpReason.ModalClosed;
    this.nonce = null;
    this.loginStateWaiter.set(LoginState.LoggedOut);
    this.endLogin();
  }
}
