import { waitForMs } from '@common/WaitUtils';
import { EmailSubService, IsSubscribedOpts, IsSubscribedResult, SubscribeOpts, SubscribeResult } from './EmailSubService';
import { debug } from '@common/LogWrapper';
import { MockStorageService } from '../mock/MockStorageService';

const log = debug('app:services:MockEmailSubService');

export class MockEmailSubService implements EmailSubService {
  private subscribedMap: Record<string, Record<string, SubscribeOpts>> = {};

  async subscribe(opts: SubscribeOpts): Promise<SubscribeResult> {
    const [emailResult, socialsResult] = await Promise.all([
      opts.socialHandles.email ? this.subscribeEmail(opts) : Promise.resolve(),
      opts.campaign && opts.socialHandles.hotWallet ? this.subscribeSocials(opts) : Promise.resolve(),
    ]);

    return {
      ...emailResult,
      ...socialsResult,
    };
  }
  async subscribeEmail({ socialHandles }: SubscribeOpts): Promise<SubscribeResult> {
    if (!socialHandles.email) {
      throw new Error('Missing email in options');
    }
    await waitForMs(500);
    log(`Email address "${socialHandles.email}" subscribed`);
    return { email: true };
  }
  async subscribeSocials(opts: SubscribeOpts): Promise<SubscribeResult> {
    if (!opts.campaign || !opts.socialHandles.hotWallet) {
      throw new Error('Missing campaign and/or hotWallet in options');
    }
    await waitForMs(500);
    const campaign = opts.campaign.toLowerCase();
    const hotWallet = opts.socialHandles.hotWallet.toLowerCase();
    if (!this.subscribedMap[campaign]) {
      this.subscribedMap[campaign] = {};
    }
    this.subscribedMap[campaign][hotWallet] = opts;
    log(`Socials subscribed to ${campaign}/${hotWallet}:`, opts);
    return { socials: true };
  }
  async isSubscribed(opts: IsSubscribedOpts): Promise<IsSubscribedResult> {
    if (!opts.campaign || !opts.walletAddress) {
      throw new Error('Missing campaign and/or wallet address in options');
    }
    await waitForMs(500);
    const campaign = opts.campaign.toLowerCase();
    const walletAddress = opts.walletAddress.toLowerCase();
    return {
      exists: Boolean(this.subscribedMap[campaign]?.[walletAddress]),
    };
  }
}
