import { waitForMs } from '@common/WaitUtils';
import { environment } from '@environment';
import { MockStorageService } from '../mock/MockStorageService';
import { ChainalysisService } from './ChainalysisService';
import { debug } from '@common/LogWrapper';

type MockCAData = {
  accounts: Record<string, boolean>,
};

const log = debug('app:services:MockChainalysisService');

const storageKey = 'chainalysisData';

function createDefaultData(): MockCAData {
  return { accounts: {} };
}

export class MockChainalysisService implements ChainalysisService {
  private data: MockCAData = undefined;

  constructor(
    private mockStorageService: MockStorageService
  ) {
    try {
      this.data = {
        ...createDefaultData(),
        ...(mockStorageService.getJson(storageKey) ?? {}),
      }
    } catch (e) {
      this.data = createDefaultData();
      mockStorageService.putJson(storageKey, this.data);
    }
  }
  async isWalletAddressSafe(walletAddress: string): Promise<boolean> {
    await waitForMs(environment.mockLoadingMs);
    return this.data[walletAddress] ?? true;
  }

  async setWalletAddressSafe(walletAddress: string, safe: boolean): Promise<void> {
    this.data[walletAddress] = safe;
    this.saveData();
  }

  private saveData(): void {
    this.mockStorageService.putJson(storageKey, this.data);
  }
}
