import React, { useEffect, useRef } from 'react'

import './VideoSection.styles.scss'
import { Section } from '@components/Section'
import { debug } from '@common/LogWrapper'
import VimeoReactPlayer from '@components/vimeo-react-player/VimeoReactPlayer'

const log = debug('app:pages:sections:VideoSection')

export const VideoSection = () => (
  <Section className='VideoSection' id='about'>
    <VimeoReactPlayer
      url="https://player.vimeo.com/video/774332983?color=2B30F5&muted=1&loop=1"
      loop
      autoPlayType="inview"
    />
  </Section>
)
