export const enum LoginState {
  Idle, LoggingIn, LoggedIn, LoggingOut, LoggedOut
}

// helper condition for ConditionWaiter
export function loginStateFinished(loginState: LoginState): boolean {
  switch (loginState) {
  case LoginState.LoggedIn:
  case LoginState.LoggedOut:
    return true;
  default:
    return false;
  }
}

export function loginStateInProgress(loginState: LoginState): boolean {
  switch (loginState) {
  case LoginState.LoggingIn:
  case LoginState.LoggingOut:
    return true;
  default:
    return false;
  }
}
