import React, { Dispatch } from 'react';
import { ConditionWaiter } from '@common/ConditionWaiter';
import { UserAccount } from '@common/UserAccount';
import { Action } from '@context/GenerateContext';
import { UserService, WithdrawStatus } from './UserService';
import { UserActionType } from './UserActionType';
import { UserPermission } from '@common/UserPermission';
import { LoginState, loginStateFinished, loginStateInProgress } from '@common/LoginState';
import { AnalyticsEventName } from '@services/analytics/AnalyticsEventName';
import { mainSuite } from '@services/ServiceFactory';
import { ChainalysisService } from '../chainalysis/ChainalysisService';
import { UserActionProps } from './UserContext';
import { environment } from '@environment';
import { ChangeSvsProviderOpts, SvsNavbarEvent, Wallet } from '@storyverseco/svs-navbar';
import { debug } from '@common/LogWrapper';
import { ContractAddress } from '@storyverseco/svs-types';

const log = debug('app:service:MetaMaskUserService');

export class MetaMaskUserService implements UserService {
  readonly logInPopUp = true;

  private initWaiter = new ConditionWaiter(false);
  private loginStateWaiter = new ConditionWaiter(LoginState.Idle, loginStateFinished);
  private userDispatchWaiter = new ConditionWaiter<React.Dispatch<Action<UserActionType, UserActionProps>>>(null);
  private userStateWaiter = new ConditionWaiter<UserAccount>(null);

  private _storyNFTs: Record<string, { contractName: string; tokens: string[] }>;

  constructor(private chainalysisService: ChainalysisService) {
    this.initLoggedInUser();
  }

  hasInitialized(): boolean {
    return this.initialized;
  }
  waitForInit(): Promise<void> {
    return this.initWaiter.wait() as unknown as Promise<void>;
  }

  setDispatch(dispatch: Dispatch<Action<UserActionType, UserActionProps>>): void {
    this.userDispatchWaiter.set(dispatch);
    this.checkForInit();
  }
  setUserState(state: UserAccount): void {
    this.userStateWaiter.set(state);
    if (state && 'loginState' in state) {
      this.loginStateWaiter.set(state.loginState);
    }
    this.checkForInit();
  }
  signUp(): void {
    this.startLogin();
  }
  logIn(): void {
    this.startLogin();
  }
  logOut(): void {
    this.startLogout(false);
  }
  async changeSvsProvider(opts?: ChangeSvsProviderOpts): Promise<string> {
    const providerType = await mainSuite.navbarService.api.changeSvsProvider(opts);
    await this.checkForLoggedInUser();
    return providerType;
  }
  getSvsProvider(): Promise<string> {
    return mainSuite.navbarService.api.getSvsProvider();
  }

  private get initialized(): boolean {
    return this.initWaiter.get();
  }

  private get userDispatch(): React.Dispatch<Action<UserActionType, UserActionProps>> {
    return this.userDispatchWaiter.get();
  }

  private get userState(): UserAccount {
    return this.userStateWaiter.get();
  }

  private get loginState(): LoginState {
    return this.loginStateWaiter.get();
  }

  private async waitForStateAndDispatch(): Promise<void> {
    await Promise.all([this.userDispatchWaiter.wait(), this.userStateWaiter.wait()]);
  }

  private checkForInit(): void {
    if (this.initialized) {
      return;
    }
    if (!this.userState) {
      return;
    }
    if (!this.userDispatch) {
      return;
    }
    if (!loginStateFinished(this.loginState)) {
      return;
    }

    this.initWaiter.set(true);
  }

  private setLoginState(loginState: LoginState) {
    this.userDispatch({ type: UserActionType.UpdateLoginState, loginState });
  }

  private async populateUser(wallet?: Wallet): Promise<void> {
    if (!wallet) {
      wallet = await mainSuite.navbarService.api.getWallet();
    }
    // If it's the first time we get the wallet, cache the tokens we have
    if (!this._storyNFTs) {
      this._storyNFTs = wallet.storyNFTs;
    }

    if (!wallet?.flags.loggedIn) {
      // not sure what happened here, but we don't have wallet info
      log('Missing wallet info');
      await this.startLogout(false);
      return;
    }
    const caSafe = await this.chainalysisService.isWalletAddressSafe(wallet.address);
    const permissions = [UserPermission.User];
    // mostly for mocking
    if (wallet.address === environment.serviceAddress) {
      permissions.push(UserPermission.Admin);
      permissions.push(UserPermission.Service);
    }
    this.userDispatch({
      type: UserActionType.LogIn,
      caSafe,
      address: wallet.address,
      name: wallet.address,
      wallet: wallet,
      permissions,
    });

    mainSuite.analyticsService.track(AnalyticsEventName.WalletConnect);
  }

  private async checkForLoggedInUser(): Promise<void> {
    const loggedIn = await mainSuite.navbarService.api.isLoggedIn();
    if (loggedIn) {
      log('checkForLoggedInUser: existing user logged in');
      await this.waitForStateAndDispatch();
      this.setLoginState(LoginState.LoggingIn);
      await this.populateUser();
    } else {
      log('checkForLoggedInUser: no user logged in');
      await this.startLogout(true);
    }
  }

  private async initLoggedInUser(): Promise<void> {
    await this.checkForLoggedInUser();
    mainSuite.navbarService.on(SvsNavbarEvent.LoginStateChanged, (loginState) => {
      log('Login state changed:', loginState);
    });
    mainSuite.navbarService.on(SvsNavbarEvent.WalletChanged, async (wallet) => {
      await this.waitForStateAndDispatch();
      if (wallet.flags.loggedIn) {
        log('logging in with', wallet.address);
        this.setLoginState(LoginState.LoggingIn);
        this.populateUser(wallet);
      } else {
        log('logging out');
        this.startLogout(false);
      }
    });
    mainSuite.navbarService.on(SvsNavbarEvent.ProviderTypeChanged, async (providerType) => {
      const wallet = await mainSuite.navbarService.api.getWallet();
      await this.waitForStateAndDispatch();
      if (wallet.flags.loggedIn) {
        log('provider changed, logging in with', wallet.address);
        this.setLoginState(LoginState.LoggingIn);
        this.populateUser(wallet);
      } else {
        log('provider changed, logging out');
        this.startLogout(false);
      }
    });
  }

  private async startLogin(): Promise<void> {
    mainSuite.analyticsService.track(AnalyticsEventName.WalletConnectStart);
    this.setLoginState(LoginState.LoggingIn);
    await this.waitForInit();
    try {
      const loggedIn = await mainSuite.navbarService.api.logIn(undefined, { messageAndSig: false });
      if (!loggedIn) {
        // most likely cancelled log in
        await this.startLogout(false);
        return;
      }
      await this.populateUser();
    } catch (e) {
      log('Error occurred while logging in:', e);
      mainSuite.analyticsService.track(AnalyticsEventName.WalletConnectError, {
        errorCode: e.code,
        errorMessage: e.message,
        errorStack: e.stack,
      });
      this.setLoginState(LoginState.LoggedOut);
    }
  }

  private async startLogout(skipWait: boolean): Promise<void> {
    await this.waitForStateAndDispatch();
    if (this.userState.loginState === LoginState.LoggedOut || this.userState.loginState === LoginState.LoggingOut) {
      return;
    }
    this.setLoginState(LoginState.LoggingOut);
    await mainSuite.navbarService.api.logOut();
    this.userDispatch({ type: UserActionType.LogOut });
  }

  getCurrentStoryNFT(contractAddress: ContractAddress) {
    if (!this._storyNFTs) {
      throw new Error(`Error (getCurrentStoryNFT): 'storyNFTs' not set.`);
    }

    return this._storyNFTs[contractAddress];
  }

  async fetchMyStoryTokenForContract({ contractAddress, ignoreEqual }: { contractAddress: ContractAddress; ignoreEqual?: boolean }) {
    const wallet = await mainSuite.navbarService.api.getWallet();
    const lowerContractAddress = contractAddress.toLowerCase() as ContractAddress;

    const updatedStoryNFT = wallet.storyNFTs[lowerContractAddress];
    // cached value
    const currentStoryNFT = this.getCurrentStoryNFT(lowerContractAddress);

    // This is used when waiting for new data
    if (ignoreEqual && JSON.stringify(currentStoryNFT) === JSON.stringify(updatedStoryNFT)) {
      return undefined;
    }

    const cachedTokens = currentStoryNFT?.tokens || [];
    const updatedTokens = updatedStoryNFT?.tokens || [];

    const newTokens = [];
    updatedTokens.forEach((token) => {
      // if it doesnt exist in the cache, it's new
      if (!cachedTokens.includes(token)) {
        newTokens.push(token);
      }
    });

    // Update storyNTFs cache
    this._storyNFTs = wallet.storyNFTs;

    return newTokens[0];
  }
}
