
export type Features = {
  showContactsSection: boolean,
  showSignUpModal: boolean,
  showFooterSignUp: boolean,
  showMapLink: boolean,
  showWhitepaperLink: boolean,
  showTokenproof: boolean,
  [key: string]: any,
}

export const initialFeatures: Features = {
  showContactsSection: false,
  showSignUpModal: false,
  showFooterSignUp: false,
  showMapLink: false,
  showWhitepaperLink: false,
  showTokenproof: false,
}
