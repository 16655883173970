import { useState, FormEvent, useEffect } from 'react';
import { useClassName } from '@hooks/useClassName';
import { mainSuite } from '@services/ServiceFactory';
import { SvsInputText } from '@components/svs-input-text/SvsInputText';
import emailIcon from '@assets/svg/email-icon.svg';
import GradientTitle from '@components/GradientTitle';
import { MintCountdown } from '../sections/MintCountdown';
import { AnalyticsEventName } from '@services/analytics/AnalyticsEventName';
import { debug } from '@common/LogWrapper';
import './EmailSignUpSection.scss';

const log = debug('app:pages:sections:EmailSignUpSection');

export function EmailSignUpSection({
  title,
  setShowSpinner,
  hideCountdown = false,
}: {
  title: string;
  setShowSpinner: (show: boolean) => void;
  hideCountdown?: boolean;
}) {
  const emailSubService = mainSuite.emailSubService;
  const analyticsService = mainSuite.analyticsService;
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string>(null);
  const [inProgress, setInProgress] = useState(false);
  const [emailSubbed, setEmailSubbed] = useState(false);

  const btnClassNames = useClassName('mint-btn-cta email-btn', emailSubbed && 'success');

  async function waitlistSignUp(e: FormEvent) {
    e.preventDefault();

    if (!email) {
      setError('Please enter a valid email address.');
      return;
    }

    analyticsService.track(AnalyticsEventName.ButtonPress, { buttonName: 'waitlistSignUp' });

    setInProgress(true);
    emailSubService
      .subscribeEmail({ socialHandles: { email } })
      .then((success) => {
        if (success) {
          log('Successful subscribing.');
          setEmailSubbed(true);
        } else {
          log('Unsuccessful subscribing.');
          setError('Error occurred. Please double-check your email address.');
        }
      })
      .catch((e) => {
        log('Error occurred while subscribing email:', e);
        setError('Error occurred. Please try again.');
      })
      .finally(() => {
        setInProgress(false);
      });
  }

  useEffect(() => {
    setShowSpinner(inProgress);
  }, [inProgress]);

  // unsets when unmounting
  useEffect(
    () => () => {
      setShowSpinner(false);
    },
    [],
  );

  return (
    <div className="email-signup-section">
      {!hideCountdown && <MintCountdown />}
      <GradientTitle>{title}</GradientTitle>
      <form onSubmit={waitlistSignUp}>
        <div className="">
          {/* <SvsInputText type="text" iconUrl={walletIcon} placeholder="Your wallet address" /> */}
          <SvsInputText
            type="email"
            iconUrl={emailIcon}
            placeholder="your@email.com"
            value={email}
            disabled={inProgress || emailSubbed}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </div>
        <p className="email-notice desktop">By entering your email you consent to be contacted with marketing and promotional emails.</p>
        {error && <p className="email-notice text-danger">{error}</p>}
        <button type="submit" className={btnClassNames} disabled={!email || inProgress || emailSubbed}>
          {emailSubbed ? 'SUCCESS!' : 'SIGN UP'}
        </button>
        <p className="email-notice mobile">By entering your email you consent to be contacted with marketing and promotional emails.</p>
      </form>
    </div>
  );
}
