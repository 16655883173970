
import { useState } from 'react';
import { Page } from '../Page';
import './ContactPage.scss';

const contactNone = 'none';
const contactOwner = 'owner';
const contactCollector = 'collector';

type ContactType = typeof contactNone | typeof contactOwner | typeof contactCollector;

export function ContactPage() {
  const [contact, setContact] = useState<ContactType>(contactNone);

  const isNone = contact === contactNone;
  const isOwner = contact === contactOwner;
  const isCollector = contact === contactCollector;

  return <Page title="Contact" className="container intro-main-content contact-content" introBg>
    <h1>Contact us</h1>
    <h2>
      <div className='select-role'>
        <div className='role-text'>Are you a</div>
        <div className={ `btn-role${isOwner ? ' active' : '' }` } onClick={ () => setContact(contactOwner) }>Project Owner</div>
        <div className='role-text'>or a</div>
        <div className={ `btn-role${isCollector ? ' active' : '' }` } onClick={ () => setContact(contactCollector) }>Collector</div>
        <div className='role-text'>?</div>
      </div>
    </h2>
    <div className='contact-form-container'>
      { !isNone ?
        <iframe title='Contact Form' className="contact-form"
          src={ `https://form.typeform.com/to/${isOwner ? 'KCtQIFzI' : 'KADrdRKK' }` } />
        : undefined }
    </div>
  </Page>
}
