import { useState, useEffect } from 'react';
import { useMintState } from '@context/mint/MintContext';
import { CountdownInline } from '@components/countdown-inline/CountdownInline';
import './MintCountdown.scss';

export function MintCountdown() {
  const mintState = useMintState();
  const [targetTime, setTargetTime] = useState(-1);

  useEffect(() => {
    if (!mintState.sale) {
      return;
    }

    const saleDates = [mintState.sale.whitelist?.signUp, mintState.sale.whitelist?.mint, mintState.sale.publicMint].filter(Boolean);

    for (const saleDate of saleDates) {
      if (saleDate.startDate && saleDate.countdownTarget) {
        const startTime = new Date(saleDate.startDate).getTime();
        if (Date.now() < startTime) {
          setTargetTime(startTime);
          return;
        }
      }
    }
  }, [mintState.sale]);

  return <CountdownInline className="mint-countdown" targetTime={targetTime} />;
}
