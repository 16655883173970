import { Page } from '../Page';
import './MintCollectionPage.scss';
import MintCollection from './components/MintCollection';

import MintCollectionLayout from './layouts/MintCollectionLayout';
import MintCollectionInfo from '../mint-collections/components/MintCollectionInfo';
import { ItemProps } from '../mint-home/components/Item';

export type MintCollectionPageProps = {
  id: string;
};

export function MintCollectionPage({ id }: MintCollectionPageProps) {
  return (
    <Page className="container mb-5" title="MintCollection" introBg>
      <MintCollectionLayout className="coll-state-page-layout" title="Mint Collection" subtitle="Following best practices from FreeNFT">
        <div className="coll-layout-columns">
          <div className="coll-layout-column">
            {/* Collection Info Component version which includes the Mint Now form*/}
            <MintCollectionInfo id={id} />
          </div>
        </div>
      </MintCollectionLayout>
    </Page>
  );
}
