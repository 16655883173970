import { debug } from '@common/LogWrapper';

import mockData from '../../assets/data/mockdata.json';

const log = debug('app:services:mock:MockStorageService');

export type StoragePair = { [key: string]: string };

const allowMockDataKey = 'allowMockData';

export class MockStorageService {
  private prefix = 'swlmock-';
  private internalPrefix = 'mockstorageservice-';
  private storage = window.sessionStorage;

  constructor() {
    if (this.getAllowMockData()) {
      this.insertMockData();
    }
  }

  insertMockData(): void {
    if (!mockData) { return; }

    Object.entries(mockData).forEach(([key, value]) => {
      if (this.has(key)) { return; }

      const valueType = typeof value;
      if (valueType === 'string' || valueType === 'number') {
        this.put(key, value as string);
      } else if (valueType === 'object') {
        this.putJson(key, value as object);
      } else {
        log(`Unsupported value type from mock data key "${key}": "${valueType}"`);
      }
    });
  }

  has(key: string): boolean {
    return !!this.storage.getItem(this.prefix + key);
  }

  put(key: string, value: string): void {
    this.storage.setItem(this.prefix + key, value);
  }

  putJson(key: string, value: object): void {
    this.put(key, JSON.stringify(value));
  }

  get(key: string): string {
    return this.storage.getItem(this.prefix + key);
  }

  getJson(key: string): any {
    const valueStr = this.get(key);
    try {
      const value = JSON.parse(valueStr);
      return value;
    } catch (e) {
      throw new Error(`MockService: value for ${key} is not JSON`);
    }
  }

  remove(key: string): void {
    this.storage.removeItem(this.prefix + key);
  }

  clear(): void {
    // only remove relevant keys
    const keys: string[] = [];

    // doing it this way since removing keys mid-traverse changes length and indexes
    for (let i = 0; i < this.storage.length; i++) {
      const key = this.storage.key(i);
      if (key.startsWith(this.prefix)) {
        keys.push(key);
      }
    }

    keys.forEach(key => this.storage.removeItem(key));
  }

  setAllowMockData(allow: boolean): void {
    this.storage.setItem(this.internalPrefix + allowMockDataKey, allow.toString());
  }

  getAllowMockData(): boolean {
    const allowMockData = this.storage.getItem(this.internalPrefix + allowMockDataKey) ?? 'true';
    return allowMockData === 'true';
  }

  exportData(): StoragePair {
    const obj: StoragePair = {};
    for (let i = 0; i < this.storage.length; i++) {
      const key = this.storage.key(i);
      if (key.startsWith(this.prefix)) {
        const baseKey = key.substring(this.prefix.length);
        const valueStr = this.storage.getItem(key);
        try {
          obj[baseKey] = JSON.parse(valueStr);
        } catch (e) {
          obj[baseKey] = valueStr;
        }
      }
    }
    return obj;
  }
}
