import { useEffect } from 'react';

export type ExtLinkProps = {
  to: string
};

export function TopLink({ to }: ExtLinkProps) {
  useEffect(() => {
    window.location.href = to;
  }, [to]);
  return null;
}
