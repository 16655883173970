import { msToDays, daysToMs, msToHours, hoursToMs, msToMinutes, minutesToMs, msToSeconds } from './NumberUtils';

export type DeltaParts = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export function min2Digits(value: number): string {
  return value.toString().padStart(2, '0');
}

export function parseCountdownToDate(targetTime: number, fromTime: number): DeltaParts {
  let delta = Math.max(0, targetTime - fromTime);

  const days = Math.floor(msToDays(delta));
  delta -= daysToMs(days);
  const hours = Math.floor(msToHours(delta)) % 24;
  delta -= hoursToMs(hours);
  const minutes = Math.floor(msToMinutes(delta)) % 60;
  delta -= minutesToMs(minutes);
  const seconds = Math.floor(msToSeconds(delta)) % 60;

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}
