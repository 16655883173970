export type CancelableWaiter<T = void> = {
  promise: Promise<T>;
  cancel: () => void;
};

export const cancelledErrorName = 'PromiseCancelled';

export class PromiseCancelledError extends Error {
  constructor() {
    super('Promise manually cancelled');
    this.name = cancelledErrorName;
  }
}

export function waitForMs(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function waitForSeconds(seconds: number): Promise<void> {
  return waitForMs(seconds * 1000);
}

export function waitForMsCancelable(ms: number): CancelableWaiter {
  let timeout: NodeJS.Timeout;
  let promiseReject: (reason: any) => void;
  const promise = new Promise<void>((resolve, reject) => {
    promiseReject = reject;
    timeout = setTimeout(resolve, ms);
  }).then(() => {
    timeout = null;
    promiseReject = null;
  });
  return {
    promise,
    cancel() {
      if (!timeout) {
        return;
      }
      clearTimeout(timeout);
      timeout = null;
      promiseReject(new PromiseCancelledError());
      promiseReject = null;
    },
  };
}

export function waitForSecondsCancelable(seconds: number): CancelableWaiter {
  return waitForMsCancelable(seconds * 1000);
}

export function suppressCancelled<T>(promise: Promise<T>): Promise<T> {
  return promise.catch((e: any) => {
    if (e.name !== cancelledErrorName) {
      throw e;
    }
    return undefined;
  });
}

function defaultExpectedCondition<T>(value: T): boolean {
  return !!value;
}

export type WaitForExpectedValueOptions<T> = {
  condition?: (value: T) => boolean;
  retryOnError?: boolean;
  maxRetries?: number;
  retryDelay?: number;
  retryCallback?: (reason: any, retries: number, maxRetries: number) => void;
};

function createDefaultExpectedOptions<T>(): WaitForExpectedValueOptions<T> {
  return {
    condition: defaultExpectedCondition,
    retryOnError: true,
    maxRetries: 5,
    retryDelay: 1000,
  };
}

function rejectDelay<T>(delayMs: number, tries: number, maxRetries: number, retryCallback?: WaitForExpectedValueOptions<T>['retryCallback']) {
  return (reason: any) =>
    new Promise((resolve, reject) => {
      retryCallback?.(reason, tries, maxRetries);
      setTimeout(() => {
        reject(reason);
      }, delayMs);
    });
}

export function waitForExpectedValue<T>(fn: () => Promise<T>, options?: WaitForExpectedValueOptions<T>): CancelableWaiter<T> {
  const opts = {
    ...createDefaultExpectedOptions(),
    ...options,
  };
  let cancelled = false;
  let waitCancel: () => void = null;
  const cancel = () => {
    cancelled = true;
    waitCancel?.();
  };
  const promise = new Promise<T>(async (resolve, reject) => {
    let lastError: any;
    for (let i = 0; i < opts.maxRetries; i += 1) {
      if (cancelled) {
        reject(new PromiseCancelledError());
        return;
      }
      try {
        const value = await fn();
        // success, exit
        if (opts.condition(value)) {
          resolve(value);
          return;
        }

        // condition is false
        lastError = new Error('Condition failed');
        opts.retryCallback?.(lastError, i, opts.maxRetries);
      } catch (e) {
        lastError = e;
        // if not retrying on error, reject and exit
        if (!opts.retryOnError) {
          reject(e);
          return;
        }

        opts.retryCallback?.(e, i, opts.maxRetries);
      }

      const { promise: delayPromise, cancel: delayCancel } = waitForMsCancelable(opts.retryDelay);
      const suppressedDelayPromise = suppressCancelled(delayPromise);
      waitCancel = delayCancel;
      await suppressedDelayPromise;
      waitCancel = null;
    }

    if (!lastError) {
      lastError = new Error('Unknown error (No more retries)');
    }

    reject(lastError);
    return;
  });
  return {
    promise,
    cancel,
  };
}

// function createDebugAsyncFn<T>(maxRetries: number, delay: number, value?: T, retryValue?: T, throwErrorOnRetry = false): () => Promise<T> {
//   let i = 0;
//   return () => new Promise(async (resolve, reject) => {
//     await waitForMs(delay);
//     if (i < maxRetries) {
//       i += 1;
//       if (throwErrorOnRetry) {
//         reject(new Error('Failing (' + retryValue + ')'));
//       } else {
//         resolve(retryValue);
//         return;
//       }
//     }
//     resolve(value);
//   });
// }

// function debugRetryCallback(reason: any, tries: number, maxRetries: number): void {
//   log('retry callback', tries, maxRetries, reason);
// }

// async function debugTest() {
//   log('Test 1: success after 3 retries');
//   let fn1 = createDebugAsyncFn(3, 500, true, false, false);
//   const test1 = waitForExpectedValue(fn1,  { retryCallback: debugRetryCallback });
//   const test1Value = await test1.promise;
//   log(`Test 1 value: ${test1Value}`);

//   log('Test 2: max retries reached');
//   let fn2 = createDebugAsyncFn(6, 500, true, false, false);
//   const test2 = waitForExpectedValue(fn2,  { retryCallback: debugRetryCallback });
//   try {
//     const test2Value = await test2.promise;
//     log(`Test 2 unexpected value: ${test2Value}`);
//   } catch (e) {
//     log(`Test 2 expected error (${e.message})`);
//   }

//   log('Test 3: cancelled before retries run out');
//   let fn3 = createDebugAsyncFn(6, 500, true, false, false);
//   const test3 = waitForExpectedValue(fn3,  { retryCallback: debugRetryCallback });
//   await waitForMs(2000);
//   test3.cancel();
//   const test3Value = await suppressCancelled(test3.promise);
//   log(`Test 3 value: ${test3Value}`);

//   log('Test 4: throw errors on 3 retries');
//   let fn4 = createDebugAsyncFn(3, 500, true, false, true);
//   const test4 = waitForExpectedValue(fn4,  { retryCallback: debugRetryCallback });
//   const test4Value = await test4.promise;
//   log(`Test 4 value: ${test4Value}`);
// }

// debugTest();
