import { ApiType } from '../ApiType';
// import { MBaasPlotsService } from '../plots/MBaasPlotsService';
// import { PlotsService } from '../plots/PlotsService';
import { BaseSuite } from './BaseSuite';
import { registerSuite, Suite } from './Suite';

class MBaasSuite extends BaseSuite {
  readonly apiType = ApiType.MBaas;

  // private _plotsService: MBaasPlotsService;

  // get plotsService(): PlotsService {
  //   if (!this._plotsService) {
  //     this._plotsService = new MBaasPlotsService(this.mainSuite.userService);
  //   }
  //   return this._plotsService;
  // }
}

export const mBaasSuite = new MBaasSuite();
registerSuite(mBaasSuite);
