/**
 * Dates info for a sale.
 */
export type SaleDates = {
  /**
   * The start date in ISO format.
   */
  startDate: string;
  /**
   * The end date in ISO format. Optional/nullable since we can have no
   * end dates for certain types of sales.
   */
  endDate?: string | null;
  /**
   * Determine if this should be the target of a countdown.
   */
  countdownTarget?: boolean;
};

export enum SaleSocialType {
  Email = 'email',
  Twitter = 'twitter',
  Discord = 'discord',
  HotWallet = 'hotWallet',
  ColdWallet = 'coldWallet',
}

export type SaleFollowSocial = {
  type: SaleSocialType;
  userId: string;
  required?: boolean;
};

export type HeaderTexts = {
  title: string;
  subTitle: string;
};

export type StepBoxTexts = {
  title: string;
  subTitle: string;
  btnLabel: string;
};

export type MintBoxTexts = {
  title?: string;
};

/**
 * Data schema of a sale.
 */
export type Sale = {
  /**
   * Unique sale ID for mint path, i.e. /mint/{saleId}
   */
  saleId: string;

  /**
   * Type of sale, i.e. characterPass
   */
  saleType: string;

  /**
   * Always show in main mint page, overriding any sale weight, dates, and supplies.
   */
  featured: boolean;

  /**
   * Token type, should coincide with the backend token types and endpoints.
   */
  tokenType: string;

  /**
   * Contract address of token
   */
  tokenContractAddress: string;

  /**
   * Wallet address where the story is stored
   */
  nftWalletAddress?: string;

  /**
   * StoryId that used with wallet address (above) forms the storyKey
   */
  nftStoryId?: string;

  /**
   * Name of invdividual NFTs
   */
  tokenNameSingular: string;

  /**
   * Name of NFTs as a plural
   */
  tokenNamePlural: string;

  /**
   * Shorter (maybe abbreviated) singular name of NFT
   */
  tokenShortNameSingular: string;

  /**
   * Shorter (maybe abbreviated) plural name of NFT
   */
  tokenShortNamePlural: string;

  /**
   * Multibaas address label for minting. Optional if no minting yet.
   */
  mbaasAddressLabel?: string;

  /**
   * The name that will be shown on the mint page
   */
  saleName: string;

  /**
   * Header title override
   */
  saleHeaderTitle?: string;

  /**
   * Header subtitle override
   */
  saleHeaderSubTitle?: string;

  /**
   * Determines the priority of which sale should be shown by default.
   * Actual algorithm should be in a SaleService function.
   */
  saleWeight: number;

  /**
   * Description of sale to show (for collection type)
   */
  saleDescription: string;

  /**
   * Video to show in sale page
   */
  saleMedia?: {
    imageUrl?: string;
    vimeoUrl?: string;
    directVideoUrl?: string;
    sharePreviewVimeoUrl?: string;
    aspectRatio?: string;
    twitterCopy?: {
      text?: string;
      title?: string;
    };
    shareClaimVideoUrl?: string;
    shareMintVideoUrl?: string;
    twitterButtonTitle?: string;
  };

  /**
   * List of socials to follow for sale
   */
  //followSocials?: SaleFollowSocial[];
  followSocials?: any;

  /**
   * List of required socials to proceed
   */
  requiredSocials?: SaleSocialType[];

  /**
   * Link to contract (for collection type)
   */
  contractLink?: string;

  /**
   * campaign ID for sale
   */
  marketingCampaign?: string;

  /**
   * Whitelist details. Optional since not every sale will have a whitelist.
   */
  whitelist?: {
    /**
     * Whitelist sign up dates.
     */
    signUp: SaleDates;

    /**
     * Whitelist mint dates. Optional since we may not have dates yet.
     */
    mint?: SaleDates;
  };

  /**
   * Public mint details. Optional since we can have a whitelist but no
   * public mint dates.
   */
  publicMint?: SaleDates;

  /**
   * Whitelist and public mint for this sale will be concurrent if true.
   */
  publicAndWhitelistMint?: boolean;

  /**
   * URL to NFT placeholder images for the MintEntry state page. Optional
   * if we are guaranteed to have image URLs either way.
   */
  pfpPlaceholderUrl?: string;

  /**
   * Contract address of minted tokens from this sale. Optional since we may
   * not have this yet.
   */
  mintedContractAddress?: string;

  /**
   * Opensea link to minted tokens' collection. Optional since we may not have
   * this yet.
   */
  mintedOpenseaLink?: string;

  /**
   * Policy ID for tokenproof login for different kinds of filtering for
   * whitelist/minting.
   */
  tokenproofPolicyId?: string;

  /**
   * List of cross links to other sales. Optional since we may not have any.
   * e.g
   * for Lightyear => [Fore Play, FezLove]
   * for Fore Play => [Lightyear, FezLove]
   * FezLove => [Fore Play, Lightyear]
   */
  crossLinks?: {
    name: string;
    url: string;
  }[];

  /**
   * The default viewer url for a nft story. It's the first ever minted token for a {contractKey}
   */
  defaultViewerUrl?: string;

  /**
   * Copies for different mint state texts
   */
  mintCopy?: {
    /**
     * Copies for upcoming mint (registration closed, before mint start date)
     */
    upcoming?: {
      header?: Partial<HeaderTexts>;
      contentTitle?: string;
      contentSubTitle?: string;
    };

    /**
     * Copies for open mint (between mint start and end dates)
     */
    open?: {
      shareStepBox?: Partial<StepBoxTexts>;
      winners?: {
        header?: Partial<HeaderTexts>;
        stepBox?: Partial<StepBoxTexts>;
      };
      public?: {
        header?: Partial<HeaderTexts>;
      };
      mintBox?: Partial<MintBoxTexts>;
    };

    /**
     * Copies for closed mint (after mint end date)
     */
    closed?: {
      header?: Partial<HeaderTexts>;
      contentTitle?: string;
      contentSubTitle?: string;
      stepBox?: Partial<StepBoxTexts>;
    };
  };
};

export type MockSale = Sale & {
  whitelistSpots?: number;
  whitelistSupply?: number;
  publicSupply?: number;
};

export type CreateMintDateOverridesFn = null | ((sales: Sale[]) => Partial<Sale>[] | undefined);

export interface SaleExtra {
  saleOverrides: Partial<Sale>[];
  sales: Sale[];
  createMintDateOverrides: CreateMintDateOverridesFn;
}

export interface MockSaleExtra extends SaleExtra {
  sales: MockSale[];
}

export default Sale;
