import VideoPlayer from '@components/VideoPlayer';
import VimeoReactPlayer from '@components/vimeo-react-player/VimeoReactPlayer';
import { useMintState } from '@context/mint/MintContext';
import { useClassName } from '@hooks/useClassName';
import { CSSProperties, useEffect, useState } from 'react';
import MintCollection from './MintCollection';
import './MintCollectionInfo.scss';

export type CollectionInfoProps = {
  id: string;
};

// workaround for having formatted description
const saleDescMap: Record<string, JSX.Element> = {
  fezlove: (
    <>
      <p>
        Fez the Ape is a hardworking, gas station-owning Ape who is determined to find love in the modern world. Join him on his hilarious journey as he
        navigates the obstacles outside the Jungle for love. With the help of his friends, family, and some unlikely allies, Fez discovers that love may be
        closer than he thinks.
      </p>
      <h3>The Writer</h3>
      <p>
        Turhan Troy Caylak is a 2-time Screen Actors Guild Award nominee from the HBO hit show Barry. He splits his time writing Hollywood screenplays and
        content creation for the KingPinDao, GTVPrimeTime and Atrium.
      </p>
    </>
  ),
};

const sale = {
  saleId: 'myId',
  saleName: 'Fez Love',
  saleDescription: saleDescMap.fezlove,
  contractLink: 'storyverse_xyz/about',
  saleMedia: {
    directVideoUrl: 'https://cdn.alpha.storyverse.xyz/static/media/TopImage.9dfceaee.mp4',
    vimeoUrl: 'https://cdn.alpha.storyverse.xyz/static/media/TopImage.9dfceaee.mp4',
    imageUrl: 'https://cdn.alpha.storyverse.xyz/static/media/TopImage.9dfceaee.mp4',
    aspectRatio: '4/2',
  },
};

function CollectionMedia({ className }: { className?: string }) {
  // todo: restore!
  // const { sale } = useMintState();
  const mediaClassName = useClassName('coll-mint-info-video', className);

  const [style, setStyle] = useState<CSSProperties>();

  useEffect(() => {
    if (!sale?.saleMedia?.aspectRatio) {
      setStyle({});
    }

    setStyle({
      aspectRatio: sale.saleMedia.aspectRatio,
    });
  }, [sale?.saleMedia?.aspectRatio]);

  if (!sale?.saleMedia) {
    return null;
  }

  if (sale.saleMedia.directVideoUrl) {
    return (
      <VideoPlayer
        className={mediaClassName}
        src={sale.saleMedia.directVideoUrl}
        autoPlay
        muted
        preload="auto"
        loop
        playsInline
        controls={false}
        style={style}
      />
    );
  }

  if (sale.saleMedia.vimeoUrl) {
    const iframeProps = { style };
    return <VimeoReactPlayer className={mediaClassName} url={sale.saleMedia.vimeoUrl} autoPlayType="inview" loop iframeProps={iframeProps} />;
  }

  if (sale.saleMedia.imageUrl) {
    return <img className={mediaClassName} src={sale.saleMedia.imageUrl} alt={sale.saleName} style={style} />;
  }

  return null;
}

export function CollectionInfo({ id }: CollectionInfoProps) {
  // todo: restore!
  // const { sale } = useMintState();

  const title = sale?.saleName;
  const description = saleDescMap[sale?.saleId] ?? sale?.saleDescription;
  const minted = `${0} Minted`; // todo: get how many minted from backed

  const contents: JSX.Element[] = [];

  contents.push(<div className="coll-mint-info-minted-counter">{minted}</div>);

  if (sale?.contractLink) {
    contents.push(
      <a href={sale.contractLink} target="_blank">
        Link to Contract
      </a>,
    );
  }

  // fixes 'Each Child in a List Should Have a Unique 'key' Prop' error/warning
  contents.map((item) => {
    <li key="{item}">{item}</li>;
  });

  return (
    <div className="coll-mint-info">
      <CollectionMedia />
      <div className="coll-mint-info-title">{title}</div>
      {Boolean(contents.length) && <div className="coll-mint-info-minted">{contents}</div>}

      <div className="coll-mint-info-bottom">
        <div className="coll-mint-info-description">{description}</div>
        <MintCollection />
      </div>
    </div>
  );
}

export default CollectionInfo;
