import { debug } from '@common/LogWrapper';
import { useEffect } from 'react';
import { LoadState } from '@common/LoadState';
import { MintActionType, MintCheckType, MintPageState, useMintDispatch, useMintState } from '@context/mint/MintContext';
import { mainSuite } from '@services/ServiceFactory';
import { useUserState } from '@services/user/UserContext';
import { environment } from '@environment';
import TransitioningSection from '@components/transitioning-section';
import GradientTitle from '@components/GradientTitle';
import './WhitelistCheckingSection.scss';
import { AnalyticsEventName } from '@services/analytics/AnalyticsEventName';

const log = debug('app:pages:sections:WhitelistCheckingSection');

export function WhitelistCheckingSection() {
  const saleApi = mainSuite.saleService;
  const tokenproofService = mainSuite.tokenproofService;
  const analyticsService = mainSuite.analyticsService;
  const userState = useUserState();
  const mintState = useMintState();
  const mintDispatch = useMintDispatch();

  useEffect(() => {
    if (mintState.checkType === MintCheckType.None) {
      return;
    }

    let stale = false;

    // async function checkFromSaleApi(address: string) {
    //   log('Checking eligible tokens from sale api');
    //   try {
    //     const eligibleTokens = await saleApi.fetchEligiblePlots({
    //       tokenType: environment.activeSaleTokenType,
    //       walletAddress: address,
    //     });

    //     if (stale) { return; }

    //     mintDispatch({
    //       type: MintActionType.UpdateEligibility,
    //       eligibleTokens,
    //       eligibleState: LoadState.Loaded,
    //     });
    //     mintDispatch({
    //       type: MintActionType.UpdateMintPageState,
    //       pageState: MintPageState.WhitelistChecked,
    //     });
    //   } catch (e) {
    //     console.error(e); // still want to log any potential errors

    //     if (stale) { return; }

    //     mintDispatch({
    //       type: MintActionType.UpdateEligibility,
    //       eligibleState: LoadState.Errored,
    //     });
    //   }
    // }

    async function addToWhitelist(nonce: string = tokenproofService.getNonce()) {
      log('Adding to whitelist');
      const sale = mintState.sale;
      const hotWallet = mintState.hotWallet;
      try {
        analyticsService.track(AnalyticsEventName.WhitelistAddStart, {
          addedEmail: !!mintState.signUpEmail,
        });
        const response = await saleApi.addToWhitelist({ saleId: sale.saleId, whitelistPayload: { nonce, hotWallet } });

        log('Response from addToWhitelist:', response);
        mintDispatch({
          type: MintActionType.UpdateEligibility,
          eligibleAdded: response.added,
          eligibleTokens: response.count,
          eligibleState: LoadState.Loaded,
          storyKey: response.storyKey,
        });
        mintDispatch({
          type: MintActionType.UpdateMintPageState,
          pageState: MintPageState.WhitelistChecked,
        });
      } catch (e) {
        analyticsService.track(AnalyticsEventName.WhitelistAddError, {
          errorCode: e.code,
          errorMessage: e.message,
          errorStack: e.stack,
        });
        mintDispatch({
          type: MintActionType.CheckFailed,
          errorMsg: 'Whitelisting failed. Please try again.',
        });
      }
    }

    if (mintState.checkType === MintCheckType.Metamask) {
      if (environment.saleApi === 'mock') {
        addToWhitelist(mintState.walletToCheck); // only for mock
      } else {
        // checkFromSaleApi(mintState.walletToCheck);
        throw new Error('Unsupported check type');
      }
    } else if (mintState.checkType === MintCheckType.Tokenproof) {
      addToWhitelist();
    } else if (mintState.checkType === MintCheckType.Manual) {
      // checkFromSaleApi(mintState.walletToCheck);
      throw new Error('Unsupported check type');
    }

    return () => {
      stale = true;
    };
  }, [saleApi, tokenproofService, mintState.checkType, mintState.sale]);
  return (
    <div className="whitelist-checking-section">
      <GradientTitle>Adding to whitelist...</GradientTitle>
      <TransitioningSection />
    </div>
  );
}
