import React from 'react'
import type { ComponentProps } from 'react'

import './Section.styles.scss'


type SectionProps = ComponentProps<'section'>

export const Section = ({
  children,
  className,
  ...others
}: SectionProps) => (
  <section className={`Section ${className ? className : ''}`} {...others}>
    {children}
  </section>
)
