import { MetadataService } from './MetadataService';
import { environment } from '../../environments/environment';
import axios from 'axios';
import debug from 'debug';
import { waitForMs } from '../../common/WaitUtils';

const log = debug('app:services:RestMetadataService');

const maxRetries = 30;
const retryDelay = 2000;

function createAxiosInstance(baseURL: string) {
  return axios.create({
    baseURL,
    timeout: 10000
  });
}

async function fetchMetadata(nftId: string): Promise<Play.NFTMetadata> {
  const axiosInstance = createAxiosInstance(environment.restMetadataUrl);
  try {
    const result = await axiosInstance.get('/' + nftId);
    const metadata = result.data;
    if (!metadata) {
      throw new Error('Metadata not found');
    }
    return metadata;
  } catch (e) {
    log('Error occurred:', e);
    return null;
  }
}

export class RestMetadataService implements MetadataService {
  async loadNFTMetadata(nftIds: string[]): Promise<Play.NFTMetadataMap> {
    const map: Play.NFTMetadataMap = {};
    for (const id of nftIds) {
      try {
        const metadata = await this.loadSingleMetadata(id);
        map[id] = {
          id,
          name: metadata.name,
          image: metadata.image,
        };
      } catch(e) {
        log('Fetching metadata failed:', e)
        map[id] = { id };
      }
    }
    
    return map;
  }
  async loadSingleMetadata(nftId: string): Promise<Play.NFTMetadata> {
    return await fetchMetadata(nftId);
  }
}
