import { Link, useLocation } from 'react-router-dom';
import { AppActionType, useAppDispatch, useAppState } from '@context/AppContext';
import { useUserState } from '@services/user/UserContext';
import { ApiType } from '@services/ApiType';
import { environment } from '@environment';
import { UserAccount } from '@common/UserAccount';
import { UserPermission } from '@common/UserPermission';
import { FormEvent, useRef } from 'react';

import './Footer.styles.scss';

import OpenSea from '@assets/landing-page-v3/logos/OpenSea.svg';
import Etherscan from '@assets/landing-page-v3/logos/Etherscan.svg';
import Discord from '@assets/landing-page-v3/logos/Discord.svg';
import Twitter from '@assets/landing-page-v3/logos/Twitter.svg';
import { SearchParam } from '@common/SearchParam';
import { CharPassDates } from '../../pages/mint-v2/sections/CharPassDates';
import useUserService from '@hooks/useUserService';

function hasAdminPerm(userState: UserAccount) {
  return userState.permissions.includes(UserPermission.Admin);
}

export function Footer() {
  const userAccount = useUserState();
  const userService = useUserService();
  const appState = useAppState();
  const appDispatch = useAppDispatch();
  const emailFieldRef = useRef<HTMLInputElement>();
  const location = useLocation();

  if (!appState.showFooter) {
    return null;
  }

  function submitSignUpEmail(e: FormEvent) {
    e.preventDefault();

    if (!emailFieldRef.current) {
      return;
    }

    // open modal with predefined email
    appDispatch({
      type: AppActionType.UpdateSignUpEmail,
      email: emailFieldRef.current.value,
      showSignUpModal: true,
    });

    // scroll up
    window.location.hash = '#top';
  }

  const showMockLinks = environment.userServiceApi === ApiType.Mock && userAccount.loggedIn;
  return (
    <footer className={'Footer'}>
      <div className={'FooterAlign'}>
        <div className={'LinkContainer'}>
          {/* <div>
            <p>Create</p>
            <Link to='/create'>Create</Link>
            <a href='/about#about'>About</a>
            {appState.features.showMapLink && <Link to='/maps'>Maps</Link>}
            {appState.features.showWhitepaperLink && <Link to='/whitepaper'>Whitepaper</Link>}
          </div> */}
          <div>
            <p>About</p>
            <Link to="/tos">TOS</Link>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/copyright">Copyright</Link>
            <Link to="/cookies">Cookies</Link>
          </div>
          <div>
            <p>Resources</p>
            <Link to="/founderspassterms">Founder's Pass TOS</Link>
            <Link to="/collectibleterms">Collectible Story TOS</Link>
            <Link to="/collectiblegiveaways">Collectible Story Giveaways</Link>
            {appState.features.showContactsSection && <a href="/about#contact">Contact</a>}
          </div>
          {showMockLinks && (
            <div>
              <p>Mock</p>
              {hasAdminPerm(userAccount) && (
                <>
                  <Link to="/admin">Admin</Link>
                </>
              )}
              <Link to={`/logout?${SearchParam.Redirect}=${encodeURIComponent(location.pathname)}`}>Log out</Link>
              <span className="clickable-text" onClick={() => userService.logIn()}>
                Change Account
              </span>
            </div>
          )}
        </div>

        <div className="Right">
          {appState.features.showFooterSignUp && (
            <div className="EmailSignup">
              <p>Sign up for our future drops</p>

              <form onSubmit={submitSignUpEmail}>
                <input type="email" placeholder="Email Address" ref={emailFieldRef} />
                <button type="submit"></button>
              </form>
            </div>
          )}

          {appState.showFooterMintDates ? (
            <CharPassDates alwaysShow />
          ) : appState.showFooterDiscordOnly ? (
            <div className="discord-only">
              Questions? Join our{' '}
              <a className="mint-gradient-title" href="https://discord.com/invite/storyverse" target="_blank">
                Discord!
              </a>
            </div>
          ) : (
            <div className="SocialLinks">
              <a href="https://opensea.io/collection/storyverse-founders-pass" target="_blank">
                <img src={OpenSea} alt="OpenSea" />
              </a>
              <a href="https://etherscan.io/token/0x8f1bc587ff2d87cfc0c5db69a04d094c523008d4" target="_blank">
                <img src={Etherscan} alt="Etherscan" />
              </a>
              {/* <a href='' target='_blank'>
                <img src={YouTube} alt='YouTube' />
              </a>
              <a href='' target='_blank'>
                <img src={Instagram} alt='Instagram' />
              </a>
              <a href='' target='_blank'>
                <img src={TikTok} alt='TikTok' />
              </a> */}
              <a href="https://discord.com/invite/storyverse" target="_blank">
                <img src={Discord} alt="Discord" />
              </a>
              <a href="https://twitter.com/storyverse_xyz" target="_blank">
                <img src={Twitter} alt="Twitter" />
              </a>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
}
