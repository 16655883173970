import { useNavigate } from 'react-router-dom';
import './Item.scss';

import ItemTag from './ItemTag';
import { collections } from '../../../assets/homepage';
import { MintActionType, useMintDispatch } from '../../../context/mint/MintContext';

export type ItemProps = {
  id: string;
  title: string;
  // userName: string;
  userUrl: string;
  mint: string;
  coll: string;
  type: 'live' | 'register';
};

export function Item({ id, title, userUrl, mint, coll, type }: ItemProps) {
  const navigate = useNavigate();
  const mintDispatch = useMintDispatch();
  const collectionMedia = {
    ...collections.placeholders,
    ...(collections[id] || {}),
  };

  function clickItem(e: React.MouseEvent, id: string) {
    e.stopPropagation();
    console.warn('>>> clicking on item', id);
    mintDispatch({
      type: MintActionType.Reset,
    });
    navigate(collectionMedia.url);
  }

  return (
    <div className="item-container" style={{ backgroundImage: `url(${collectionMedia.thumb})` }} onClick={(e) => clickItem(e, id)}>
      <ItemTag type={type} />

      <div className="content">
        <div className="title">{title}</div>
        <div className="parent-div">
          <div className="user">
            <div className="avatar" style={{ backgroundImage: `url(${collectionMedia.avatar})` }} />
            <a href="" className="name">
              {collectionMedia.author}
            </a>
          </div>
          <div className="labels">
            <div className="mint">{mint}</div>
            {/*<div className="coll">{coll}</div>*/}
          </div>
        </div>

        <div className="bottom">
          <button className="button-blue" aria-label="Submit">
            {type == 'live' ? 'Mint Now' : 'Register'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Item;
