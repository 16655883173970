import React, { useMemo } from 'react';

export function TextNewLiner({ text }: { text: string; }) {
  const textParts = useMemo(() => text.split('\n'), [text]);
  return <>
    {textParts.map((part, index, arr) => (
      <React.Fragment key={index}>
        {part}
        {index !== arr.length - 1 && <br />}
      </React.Fragment>
    ))}
  </>;
}
