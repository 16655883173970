import { ChainwatcherTimeoutError } from '@errors';
import { SvsNavbarEvent } from '@storyverseco/svs-navbar';
import { mainSuite } from '@services/ServiceFactory';
import { useCallback, useEffect, useState } from 'react';
import { MintBox } from './MintBox';
import './MintBox.scss';

const ShowMintBoxEvent = 'ShowMintBox';
enum GameEvents {
  SiteMintSuccess = 'SiteMintSuccess',
  SiteMintFailure = 'SiteMintFailure',
  SiteMintTokenTimeout = 'SiteMintTokenTimeout',
}

export const GameMintBox = () => {
  const [show, setShow] = useState(false);
  const [mintErrorMessage, setMintErrorMsg] = useState('');

  useEffect(() => {
    const onNavbarEvent = (evt: any, data) => {
      if (evt === ShowMintBoxEvent) {
        setShow(Boolean(data?.show));
      }
    };
    mainSuite.navbarService.on(SvsNavbarEvent.Any, onNavbarEvent);
    return () => {
      mainSuite.navbarService.off(SvsNavbarEvent.Any, onNavbarEvent);
    };
  }, []);

  const onMintSuccess = useCallback((tokenId: string) => {
    console.log('onMintSuccess', { tokenId });
    mainSuite.navbarService.sendClientEvent(GameEvents.SiteMintSuccess, tokenId);
  }, []);

  const onMintFailure = useCallback((err: Error) => {
    setMintErrorMsg(err.message);

    const isTimeout = err.message.includes('blockchain is busy');

    if (isTimeout) {
      mainSuite.navbarService.sendClientEvent(GameEvents.SiteMintTokenTimeout);
    } else {
      mainSuite.navbarService.sendClientEvent(GameEvents.SiteMintFailure);
    }
  }, []);

  return (
    <div className={`mintbox-container ${show ? '' : 'hide'}`}>
      <MintBox onMintStart={() => {}} onMintFailure={onMintFailure} onMintSuccess={onMintSuccess} />;
      {mintErrorMessage && <div className="coll-form-error-msg">{mintErrorMessage}</div>}
    </div>
  );
};
