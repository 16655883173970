import React, { useEffect, useState, useRef } from 'react'
import { debug } from '@common/LogWrapper'

import { Section } from '@components/Section'
import { GradientTitle } from '@components/GradientTitle'
import ExternalLink from '@components/ExternalLink'
import VideoPlayer from '@components/VideoPlayer'

import './CarouselSection.styles.scss'
import OpenSeaLogo from '@assets/landing-page-v3/logos/OpenSea.svg'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

import { carouselPlots } from '@assets/landing-page-v3/carousel'

const log = debug('app:landing:carousel')

function CarouselItem({ plot, isSelected }: {
  plot: typeof carouselPlots[number],
  isSelected?: boolean,
}) {

  return (
    <div className='slideContent' key={plot.tokenId}>
      <VideoPlayer
        src={plot.media}
        poster={plot.poster}
        play={isSelected}
        muted
        preload='auto'
        loop
        playsInline
        controls={false}
        className='LandingImage'
      />
      {/* <img src={plot.image} alt={`Story for ${plot.title}`} className='LandingImage' loading='lazy' /> */}
      <div className='caption'>
        <p>{plot.title}</p>
        {/* <p>Owned by <ExternalLink href={plot.ownerLink}>{plot.ownerName}</ExternalLink></p> */}
      </div>
    </div>
  )
}

export const CarouselSection = () => {
  const [ selectedItem, setSelectedItem ] = useState(0)

  function customRenderItem(item, props) {
    return <item.type {...item.props} {...props} />
  }

  return (
    <Section className='CarouselSection'>
      <Carousel
        autoPlay
        centerMode
        centerSlidePercentage={50}
        infiniteLoop
        interval={10000}
        showStatus={false}
        showThumbs={false}
        swipeable={false}
        selectedItem={selectedItem}
        onClickItem={(clickedItemId) => setSelectedItem(clickedItemId)}
        renderItem={customRenderItem}
      >
        {carouselPlots.map((plot, index) => (
          <CarouselItem key={plot.tokenId} plot={plot} />
        ))}
      </Carousel>

      <GradientTitle>Our Stories</GradientTitle>
      <p>Written by the community</p>

      <a href='https://opensea.io/collection/storyverse-founders-pass' target='_blank'>
        <img src={OpenSeaLogo} alt='OpenSea Logo' />
        View in OpenSea
      </a>
    </Section>
  )
}
