import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.scss';

/* 
Workaround for "position: fixed" bug where transform or filter will cause
"fixed" elements to stick to the parent element instead of the viewport.
https://stackoverflow.com/questions/52937708/why-does-applying-a-css-filter-on-the-parent-break-the-child-positioning
*/
const rootElement = document.getElementById('root');
rootElement.addEventListener('animationend', () => {
  rootElement.style.animation = 'none';
}, false);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootElement,
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
