import { MouseEvent } from 'react';
import GradientTitle from '@components/GradientTitle';
import { ModalPortal, ModalCloseButton } from '@components/modalportal/ModalPortal';
import { AnalyticsEventName } from '@services/analytics/AnalyticsEventName';
import { mainSuite } from '@services/ServiceFactory';
import './NeedAccountModal.scss';
import { useMintState } from '@context/mint/MintContext';

export function NeedAccountModal({ onClose }: { onClose: () => void }) {
  const { analyticsService } = mainSuite;
  const mintState = useMintState();

  function closeButtonClicked(e: MouseEvent<HTMLDivElement>) {
    // ensures it's the button or the background that was clicked
    if (e.target !== e.currentTarget) {
      return;
    }

    onClose();
  }

  const nftName = mintState?.sale?.tokenShortNameSingular ?? 'NFT';

  return (
    <ModalPortal>
      <div className="need-account-modal-con" onClick={closeButtonClicked}>
        <div className="need-account-modal text-center">
          <h1 className="mint-gradient-title">In order to sign up you need a Tokenproof account to validate your {nftName}.</h1>
          <GradientTitle>Please go to Tokenproof and make an account first.</GradientTitle>
          <div className="text-center">
            <a
              href="https://tokenproof.xyz/enrollment"
              className="mint-btn-cta"
              target="_blank"
              onClick={() => analyticsService.track(AnalyticsEventName.ButtonPress, { buttonName: 'tokenproofExt' })}
            >
              GO TO TOKENPROOF
            </a>
          </div>
          <ModalCloseButton onClick={closeButtonClicked} />
        </div>
      </div>
    </ModalPortal>
  );
}
