import deFiAndTheCity from './77634202551809989760654462161021701457269602020616730708810869957824872492.gif.mp4';
import deFiAndTheCityPoster from './77634202551809989760654462161021701457269602020616730708810869957824872492.poster.jpg';
import toTheMoonMeta from './72459471498662388229082474994677492985349853758174844842761352625564680207.gif.mp4';
import toTheMoonMetaPoster from './72459471498662388229082474994677492985349853758174844842761352625564680207.poster.jpg';
import queenOfOpenSea from './78034895772781323454465622640213547173484445148962033197596468187897006979.gif.mp4';
import queenOfOpenSeaPoster from './78034895772781323454465622640213547173484445148962033197596468187897006979.poster.jpg';
import theCopyCatCrew from './77822561862347127614730080593028934288944985961902016931195917784651400717.gif.mp4';
import theCopyCatCrewPoster from './77822561862347127614730080593028934288944985961902016931195917784651400717.poster.jpg';
import mintConditions from './77093904441168741086979434845726990724347736724400506415443039242195830353.gif.mp4';
import mintConditionsPoster from './77093904441168741086979434845726990724347736724400506415443039242195830353.poster.jpg';
import bitcoinchella from './79241523718895061132599219194837638887709880144791119010511829414463931942.gif.mp4';
import bitcoinchellaPoster from './79241523718895061132599219194837638887709880144791119010511829414463931942.poster.jpg';

export const carouselPlots = [
  {
    tokenId: '77634202551809989760654462161021701457269602020616730708810869957824872492',
    title: 'DeFi and the City',
    media: deFiAndTheCity,
    poster: deFiAndTheCityPoster,
    ownerName: '15CECF',
    ownerLink:
      'https://opensea.io/assets/ethereum/0x3a7011e7e2b32c2b52f7de1294ff35d6ff20310f/77634202551809989760654462161021701457269602020616730708810869957824872492',
  },
  {
    tokenId: '72459471498662388229082474994677492985349853758174844842761352625564680207',
    title: 'To the Moon, Meta',
    media: toTheMoonMeta,
    poster: toTheMoonMetaPoster,
    ownerName: '15CECF',
    ownerLink:
      'https://opensea.io/assets/ethereum/0x3a7011e7e2b32c2b52f7de1294ff35d6ff20310f/72459471498662388229082474994677492985349853758174844842761352625564680207',
  },
  {
    tokenId: '78034895772781323454465622640213547173484445148962033197596468187897006979',
    title: 'Queen of OpenSea',
    media: queenOfOpenSea,
    poster: queenOfOpenSeaPoster,
    ownerName: '15CECF',
    ownerLink:
      'https://opensea.io/assets/ethereum/0x3a7011e7e2b32c2b52f7de1294ff35d6ff20310f/78034895772781323454465622640213547173484445148962033197596468187897006979',
  },
  {
    tokenId: '77822561862347127614730080593028934288944985961902016931195917784651400717',
    title: 'The Copy Cat Crew',
    media: theCopyCatCrew,
    poster: theCopyCatCrewPoster,
    ownerName: '15CECF',
    ownerLink:
      'https://opensea.io/assets/ethereum/0x3a7011e7e2b32c2b52f7de1294ff35d6ff20310f/77822561862347127614730080593028934288944985961902016931195917784651400717',
  },
  {
    tokenId: '77093904441168741086979434845726990724347736724400506415443039242195830353',
    title: 'Mint Conditions',
    media: mintConditions,
    poster: mintConditionsPoster,
    ownerName: '15CECF',
    ownerLink:
      'https://opensea.io/assets/ethereum/0x3a7011e7e2b32c2b52f7de1294ff35d6ff20310f/77093904441168741086979434845726990724347736724400506415443039242195830353',
  },
  {
    tokenId: '79241523718895061132599219194837638887709880144791119010511829414463931942',
    title: 'Bitcoinchella',
    media: bitcoinchella,
    poster: bitcoinchellaPoster,
    ownerName: '15CECF',
    ownerLink:
      'https://opensea.io/assets/ethereum/0x3a7011e7e2b32c2b52f7de1294ff35d6ff20310f/79241523718895061132599219194837638887709880144791119010511829414463931942',
  },
];
