import { mainSuite } from '@services/ServiceFactory';
import { ContractAddress } from '@storyverseco/svs-types';
import { ChainwatcherTimeoutError } from '@errors';

const RETRY_DELAY = 5000; // 5s
const MAX_RETRIES = 12; // 5s x 12 = 1min

export const waitForMyToken = async (contractAddress: ContractAddress, retries: number = 0) => {
  const myTokenId = await mainSuite.userService.fetchMyStoryTokenForContract({
    contractAddress,
    ignoreEqual: true,
  });

  if (!myTokenId) {
    if (retries >= MAX_RETRIES) {
      throw new ChainwatcherTimeoutError();
    }
    await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
    return waitForMyToken(contractAddress, ++retries);
  }
  return myTokenId;
};
