import { environment } from '@environment';
import { useAppState } from '@context/AppContext';
import { useEffect, useMemo } from 'react';
import { SearchParam } from '@common/SearchParam';

export function useAppUrl(normalUrl: string, paramsStr?: string) {
  const appState = useAppState();

  return useMemo(() => {
    if (environment.appUrlOverride && !appState?.initialSearchParams) {
      return null;
    }
    let baseUrl: string;
    if (environment.appUrlOverride) {
      baseUrl = appState.initialSearchParams.get(SearchParam.AppUrl) ?? normalUrl;
    } else {
      baseUrl = normalUrl;
    }

    if (!paramsStr) {
      return baseUrl;
    }

    // we need to properly parse search params and combine
    const appUrlObj = new URL(baseUrl);
    const params = new URLSearchParams(paramsStr);
    for (const [key, value] of params) {
      // prioritize baseUrl's parameters over paramsStr
      if (!appUrlObj.searchParams.has(key)) {
        appUrlObj.searchParams.append(key, value);
      }
    }

    return appUrlObj.toString();
  }, [appState?.initialSearchParams, paramsStr]);
}
