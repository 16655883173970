import './create-landing.scss';
import { Page } from '../Page';
import { mainSuite } from '@services/ServiceFactory'
import { AnalyticsEventName } from '@services/analytics/AnalyticsEventName'
import { debug } from '@common/LogWrapper'
import { useEffect } from 'react'

import { LandingSection } from './sections/LandingSection'
import { VideoSection } from './sections/VideoSection'
import { CarouselSection } from './sections/CarouselSection'
import { ShowcaseSection } from './sections/ShowcaseSection'
import { RoadmapSection } from './sections/RoadmapSection'
import { TeamSection } from './sections/TeamSection'
import { FaqSection } from './sections/FaqSection'
import { ContactSection } from './sections/ContactSection'


const log = debug('app:pages:CreateLanding');

export function CreateLandingPage() {
  useEffect(() => {
    mainSuite.analyticsService.userTrack({})
    mainSuite.analyticsService.track(AnalyticsEventName.EntryFinal)
  }, []);

  return (
    <Page title='Home' className='create-landing-page' analyticsPageName='create-landing' analyticsManualEntryFinal>
      <LandingSection />
      <VideoSection />
      <CarouselSection />
      <ShowcaseSection />
      <RoadmapSection />
      <TeamSection />
      <FaqSection />
      <ContactSection />
    </Page>
  );
}
