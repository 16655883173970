import svsfl from './svsfl';
import svscs from './svscs';
import svsfp from './svsfp';
import svsrl from './svsrl';
import svsbrm from './svsbrm';
import svseh from './svseh';
import svssc from './svssc';
import placeholders from './placeholders';
import svspr from './svspr';

export const collections = {
  svsfl,
  placeholders,
  svscs,
  svsfp,
  svsrl,
  svsbrm,
  svseh,
  svssc,
  svspr,
};

export type Collections = typeof collections;

export type Collection = keyof typeof collections;
