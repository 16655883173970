import AvatarImg from './avatar.png';
import ThumbImg from './thumb.png';

export default {
  avatar: AvatarImg,
  thumb: ThumbImg,
  author: 'Bruno Dias',
  url: '/prisonrush',
  footnote: null,
};
