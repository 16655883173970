import { useEffect, useMemo, useRef, useState } from 'react';
import { debug } from '@common/LogWrapper';
import { HostedApp } from '@components/hosted-app/HostedApp';
import { environment } from '@environment';
import { usePassthroughParams } from '@hooks/usePassthroughParams';
import { useParams } from 'react-router-dom';
import { useAppUrl } from '@hooks/useAppUrl';
import { Page } from '../Page';
import templateAliases from '@assets/data/templatealias.json';
import './CreatePage.scss';
import { GameSiteUrlParams } from '@storyverseco/svs-consts';
import { paramsToSearchParams, useParamsToSearchParams } from '@hooks/useParamsToSearchParams';
import { mainSuite } from '@services/ServiceFactory';
import { LoadingVideo, StaticLoadingVideo, SvsNavbarEvent, SvsProvider } from '@storyverseco/svs-navbar';
import { useAppState } from '@context/AppContext';
import { SearchParam } from '@common/SearchParam';
import LoadingVideoOverlay from '@components/loading-video-overlay/LoadingVideoOverlay';
import { useUserHook } from '@hooks/useUserHook';

const log = debug('app:pages:create');

const predefinedSearchParams = [{ key: 'iframed', value: '1' }];

// page param to search param mapping for template links
const templateMapping = {
  [GameSiteUrlParams.Template]: GameSiteUrlParams.Template,
};

// page param to search param mapping for story links
const storyMapping = {
  [GameSiteUrlParams.WalletAddress]: GameSiteUrlParams.WalletAddress,
  [GameSiteUrlParams.StoryId]: GameSiteUrlParams.StoryId,
};

// allows empty strings, since empty strings are falsy
function isStrDefined(str: string | null) {
  return typeof str === 'string';
}

// get relevant search params
function useStoryOrTemplateSearchParams() {
  const params = useParams();
  const paramItems = useMemo(() => {
    // if one param, it's a template link
    if (params[GameSiteUrlParams.Template]) {
      // convert page params to search params
      const items = paramsToSearchParams(params, templateMapping).map(({ key, value }) => {
        // ignore non-template key
        if (key !== GameSiteUrlParams.Template) {
          return { key, value };
        }
        const alias = templateAliases[value.toLowerCase()];
        const templateIndex = alias ? alias.templateIndex : value;

        // replace known aliases
        return { key, value: templateIndex };
      });
      return [...items, ...predefinedSearchParams];
    }

    // if two params, it's a story link
    if (params[GameSiteUrlParams.WalletAddress] && params[GameSiteUrlParams.StoryId]) {
      // convert page params to search params
      const items = paramsToSearchParams(params, storyMapping);
      return [...items, ...predefinedSearchParams];
    }

    // if no param, just load creator normally
    return predefinedSearchParams;
  }, [params]);
  return paramItems;
}
interface CreatorPageProps {
  isNFT?: boolean;
}

export function CreatePage(props: CreatorPageProps) {
  const navbarBridge = mainSuite.navbarService;
  const appState = useAppState();
  useUserHook({ providerType: SvsProvider.WalletConnect });

  // convert page params to search params
  let combinedSearch = useStoryOrTemplateSearchParams();

  if (props.isNFT) {
    combinedSearch = [...combinedSearch, { key: GameSiteUrlParams.Nft, value: 'true' }];
  }

  // combine passthrough params with page's search params
  const searchParamsStr = usePassthroughParams(environment.passthroughParams, combinedSearch);

  // appUrl override if enabled
  const url = useAppUrl(environment.createUrl, searchParamsStr);

  const [initted, setInitted] = useState(false);
  const [loaded, setLoaded] = useState(appState.initialSearchParams.has(SearchParam.NoVideo));

  useEffect(() => {
    if (initted) {
      return;
    }
    if (!appState?.initialSearchParams) {
      return;
    }

    if (appState.initialSearchParams.has(SearchParam.NoVideo)) {
      setLoaded(true);
    }
    setInitted(true);
  }, [appState, initted, setInitted]);

  useEffect(() => {
    if (loaded) {
      return;
    }
    if (appState?.initialSearchParams?.has(SearchParam.FullVideo)) {
      return;
    }
    function onHostedAppLoaded() {
      log('hosted app loaded');
      setLoaded(true);
    }

    navbarBridge.once(SvsNavbarEvent.HostedAppLoaded, onHostedAppLoaded);

    return () => {
      navbarBridge.off(SvsNavbarEvent.HostedAppLoaded, onHostedAppLoaded);
    };
  }, [navbarBridge, loaded, setLoaded, appState]);

  function onVideoEnded() {
    setLoaded(true);
  }

  // only allow HostedApp to be shown if url and searchParamsStr are defined
  // to prevent loading twice
  return (
    <Page
      title="Creator"
      className="create-page"
      analyticsPageName="create-page-site"
      hideFooter
      fullHeight
      fixedNavbar
      hideNavbar={loaded}
      analyticsManualEntryFinal
    >
      {url && isStrDefined(searchParamsStr) && <HostedApp src={url} />}
      <LoadingVideoOverlay show={!loaded} video={StaticLoadingVideo.Default} onVideoEnd={onVideoEnded} />
    </Page>
  );
}
