export enum TwtAuthState {
  Idle,
  Checking,
  Authorized,
  Unauthorized,
}

export function twtAuthStateFinished(state: TwtAuthState) {
  return state === TwtAuthState.Authorized || state === TwtAuthState.Unauthorized;
}
