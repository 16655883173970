import amplitude, { AmplitudeClient } from 'amplitude-js';
import { UserAccount } from '@common/UserAccount';
import { environment } from '@environment';
import { AnalyticsService } from './AnalyticsService';
import { AnalyticsUserOptions } from './AnalyticsUserOptions';
import { AnalyticsEventOptions, AnalyticsShareResults } from './AnalyticsEventOptions';
import { AnalyticsEventName, ShareType } from './AnalyticsEventName';
import { debug } from '@common/LogWrapper';
import { removeUndefinedValues } from '@common/ObjectUtils';

const log = debug('app:services:AmpAnalyticsService');

type EventItem = {
  name: AnalyticsEventName;
  options?: AnalyticsEventOptions;
};

export class AmpAnalyticsService implements AnalyticsService {
  private client?: AmplitudeClient;

  private clientInitted = false;
  private isEntryFinalSent = false;
  private innerUserOptions: AnalyticsUserOptions = {};
  private persistedOptions: AnalyticsEventOptions = {};

  private eventQueue: EventItem[] = [];

  constructor() {
    if (!environment.ampKey?.length) {
      log('No amp key. amp disabled.');
      return;
    }
    this.client = amplitude.getInstance();
  }
  init(): void {
    if (!this.client) {
      return;
    }
    if (this.clientInitted) {
      return;
    }

    this.client.init(environment.ampKey, undefined, { apiEndpoint: environment.ampProxy || undefined }); // convert empty strings to undefined

    this.client.setUserProperties(this.innerUserOptions);

    // log queued up event pre-init
    for (const eventItem of this.eventQueue) {
      this.client.logEvent(eventItem.name, eventItem.options);
    }
    this.eventQueue = [];

    this.clientInitted = true;
    log('client initted');
  }
  setPersistedOptions(options?: AnalyticsEventOptions): void {
    this.persistedOptions = removeUndefinedValues({
      ...this.persistedOptions,
      ...options,
    });
  }
  setUserState(state: UserAccount): void {
    if (!this.client) {
      return;
    }

    this.userTrack({
      appName: 'mintingWebsite',
      walletId: state.address,
    });
  }
  userTrack(userOptions: AnalyticsUserOptions): void {
    if (!this.client) {
      return;
    }
    if (!this.isUserOptionsDiff(userOptions)) {
      return;
    }
    log('Amplitude: set user props', userOptions);

    this.innerUserOptions = {
      ...this.innerUserOptions,
      ...userOptions,
    };

    if (this.clientInitted) {
      this.client.setUserProperties(this.innerUserOptions);
    }
  }
  track(name: AnalyticsEventName, options?: AnalyticsEventOptions): void {
    if (!this.client) {
      return;
    }
    // entry final should be only sent once
    if (name === AnalyticsEventName.EntryFinal) {
      if (this.isEntryFinalSent) {
        return;
      }
      this.isEntryFinalSent = true;
      this.init(); // only init on entry final
    }
    const combined = {
      ...this.persistedOptions,
      ...options,
    };
    log('Amplitude: track event', name, combined);
    if (this.clientInitted) {
      this.client.logEvent(name, combined);
    } else {
      // queue up events for initting
      this.eventQueue.push({ name, options: combined });
    }
  }

  trackShareStatus(result: AnalyticsShareResults): void {
    switch (result.shareType) {
      case 'openShare':
        this.track(AnalyticsEventName.Share, {
          type: ShareType.NATIVE,
          success: true,
        });
        break;
      case 'tweetIntent':
        this.track(AnalyticsEventName.Share, {
          type: ShareType.TWITTER,
          success: true,
        });
        break;
      case 'tweetVideo':
        this.track(AnalyticsEventName.Share, {
          type: ShareType.TWITTER,
          success: true,
        });
        break;
      case 'aborted':
        // I don't thik this case actually works, never seen anything fall under here
        break; // do nothing
      case 'twitterAuthAborted':
        this.track(AnalyticsEventName.Share, {
          type: ShareType.USER_ABORT,
          success: false,
        });
        break;
    }
  }

  private isUserOptionsDiff(userOptions: AnalyticsUserOptions): boolean {
    for (const key of Object.keys(userOptions)) {
      if (!(key in this.innerUserOptions)) {
        return true;
      }
      if (this.innerUserOptions[key] !== userOptions[key]) {
        return true;
      }
    }
    return false;
  }
}
