import { debug } from './LogWrapper';
import { SaleService } from '../services/sale/SaleService';
import Sale from './Sale';
import { SaleState } from './SaleState';
import { getSaleDateFlagsFromTime, SaleDateFlag, salePrioWeightSorter, separateSaleDateFlags } from './SaleUtils';

const log = debug('app:common:SalePicker');

const checkOthersOnStates = [SaleState.OutOfSpots, SaleState.OutOfSupply, SaleState.None];

export async function salePrioWeightPicker(sales: Sale[], saleService: SaleService, time = Date.now()): Promise<{ sale: Sale | null; saleState: SaleState }> {
  const orderedSales = salePrioWeightSorter(sales);
  // cache
  const flagsMap: Record<string, { saleKind: SaleDateFlag; relative: SaleDateFlag }> = {};
  const saleStateMap: Record<string, SaleState> = {};
  for (const sale of orderedSales) {
    const saleState = await fetchSaleState(sale, saleService, time, flagsMap);
    // in case there are parallel sales,
    // skip OutOfStock/OutOfSpots to check others if they still have spots/supply left
    // unless sale is featured
    if (!sale.featured && checkOthersOnStates.includes(saleState)) {
      saleStateMap[sale.saleId] = saleState;
    } else {
      return { sale, saleState };
    }
  }

  // by this point, sales if any are out of stock so choose the earliest one with a stored sale state
  for (const sale of orderedSales) {
    const saleState = saleStateMap[sale.saleId];
    if (saleState) {
      return { sale, saleState };
    }
  }

  // by this point, there are no applicable sales
  return { sale: null, saleState: SaleState.None };
}

export async function fetchSaleState(
  sale: Sale,
  saleService: SaleService,
  time = Date.now(),
  separatedFlagsCache?: Record<string, { saleKind: SaleDateFlag; relative: SaleDateFlag }>,
): Promise<SaleState> {
  if (separatedFlagsCache && !separatedFlagsCache[sale.saleId]) {
    const saleDateFlags = getSaleDateFlagsFromTime(sale, time);
    separatedFlagsCache[sale.saleId] = separateSaleDateFlags(saleDateFlags);
  }
  const { saleKind, relative } = separatedFlagsCache?.[sale.saleId] ?? separateSaleDateFlags(getSaleDateFlagsFromTime(sale, time));
  if (relative === SaleDateFlag.Before) {
    // before
    return SaleState.Upcoming;
  } else if (relative === SaleDateFlag.During) {
    // TODO fetch something for collection

    // during
    if (saleKind === SaleDateFlag.WhitelistSignUp) {
      // whitelist signup
      if (sale.saleType === 'characterPass') {
        try {
          const spotsLeft = await saleService.fetchWhitelistRemainingSpots({ saleId: sale.saleId });
          if (spotsLeft > 0) {
            return SaleState.Open;
          } else {
            return SaleState.OutOfSpots;
          }
        } catch (e) {
          throw new Error(`Could not fetch waitlist remaining spots: ${e.message}`);
        }
      } else {
        return SaleState.Open;
      }
    } else if (saleKind === SaleDateFlag.WhitelistMint) {
      // whitelist mint
      if (sale.saleType === 'characterPass') {
        try {
          const remainingSupply = await saleService.fetchWhitelistMintRemainingSupply({ saleId: sale.saleId });
          if (remainingSupply > 0) {
            return SaleState.Open;
          } else {
            return SaleState.OutOfSupply;
          }
        } catch (e) {
          throw new Error(`Could not fetch waitlist remaining supply: ${e.message}`);
        }
      } else {
        return SaleState.Open;
      }
    } else if (saleKind === SaleDateFlag.PublicMint) {
      // public mint
      if (sale.saleType === 'characterPass') {
        try {
          const remainingSupply = await saleService.fetchPublicMintRemainingSupply({ saleId: sale.saleId });
          if (remainingSupply > 0) {
            return SaleState.Open;
          } else {
            // in case there are parallel sales,
            // skip this one to check others if the others still have spots/supply left
            return SaleState.OutOfSupply;
          }
        } catch (e) {
          throw new Error(`Could not fetch public remaining supply: ${e.message}`);
        }
      } else {
        return SaleState.Open;
      }
    }
  } else if (relative === SaleDateFlag.After) {
    // after
    return SaleState.Closed;
  }

  // somehow, no dates
  return SaleState.None;
}
