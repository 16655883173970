import React from 'react'

import './ShowcaseSection.styles.scss'
import { Section } from '@components/Section'
import { GradientTitle } from '@components/GradientTitle'

import WriteAnimateShareGif from '@assets/landing-page-v3/showcase/WriteAnimateShare.gif'
import StartCreatingImage from '@assets/landing-page-v3/showcase/StartCreating.gif'
import StartCreatingPoster from '@assets/landing-page-v3/showcase/StartCreating-poster.jpg'
import StartCreatingVid from '@assets/landing-page-v3/showcase/StartCreating.mp4'
import VideoPlayer from '@components/VideoPlayer'

export const ShowcaseSection = () => (
  <Section className='ShowcaseSection' id='showcase'>
    <div>
      <div>
        <p className='purpleTitle'>Made for anyone</p>
        <GradientTitle>
          Create. <br />
          Animate. <br />
          Share.
        </GradientTitle>

        <p className='description'>
          Our industry leading tools allow anyone to create a story. It’s like having an entire gaming and animation studio at your fingertips.
        </p>
      </div>

      <img src={WriteAnimateShareGif} alt='Create. Animate. Share.' loading='lazy' />
    </div>

    <div>
      {/* <img src={StartCreatingImage} alt='Start creating' loading='lazy' /> */}
      <VideoPlayer
        src={StartCreatingVid}
        poster={StartCreatingPoster}
        autoPlay
        muted
        preload='auto'
        loop
        playsInline
        controls={false}
      />

      <div>
        <p className='purpleTitle'>Creator app</p>
        <GradientTitle>
          Start <br />
          creating
        </GradientTitle>

        <p className='description'>
          Choose a character <br />
          Type the script <br />
          Set an expression <br />
          Angle the camera <br />
          Add choices <br />
          Share your story
        </p>
      </div>
    </div>
  </Section>
)
