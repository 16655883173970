import { useState } from 'react';
import { MockModal, ModalCloseCallback } from './MockModal';
import { waitForMs } from '../../common/WaitUtils';

import './MockTxScreen.scss';
import { environment } from '../../environments/environment';

export enum MockTxAction {
  Deny, Approve
}

export type MockTxCallback = (action: MockTxAction) => void;

function MockTxScreen({ callback, description }: { callback: MockTxCallback, description: string }): JSX.Element {
  const [approving, setApproving] = useState(false);

  function deny() {
    callback(MockTxAction.Deny);
  }

  async function approve() {
    setApproving(true);
    await waitForMs(environment.mockLoadingMs);
    callback(MockTxAction.Approve);
  }

  return (
    <div className="card-body">
      <p>{description}</p>
      <div className="d-flex">
        <button className="btn btn-danger flex-fill mx-2" onClick={deny} disabled={approving}>Deny</button>
        <button className="btn btn-success flex-fill mx-2" onClick={approve} disabled={approving}>
          {approving && <span className="spinner-border spinner-border-sm"></span>}
          Approve
        </button>
      </div>
    </div>
  );
}

export function createTxScreen(description: string, callback: MockTxCallback, closeCallback: ModalCloseCallback) {
  return new MockModal(
    'Transaction', MockTxScreen, { description },
    'mock-tx-screen', callback, closeCallback,
    environment.mockLoadingMs
  );
}

export function waitForTx(description: string): Promise<void> {
  return new Promise((resolve, reject) => {
    let txScreen;
    function callback(action: MockTxAction) {
      if (action === MockTxAction.Approve) {
        resolve();
      } else {
        reject(new Error('Denied transaction'));
      }
      txScreen.destroy();
    }
    function closeCallback() {
      reject(new Error('Closed transaction'));
      txScreen.destroy();
    }
    txScreen = createTxScreen(description, callback, closeCallback);
  });
}
