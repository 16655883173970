import './ItemTag.scss';

export type ItemTagProps = {
  type: 'live' | 'register';
};

export function ItemTag({ type }: ItemTagProps) {
  const caption = type == 'live' ? 'LIVE' : 'REGISTRATION OPEN';

  return (
    <div className="tag">
      <div className="bg" />
      <div className="tag-content">
        <div className={`icon ${type}`} />
        <div className="label">{caption}</div>
      </div>
    </div>
  );
}

export default ItemTag;
