import React from 'react'
import type { ComponentProps } from 'react'

import './ExternalLink.styles.scss'


type ExternalLinkProps = ComponentProps<'a'>

export const ExternalLink = ({
  children,
  className,
  ...others
}: ExternalLinkProps) => (
  <a className={`ExternalLink ${className ? className : ''}`} target='_blank' {...others}>
    {children}
  </a>
)
