
import './Founder.scss';

type FounderProps = {
  img: string,
  name: string,
  handle: string,
  desc: string,
}

export function Founder({img, name, handle, desc}: FounderProps) {
  return <div className="founder-container">
    <div className="founder-rect-top">

    </div>
    <div className="founder-picture"
      style={{ background: `url(${img})`, backgroundSize: 'contain'}} />
    <div className="founder-rect-bottom">
      <a className="founder-name" href={ 'https://twitter.com/' + handle } target='_blank' rel='noreferrer'>
        { '@' + handle }
      </a>
      <div className="founder-title">
        { name }
      </div>
      <div className="founder-desc">
        { desc }
      </div>
    </div>
  </div>
}
