import DOMPurify from 'dompurify';
import VideoPlayer from '@components/VideoPlayer';
import VimeoReactPlayer from '@components/vimeo-react-player/VimeoReactPlayer';
import { useMintState } from '@context/mint/MintContext';
import { useClassName } from '@hooks/useClassName';
import { CSSProperties, useEffect, useState } from 'react';
import './CollectionInfo.scss';

export type CollectionInfoProps = {};

function CollectionMedia({ className }: { className?: string }) {
  const { sale } = useMintState();
  const mediaClassName = useClassName('coll-info-video', className);

  const [style, setStyle] = useState<CSSProperties>();

  useEffect(() => {
    if (!sale?.saleMedia?.aspectRatio) {
      setStyle({});
    }

    setStyle({
      aspectRatio: sale.saleMedia.aspectRatio,
    });
  }, [sale?.saleMedia?.aspectRatio]);

  if (!sale?.saleMedia) {
    return null;
  }

  if (sale.saleMedia.directVideoUrl) {
    return (
      <VideoPlayer
        className={mediaClassName}
        src={sale.saleMedia.directVideoUrl}
        autoPlay
        muted
        preload="auto"
        loop
        playsInline
        controls={false}
        style={style}
      />
    );
  }

  if (sale.saleMedia.vimeoUrl) {
    const iframeProps = { style };
    return <VimeoReactPlayer className={mediaClassName} url={sale.saleMedia.vimeoUrl} autoPlayType="inview" loop iframeProps={iframeProps} />;
  }

  if (sale.saleMedia.imageUrl) {
    return <img className={mediaClassName} src={sale.saleMedia.imageUrl} alt={sale.saleName} style={style} />;
  }

  return null;
}

export function CollectionInfo({}: CollectionInfoProps) {
  const { sale } = useMintState();
  const title = sale?.saleName;
  const description = DOMPurify.sanitize(sale?.saleDescription);
  const minted = `${0} Minted`;

  const contents: JSX.Element[] = [];

  // <div className="coll-info-minted-counter">{minted}</div>
  if (sale?.contractLink) {
    contents.push(
      <a href={sale.contractLink} target="_blank">
        Link to Contract
      </a>,
    );
  }

  // const classNames = useClassName('coll-info', className);
  return (
    <div className="coll-info">
      <CollectionMedia />
      <div className="coll-info-title">{title}</div>
      {Boolean(contents.length) && <div className="coll-info-minted">{contents}</div>}
      <div className="coll-info-description" dangerouslySetInnerHTML={{ __html: description }}></div>
    </div>
  );
}

export default CollectionInfo;
