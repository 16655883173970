import { MouseEvent, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { loginStateInProgress } from '@common/LoginState';
import { hasProvider } from '@common/ProviderUtils';
import { SearchParam } from '@common/SearchParam';
import { Alert } from '@components/alerts/Alert';
import { useUserState } from '@services/user/UserContext';
import { useUserService } from '@hooks/useUserService';
import { Page } from '../Page';

import './LoginPage.scss';

export function LoginPage() {
  const userState = useUserState();
  const userService = useUserService();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  function logIn(e?: MouseEvent<HTMLAnchorElement>) {
    e?.preventDefault();
    if (!hasProvider()) { return; }
    userService.logIn();
  }

  useEffect(() => {
    if (loginStateInProgress(userState.loginState)) {
      return;
    }
    if (userState.loggedIn) {
      // using OR for falsy empty strings
      navigate(params.get(SearchParam.Redirect) || '/'); 
    }
  }, [userState, navigate, params]);

  useEffect(() => {
    // prevent login attempt if a window pop up is required, which are blocked
    // when not called via a mouse event.
    if (!userService.logInPopUp) {
      logIn();
    }
  }, []);

  return (
    <Page title="Login" className="login container py-2">
      <Alert.Warning>
        {hasProvider() ? (
          <>
            This page requires you to be logged in. Please <a href="#" onClick={logIn}>click here</a> to log in.
          </>
        ) : (
          <>
            This page requires you to be logged in. Please install a Web3 provider like MetaMask to login.
          </>
        )}
      </Alert.Warning>
    </Page>
  );
}
