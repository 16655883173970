import { ChainalysisService } from './ChainalysisService';
import { pipelineApiCall, PipelineEndpoint } from '@common/SvsRestApi';

export class RestChainalysisService implements ChainalysisService {
  async isWalletAddressSafe(walletAddress: string): Promise<boolean> {
    return true;
    // disabled for now because we're spamming this method somehow
    // const response = await pipelineApiCall({
    //   method: 'GET',
    //   endpoint: PipelineEndpoint.Chainalysis,
    //   tokens: { walletAddress },
    // });

    // return response.status;
  }
}
