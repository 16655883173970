import { Action, generateStoreContext } from '@context/GenerateContext';
import { UserAccount, UserAccountLike } from '@common/UserAccount';
import { UserActionType } from './UserActionType';
import { UserPermission } from '@common/UserPermission';
import { noChainId } from '@common/ProviderUtils';
import { Wallet } from '@storyverseco/svs-navbar';
import { LoginState } from '@common/LoginState';

const initialState: UserAccount = {
  caSafe: true,
  chainId: noChainId,
  address: null,
  name: null,
  permissions: [],
  loggedIn: false,
  wallet: null,
  loginState: LoginState.Idle,
};

export type UserActionProps =
  | {
      type: UserActionType.UpdateCASafe;
      caSafe: boolean;
    }
  | {
      type: UserActionType.UpdateChainId;
      chainId: number;
    }
  | {
      type: UserActionType.UpdateAddress;
      address: string | null;
    }
  | {
      type: UserActionType.UpdateName;
      name: string | null;
    }
  | {
      type: UserActionType.UpdateWallet;
      wallet: Wallet | null;
    }
  | {
      type: UserActionType.UpdatePermissions;
      permissions: UserPermission[];
    }
  | {
      type: UserActionType.AddPermissions;
      permissions: UserPermission[];
    }
  | {
      type: UserActionType.RemovePermissions;
      permissions: UserPermission[];
    }
  | {
      type: UserActionType.UpdateLoggedIn;
      loggedIn: boolean;
    }
  | {
      type: UserActionType.UpdateLoginState;
      loginState: LoginState;
    }
  | {
      type: UserActionType.LogOut;
    }
  | (UserAccountLike & {
      type: UserActionType.LogIn;
    });

function createFilterUnwantedPerms(unwantedPerms: UserPermission[]) {
  return (perm: UserPermission) => unwantedPerms.indexOf(perm) === -1;
}

function reducer(state: UserAccount, action: Action<UserActionType, UserActionProps>): UserAccount {
  let permissions: UserPermission[], packs: number[], index: number;
  switch (action.type) {
    // case UserActionType.UpdateAll:
    //   const allState = { ...state, ...action };
    //   delete allState.type;
    //   return allState;
    case UserActionType.UpdateCASafe:
      return {
        ...state,
        caSafe: action.caSafe,
      };
    case UserActionType.UpdateChainId:
      return {
        ...state,
        chainId: action.chainId,
      };
    case UserActionType.UpdateAddress:
      return {
        ...state,
        address: action.address,
      };
    case UserActionType.UpdateName:
      return {
        ...state,
        name: action.name,
      };
    case UserActionType.UpdateWallet:
      return {
        ...state,
        wallet: action.wallet,
      };
    case UserActionType.UpdatePermissions:
      return {
        ...state,
        permissions: action.permissions,
      };
    case UserActionType.AddPermissions:
      if (!action.permissions) {
        throw new Error(`Missing 'permissions' property in '${action.type}' action.`);
      }
      if (!Array.isArray(action.permissions)) {
        throw new Error(`Invalid value in 'permissions' property in '${action.type}' action.`);
      }
      permissions = state.permissions || [];

      permissions = permissions.concat(
        action.permissions.filter(createFilterUnwantedPerms(permissions)), // avoid duplicates
      );
      return {
        ...state,
        permissions,
      };
    case UserActionType.RemovePermissions:
      if (!action.permissions) {
        throw new Error(`Missing 'permissions' property in '${action.type}' action.`);
      }
      if (!Array.isArray(action.permissions)) {
        throw new Error(`Invalid value in 'permissions' property in '${action.type}' action.`);
      }
      permissions = state.permissions || [];
      permissions = permissions.filter(createFilterUnwantedPerms(action.permissions));
      return {
        ...state,
        permissions,
      };
    case UserActionType.UpdateLoggedIn:
      return {
        ...state,
        loggedIn: action.loggedIn,
      };
    case UserActionType.UpdateLoginState:
      return {
        ...state,
        loginState: action.loginState,
      };
    case UserActionType.LogOut:
      return {
        caSafe: true,
        chainId: noChainId,
        address: null,
        name: null,
        wallet: {
          storyNFTs: {},
          nfts: {},
          flags: {
            loggedIn: false,
            founder: false,
            storyNFTEditor: false,
          },
        },
        permissions: [],
        loggedIn: false,
        loginState: LoginState.LoggedOut,
      };
    case UserActionType.LogIn:
      if (!('caSafe' in action)) {
        throw new Error(`Missing "caSafe" property in "${action.type}" action.`);
      }
      return {
        caSafe: action.caSafe,
        chainId: action.chainId ?? noChainId,
        address: action.address,
        name: action.name,
        wallet: action.wallet,
        permissions: action.permissions || [],
        loggedIn: true,
        loginState: LoginState.LoggedIn,
      };
    default:
      throw new Error(`Unknown action type: "${(action as any).type}"`);
  }
}

const { StateContext, DispatchContext, ContextProvider, ContextConsumer, withContext, useContextState, useContextDispatch } = generateStoreContext(
  reducer,
  initialState,
  'userState',
  'userDispatch',
);

export {
  StateContext as UserStateContext,
  DispatchContext as UserDispatchContext,
  ContextProvider as UserProvider,
  ContextConsumer as UserConsumer,
  withContext as withUser,
  useContextState as useUserState,
  useContextDispatch as useUserDispatch,
  UserActionType,
};
