import { useEffect, useState } from 'react';
import './CountdownInline.scss';
import { useClassName } from '@hooks/useClassName';
import { DeltaParts, min2Digits, parseCountdownToDate } from '@common/CountdownUtils';

export type CountdownInlineProps = React.HTMLProps<HTMLDivElement> & {
  targetTime: number;
  done?: () => void;
};

export function CountdownInline({ targetTime, done, className, ...props }: CountdownInlineProps) {
  const [deltaParts, setDeltaParts] = useState<DeltaParts | null>(null);
  const classNames = useClassName('svs-countdown-inline', className);

  useEffect(() => {
    if (Date.now() > targetTime) {
      return;
    }

    const now = Date.now();
    setDeltaParts(parseCountdownToDate(targetTime, now));

    const intervalId = setInterval(() => {
      const now = Date.now();
      const delta = targetTime - now;
      setDeltaParts(parseCountdownToDate(targetTime, now));
      if (delta <= 0) {
        clearInterval(intervalId);
        done?.();
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [targetTime]);

  if (!deltaParts) {
    return <div className={classNames}>...</div>;
  }

  const showDays = deltaParts.days > 0;
  const showHours = showDays || deltaParts.hours > 0;
  const showMinutes = showHours || deltaParts.minutes > 0;

  return (
    <div className={classNames}>
      {showDays && (
        <span className="days">
          {min2Digits(deltaParts.days)} {deltaParts.days === 1 ? 'day' : 'days'} &nbsp;
        </span>
      )}
      {showHours && (
        <span className="hours">
          {min2Digits(deltaParts.hours)} {deltaParts.hours === 1 ? 'hour' : 'hours'} &nbsp;
        </span>
      )}
      {showMinutes && (
        <span className="minutes">
          {min2Digits(deltaParts.minutes)} {deltaParts.minutes === 1 ? 'min' : 'mins'} &nbsp;
        </span>
      )}
      <span className="seconds">
        {min2Digits(deltaParts.seconds)} {deltaParts.seconds === 1 ? 'sec' : 'secs'} &nbsp;
      </span>
    </div>
  );
}
