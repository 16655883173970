import logo from '../../assets/logo.svg';
import './Web3Unavailable.scss';


export function Web3Unavailable() {
  return (
    <>
      <a id='overlay' href={`https://metamask.app.link/dapp/${location.hostname}${location.pathname}`}>
        Web3 unavailable
      </a>
      <div id='container'>
        <img id='logo' src={logo} />
        <h2>Metamask Required<br/>Tap to Continue</h2>
      </div>
    </>
  );
}
