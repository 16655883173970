import AvatarImg from './avatar.png';
import ThumbImg from './thumb.png';

export default {
  avatar: AvatarImg,
  thumb: ThumbImg,
  author: 'Rionna Morgan',
  url: '/chasingshadows',
  footnote: 'Rionna Morgan, best-selling author, Author of The 7 Love Stories NFTs.',
};
