import React, { createRef, useEffect, useState } from 'react'

import './RoadmapSection.styles.scss'
import { Section } from '@components/Section'
import { CheckmarkSvg } from './CheckmarkSvg'
import GradientTitle from '@components/GradientTitle'

import CreatorApp from '@assets/landing-page-v3/roadmap/CreatorApp.png'
import NFTIntegration from '@assets/landing-page-v3/roadmap/NFTIntegration.png'
import AdditionalFeatures from '@assets/landing-page-v3/roadmap/AdditionalFeatures.png'
import INK from '@assets/landing-page-v3/roadmap/INK.png'

export const RoadmapSection = () => {
  const RoadmapProgressRef = createRef<HTMLSpanElement>()
  const InnerRoadmapProgressRef = createRef<HTMLSpanElement>()
  
  const checkmarks = [
    {
      id: '0',
      outer: createRef<SVGSVGElement>(),
      gradientProgress: createRef<SVGStopElement>(),
      checkmark: createRef<SVGPathElement>()
    },
    {
      id: '1',
      outer: createRef<SVGSVGElement>(),
      gradientProgress: createRef<SVGStopElement>(),
      checkmark: createRef<SVGPathElement>()
    },
    {
      id: '2',
      outer: createRef<SVGSVGElement>(),
      gradientProgress: createRef<SVGStopElement>(),
      checkmark: createRef<SVGPathElement>(),
      disabled: true
    },
    {
      id: '3',
      outer: createRef<SVGSVGElement>(),
      gradientProgress: createRef<SVGStopElement>(),
      checkmark: createRef<SVGPathElement>(),
      disabled: true
    }
  ]


  const scrollHandler = () => {
    if (!RoadmapProgressRef.current) {
      return
    }
    if (!InnerRoadmapProgressRef.current) {
      return
    }

    const bounds = RoadmapProgressRef.current.getBoundingClientRect()
    const windowHeight = screen.height

    const inner = InnerRoadmapProgressRef.current


    inner.style.height = `calc(${windowHeight - bounds.y}px - ${windowHeight * .4}px)`

    checkmarks
      .filter((checkmark) => !checkmark.disabled)
      .forEach((checkmark) => {
        const outerBounds = checkmark.outer.current.getBoundingClientRect()
        let progress = (windowHeight * .6 - outerBounds.y) / outerBounds.height

        if(progress < 0) {
          progress = 0
        } else if(progress > 1) {
          progress = 1
        }

        // Gradient background animation
        checkmark.gradientProgress.current.setAttribute('offset', progress.toString())

        // Checkmark animation
        const checkmarkTotalPathLength = checkmark.checkmark.current.getTotalLength()
        const checkmarkVisiblePathLength = checkmarkTotalPathLength * progress

        checkmark.checkmark.current.style.strokeDasharray = checkmarkTotalPathLength.toString()
        checkmark.checkmark.current.style.strokeDashoffset = (checkmarkTotalPathLength - checkmarkVisiblePathLength).toString()

        // SVG growing animation
        checkmark.outer.current.style.transform = `scale(${1 + progress})`
      })
  }


  // Use Intersection Observer to add/remove event listeners
  // for progress bar resizing and check mark animations
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [ entry ] = entries

      if(entry.isIntersecting) {
        window.addEventListener('resize', scrollHandler) // Resizing can also change the amount visible on the viewport
        window.addEventListener('scroll', scrollHandler)
      } else {
        window.removeEventListener('resize', scrollHandler)
        window.removeEventListener('scroll', scrollHandler)
      }
    })

    if(RoadmapProgressRef.current) {
      observer.observe(RoadmapProgressRef.current)
    }

    return () => {
      window.removeEventListener('resize', scrollHandler)
      window.removeEventListener('scroll', scrollHandler)

      if(RoadmapProgressRef.current) {
        observer.unobserve(RoadmapProgressRef.current)
      }
    }
  }, [ RoadmapProgressRef ])


  return (
    <Section className='RoadmapSection' id='roadmap'>
      <GradientTitle>Roadmap</GradientTitle>

      <div className='RoadmapWrapper'>
        <span className='RoadmapProgressOuter' ref={RoadmapProgressRef}>
          <span className='RoadmapProgressInner' ref={InnerRoadmapProgressRef} />
        </span>

        <div className='RoadmapElement'>
          <img src={CreatorApp} alt='Creator App' />

          <CheckmarkSvg {...checkmarks[0]} />

          <div>
            <h1 className='active'>Creator App</h1>
            <p>We launched an easy to use app users can create stories with.</p>
          </div>
        </div>

        <div className='RoadmapElement'>
          <img src={NFTIntegration} alt='New NFT Integrations' />

          <CheckmarkSvg {...checkmarks[1]} />

          <div>
            <h1 className='active'>New NFT Integration</h1>
            <p>We’re continually working on adding new NFT projects into Storyverse.</p>
          </div>
        </div>

        <div className='RoadmapElement'>
          <img src={AdditionalFeatures} alt='Additional Features' />

          <CheckmarkSvg {...checkmarks[2]} />

          <div>
            <h1>Additional Features & Improvements</h1>
            <p>Features like changing your stories background or even a character's wardrobe are coming soon. Concepts including Proof Of Choice and more.</p>
          </div>
        </div>

        <div className='RoadmapElement'>
          <img src={INK} alt='INK' />

          <CheckmarkSvg {...checkmarks[3]} />

          <div>
            <h1>INK</h1>
            <p>As Storyverse’s digital asset and governance token, INK will provide a way for creators to monetize their stories. </p>
          </div>
        </div>
      </div>

      <p className='moreToComeText'>More to come...</p>
    </Section>
  )
}
