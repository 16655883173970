import { SvsBridge, SvsWalletService, SvsWriterApi } from '@storyverseco/svs-navbar';
import { ApiType } from '../ApiType';
import { BaseSuite } from './BaseSuite';
import { registerSuite } from './Suite';
import { environment } from '@environment';
import { SaleService } from '../sale/SaleService';

class NavbarSuite extends BaseSuite {
  readonly apiType = ApiType.Navbar;

  private navbarBridge: SvsBridge;
  private _saleService: SaleService;

  get navbarService(): SvsBridge {
    if (!this.navbarBridge) {
      this.navbarBridge = new SvsBridge({
        persistStoryServerPrefix: environment.persistStoryServerPrefix,
        serviceProxyUrl: environment.serviceProxyUrl,
        plotsBaseURI: environment.plotsBaseURI,
        persistPlotServerPrefix: environment.persistPlotServerPrefix,
        foundersPassContract: environment.foundersPassContract,
        walletService: this.mainSuite.walletService,
        writersApi: this.mainSuite.writerService,
        // Doing this because the type in the navbar needs to be updated
        saleApi: this.mainSuite.saleService as any,
        templateApiPrefix: environment.templateApiPrefix,
        assetsApiPrefix: environment.assetsApiPrefix,
        chainIds: environment.chainIds,
        walletConnectProjectId: environment.walletConnectId,
        walletConnectConnectorVersion: environment.walletConnectorVersion,
        pipelineBaseUrl: environment.pipelineBaseUrl,
        authBaseUrl: environment.authBaseUrl,
        mediaServiceBaseUrl: environment.mediaServiceBaseUrl,
        mbaasBaseUrl: environment.mbaasBaseUrl,
        mbaasApiKey: environment.mbaasApiKey,
      });
    }
    return this.navbarBridge;
  }

  get walletService(): SvsWalletService {
    return null; // use navbar's default wallet service
  }

  get writerService(): SvsWriterApi {
    return null; // use navbar's default writer service
  }
}

export const navbarSuite = new NavbarSuite();
registerSuite(navbarSuite);
