import './MintBox.scss';
import { useCountdownUntilEnd } from '@src/pages/mint-v2/components/CollectionsMint';

// This has been split out so it doesnt cause the parent component to update every frame (counter)
export const MintBoxFootnote = () => {
  const { rawCountdownText } = useCountdownUntilEnd();

  return <div className="Row Footnote">{rawCountdownText}</div>;
};
// Keeping this around in case we want to put it back
//  &nbsp;&nbsp; • &nbsp;&nbsp; 14 / 250 minted
