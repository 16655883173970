import { Spinner } from '@components/spinner/Spinner';

export function TransitioningSection() {
  return (
    <div className="transitioning-section py-5">
      <Spinner />
    </div>
  );
}

export default TransitioningSection;
