import './MintCollectionLayout.scss';
import { useClassName } from '@hooks/useClassName';
import React from 'react';
import { TextNewLiner } from '@components/text-new-liner/TextNewLiner';

export type MintCollectionLayoutProps = {
  title: string;
  subtitle: string;
  showDates?: boolean;
  children?: React.ReactNode;
  className?: string;
};

export function MintCollectionLayout({ title, subtitle, showDates, className, children }: MintCollectionLayoutProps) {
  const classNames = useClassName('coll-layout', className);
  return (
    <div className={classNames}>
      <header className="coll-layout-header">
        <div>
          <h1 className="coll-layout-title coll-gradient-title">
            <TextNewLiner text={title} />
          </h1>
          <p className="coll-layout-subtitle">{subtitle}</p>
        </div>
      </header>
      <div className="coll-layout-body">{children}</div>
    </div>
  );
}

export default MintCollectionLayout;
