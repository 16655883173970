import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import type { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { SvsProvider } from '@storyverseco/svs-navbar';

import { Burger } from '../Burger';
import { AppActionType, useAppDispatch, useAppState } from '../../context/AppContext';

import './Header.styles.scss';

import Logo from '@assets/landing-page-v3/header/logo.svg';
import ToggleArrow from '@assets/landing-page-v3/header/toggleArrow.svg';
import Discord from '@assets/landing-page-v3/logos/Discord.svg';
import Twitter from '@assets/landing-page-v3/logos/Twitter.svg';
import OpenSea from '@assets/landing-page-v3/logos/OpenSea.svg';
import { useClassName } from '../../hooks/useClassName';
import { useUserHook } from '@hooks/useUserHook';
import { mainSuite } from '@services/ServiceFactory';

function AboutLink({ headerOpened }: { headerOpened: boolean }) {
  const appState = useAppState();

  const [headerDropdownOpened, setHeaderDropdownOpened] = useState(false);
  const HeaderDropdownRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (!HeaderDropdownRef.current) {
      return;
    }
    if (window.innerWidth > 699) {
      return;
    }

    if (headerDropdownOpened) {
      HeaderDropdownRef.current.style.height = `${HeaderDropdownRef.current.scrollHeight}px`;
      HeaderDropdownRef.current.parentElement.classList.add('open');
    } else {
      HeaderDropdownRef.current.style.height = null;
      HeaderDropdownRef.current.parentElement.classList.remove('open');
    }
  }, [headerDropdownOpened]);

  useEffect(() => {
    if (!headerOpened) {
      setHeaderDropdownOpened(false);
    }
  }, [headerOpened]);

  if (!appState.features.showWhitepaperLink && !appState.features.showMapLink && !appState.features.showContactsSection) {
    return <a href={'/about#about'}>About</a>;
  }

  return (
    <span className="DropdownLink">
      <span onClick={() => setHeaderDropdownOpened(!headerDropdownOpened)}>
        About <img src={ToggleArrow} />
      </span>

      <div className="HeaderDropdown" ref={HeaderDropdownRef}>
        {appState.features.showWhitepaperLink && <Link to={'/whitepaper'}>Whitepaper</Link>}
        {appState.features.showMapLink && <Link to={'/maps'}>Maps</Link>}
        {appState.features.showContactsSection && <a href={'/about#contact'}>Contact</a>}
      </div>
    </span>
  );
}

const HOLD_THRESHOLD_MS = 7000; // 7s
let longPressInterval;

export function Header() {
  const appDispatch = useAppDispatch();
  const appState = useAppState();
  const { wallet, ready, logOut, logIn } = useUserHook({ providerType: SvsProvider.WalletConnect });
  const [opened, setOpened] = useState(false);
  const className = useClassName('Header', opened && 'open', appState.fixedNavbar && 'fixed');

  const headerClicked = (event: MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;

    if (!['NAV', 'DIV'].includes(target.nodeName)) {
      return;
    }

    setOpened(false);
  };

  const isConnected = useMemo(() => ready && Boolean(wallet?.flags.loggedIn), [wallet]);

  const onConnectBtnPress = useCallback(async () => {
    if (isConnected) {
      await logOut();
    } else {
      await logIn();
    }
    setOpened(false);
  }, [isConnected, logIn, logOut]);

  const onLogoPress = () => {
    longPressInterval = setTimeout(() => {
      appDispatch({
        type: AppActionType.UpdateShowSecretMenu,
        show: true,
      });
    }, HOLD_THRESHOLD_MS);
  };

  const onLogoRelease = () => {
    clearInterval(longPressInterval);
  };

  return (
    <nav className={className} onClick={headerClicked}>
      <Link to={'/'} onMouseDown={onLogoPress} onMouseUp={onLogoRelease}>
        <img src={Logo} alt="Storyverse Logo" className={'Logo'} />
      </Link>

      <div className={'LinkContainer'}>
        {/* <Link to={'/'}>Create</Link> */}
        {/* <AboutLink headerOpened={opened} /> */}
        {/* <a href={'/about#roadmap'}>Roadmap</a> */}
        {/* <a href={'/about#faq'}>FAQ</a> */}

        <div className="IconContainer">
          <a href={'https://discord.com/invite/storyverse'} target="_blank">
            <img src={Discord} alt="Discord" />
          </a>
          <a href={'https://twitter.com/storyverse_xyz'} target="_blank">
            <img src={Twitter} alt="Twitter" />
          </a>
          <a href={'https://opensea.io/collection/storyverse-founders-pass'} target="_blank">
            <img src={OpenSea} alt="OpenSea" />
          </a>
        </div>
        <div>
          <button className="svs-btn" onClick={onConnectBtnPress}>
            {isConnected ? 'Disconnect' : 'Connect'}
          </button>
        </div>
      </div>

      <Burger className={'Burger'} opened={opened} onClick={() => setOpened(!opened)} />
    </nav>
  );
}
