import React from 'react'

import './TeamSection.styles.scss'
import { Section } from '@components/Section'
import { GradientTitle } from '@components/GradientTitle'

import { TeamMembers } from './TeamMembers.data'
import { ProfilePictures } from '@assets/landing-page-v3/team'

export const TeamSection = () => (
  <Section className='TeamSection'>
    <GradientTitle>
      The Team
    </GradientTitle>

    <div className='TeamHeader'>
      {
        TeamMembers.head.map((member) => (
          <div className='TeamMember' key={member.name + member.role}>
            <img src={ProfilePictures[member.name]} alt={`${member.name}'s profile picture'`} loading='lazy' />
            <GradientTitle>{member.name}</GradientTitle>
            <p>{member.role}</p>
          </div>
        ))
      }
    </div>
    <div className='TeamList'>
      {
        TeamMembers.normal.map((member) => (
          <div className='TeamMember' key={member.name + member.role}>
            <img src={ProfilePictures[member.name]} alt={`${member.name}'s profile picture'`} loading='lazy' />
            <GradientTitle>{member.name}</GradientTitle>
            <p>{member.role}</p>
          </div>
        ))
      }
    </div>
  </Section>
)
