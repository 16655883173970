import './Banner.scss';

import ImgBanner from '../../../assets/homepage/banner-clean.png';
import ImgBAYC from '../../../assets/homepage/bayc.png';
import ImgMAYC from '../../../assets/homepage/mayc.png';
import { GradientTitle } from '@components/GradientTitle';

export type BannerProps = {};

function clickBanner(e: React.MouseEvent, id: string) {
  e.stopPropagation();
  console.warn('>>> clicking on item', id);
}

function clickBannerButton(e: React.MouseEvent, id: string) {
  e.stopPropagation();
  console.warn('>>> clicking on item button', id);
}

export function Banner({}: BannerProps) {
  return (
    <div className="banner" style={{ backgroundImage: `url(${ImgBanner})` }}>
      <div className="interactive">
        <a className="left" href="/bayc">
          <img src={ImgBAYC} />
        </a>
        <a className="right" href="/mayc">
          <img src={ImgMAYC} />
        </a>
      </div>
      {/** */}
      <div className="content">
        <GradientTitle className="title">BAYC / MAYC Holders</GradientTitle>
        <GradientTitle className="message">{`Add YOUR ape to Storyverse\nBe in stories written by famous authors\nEarn royalties`}</GradientTitle>

        <div className="buttons">
          <p>JOIN HERE</p>
          <button type="submit" className="button-blue" aria-label="Submit" onClick={(e) => clickBannerButton(e, 'bayc')}>
            BAYC
          </button>
          <button type="submit" className="button-blue" aria-label="Submit" onClick={(e) => clickBannerButton(e, 'mayc')}>
            MAYC
          </button>
        </div>

        <GradientTitle className="footer">Join over 700+ Apes already in Storyverse, FREE!</GradientTitle>
      </div>
      {/**/}
    </div>
  );
}

export default Banner;
