import EventEmitter from 'eventemitter3';
import {
  ChoiceOpts,
  StoryResult,
  SvsGetWalletOpts,
  SvsNavbarEvent,
  SvsSaveDraftOpts,
  SvsSavePlotTitleOpts,
  SvsWalletService,
  GetNFTStoryOpts,
  Wallet,
  ChangeSvsProviderOpts,
  IsNFTStoryOwnerResult,
  SvsStoryBaseOpts,
} from '@storyverseco/svs-navbar';
import { MockStorageService } from '../mock/MockStorageService';
import { Assets, S3Template, StoryIndex } from '@storyverseco/svs-types';
import { IStory } from '@storyverseco/svs-story-suite';
import type { EthereumClient } from '@web3modal/ethereum';

const walletServiceKey = 'walletService';

export class MockWalletService extends EventEmitter<SvsNavbarEvent> implements SvsWalletService {
  loggedIn: boolean;
  initted: boolean;

  constructor(private mockService: MockStorageService) {
    super();
  }
  isNFTStoryOwner(opts: SvsStoryBaseOpts): Promise<IsNFTStoryOwnerResult> {
    throw new Error('Method not implemented.');
  }
  getWagmiClient?() {
    throw new Error('Method not implemented.');
  }
  getW3MEthereumClient?(): EthereumClient {
    throw new Error('Method not implemented.');
  }
  changeSvsProvider(opts: ChangeSvsProviderOpts): Promise<string> {
    throw new Error('Method not implemented.');
  }
  getSvsProvider(): Promise<string> {
    throw new Error('Method not implemented.');
  }
  getNFTStory(GetNFTStoryOpts: any): Promise<any> {
    throw new Error('Method not implemented.');
  }
  recordChoice(opts: ChoiceOpts): Promise<StoryResult> {
    throw new Error('Method not implemented.');
  }
  randomReveal(opts: ChoiceOpts): Promise<StoryResult> {
    throw new Error('Method not implemented.');
  }

  getNFTStoryForStoryWriter(opts: GetNFTStoryOpts): Promise<IStory> {
    throw new Error('Method not implemented.');
  }
  saveNftStoryForWriter(opts: SvsSaveDraftOpts): Promise<StoryResult> {
    throw new Error('Method not implemented.');
  }

  init(): Promise<void> {
    try {
    } catch (e) {}
    throw new Error('Method not implemented.');
  }
  waitForInit(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  signUp(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  logIn(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  logOut(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  isWeb3Available(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  getWallet(optsOrForce?: boolean | SvsGetWalletOpts): Promise<Wallet> {
    throw new Error('Method not implemented.');
  }
  saveDraft(optsOrTokenId: string | SvsSaveDraftOpts, data?: string | { [key: string]: any; saveCount?: number }, force?: boolean): Promise<StoryResult> {
    throw new Error('Method not implemented.');
  }
  authorizeChoiceCreation(payload: Record<string, any>): Promise<any> {
    throw new Error('Method not implemented.');
  }
  authorizeOptionMinting(payload: Record<string, any>): Promise<any> {
    throw new Error('Method not implemented.');
  }
  savePlotTitle(optsOrTokenId: string | SvsSavePlotTitleOpts, title?: string): Promise<{ title: string }> {
    throw new Error('Method not implemented.');
  }

  saveStory(): Promise<StoryResult> {
    throw new Error('Method not implemented.');
  }
  saveStoryForShare(): Promise<StoryResult> {
    throw new Error('Method not implemented.');
  }
  getTemplatesFor(): Promise<Record<string, S3Template>> {
    throw new Error('Method not implemented.');
  }

  getAvailableAssets(): Promise<Assets> {
    throw new Error('Method not implemented.');
  }
  sendTransaction(tx: any): Promise<any> {
    throw new Error('Method not implemented.');
  }
  getTransactionReceipt(txHash: string | Promise<string>): Promise<any> {
    throw new Error('Method not implemented.');
  }
  signSession(): Promise<any> {
    throw new Error('Method not implemented.');
  }
}
