import * as cfg from '@storyverseco/svs-config-client';
import { environment } from '@environment';

let config: cfg.ConfigClient;

export const getConfig = async () => {
  if (!config) {
    config = cfg.stage(environment.production ? 'prod' : 'dev');
    if (config.promise) {
      config = await config.promise;
    }
  }
  return config;
};
