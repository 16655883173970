export enum LoadState {
  Idle = 'idle',
  Loading = 'loading',
  Loaded = 'loaded',
  Errored = 'errored',
}

export const finishedLoadStates = [LoadState.Loaded, LoadState.Errored];

export const inProgressLoadStates = [LoadState.Loading];

// helper condition functions for condition waiter

export function loadStateFinished(loadState: LoadState): boolean {
  return finishedLoadStates.includes(loadState);
}

export function loadStateInProgress(loadState: LoadState): boolean {
  return inProgressLoadStates.includes(loadState);
}
