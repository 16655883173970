import { SvsCurrentOwnerWriterOpts, SvsFetchWriterOpts, SvsWriterApi, SvsWriterOpts } from '@storyverseco/svs-navbar';
import { MockStorageService } from '../mock/MockStorageService';

export class MockWriterService implements SvsWriterApi {
  constructor(mockService: MockStorageService) {}

  addWriter(opts: SvsWriterOpts): Promise<{ owner: string; writers: { address: string }[] }> {
    throw new Error('Method not implemented.');
  }
  removeWriter(opts: SvsWriterOpts): Promise<{ owner: string; writers: { address: string }[] }> {
    throw new Error('Method not implemented.');
  }
  fetchWriters(opts: SvsFetchWriterOpts): Promise<{ owner: string; writers: { address: string }[] }> {
    throw new Error('Method not implemented.');
  }
  isCurrentOwner(opts: SvsCurrentOwnerWriterOpts): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
}
