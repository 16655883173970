import React, { useMemo } from 'react';
import { useClassName } from '../../hooks/useClassName';
import './SvsInputText.scss';

const allowedInputTypes = [
  'text',
  'date',
  'datetime-local',
  'email',
  'number',
  'password',
  'search',
  'url',
  'time',
  'week',
] as const;

export type SvsInputTextProps = React.HTMLProps<HTMLInputElement> & {
  iconUrl: string,
  type: typeof allowedInputTypes[number],
};


export function SvsInputText({
  iconUrl,
  id,
  className,
  style,
  type,
  ...inputProps
}: SvsInputTextProps) {
  if (!allowedInputTypes.includes(type)) {
    throw new Error('Invalid svs input type');
  }

  const classNames = useClassName('svs-input-text', className);
  const iconStyle = useMemo(() => ({
    backgroundImage: `url(${iconUrl})`,
  }), [iconUrl]);

  return <div id={id} className={classNames} style={style}>
    <input type={type} style={iconStyle} {...inputProps} />
  </div>;
}
