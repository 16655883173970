import { useMemo } from 'react';
import { Params, useParams } from 'react-router-dom';

/**
 * Convert react router params to search param items (array of key-value objects)
 * @param params React router params
 * @param mapping Mapping from react router param to search param key
 * @returns An array of key-value objects
 */
export function paramsToSearchParams(params: Readonly<Params<string>>, mapping: Record<string, string>) {
  return Object.entries(mapping)
    .filter(([from, _to]) => !!params[from])
    .map(([from, to]) => ({ key: to, value: params[from] }));
}

/**
 * Convert react router params to search param items (array of key-value objects)
 * @param mapping Mapping from react router param to search param key
 * @returns An array of key-value objects
 */
export function useParamsToSearchParams(mapping: Record<string, string>) {
  const params = useParams();
  const paramItems = useMemo(() => paramsToSearchParams(params, mapping), [params, mapping]);
  return paramItems;
}
