import { isTpToken, TokenproofService, TpAccount, TpReason, TpStatus, TpToken } from './TokenproofService';
import { environment } from '@environment';
import { waitForExpectedValue, waitForMs } from '@common/WaitUtils';
import { LoginState, loginStateFinished, loginStateInProgress } from '@common/LoginState';
import { ConditionWaiter } from '@common/ConditionWaiter';
import { safeLocalStorage } from '@common/SafeLocalStorage';
import { daysToMs, hoursToMs, minutesToMs } from '@common/NumberUtils';
import { LoadState } from '@common/LoadState';
import { AnalyticsService } from '../analytics/AnalyticsService';
import { SaleService } from '../sale/SaleService';
import { AnalyticsEventName, AnalyticsUsageName } from '../analytics/AnalyticsEventName';
import { debug } from '@common/LogWrapper';
import * as Sentry from '@sentry/react';
import { MintPageState } from '@context/mint/MintContext';
import { pipelineApiCall, PipelineEndpoint } from '@common/SvsRestApi';

const log = debug('app:services:RestTokenproofService');
const tpLocalStorageSessionKey = 'svs.mainsite.tp.s:1.0.0';
const tpExpiresIn = minutesToMs(5);
const tpExpireMaxRetries = 1;
const tpRetryOnExpire = false;

declare global {
  const tokenproof: any;
}

type TpLoginResult = {
  status: string;
  reason: string;
  nonce: string;
};

type TpPipelineHolding = {
  account: string;
  contract: string;
  tokens: TpToken[];
};

type TpPipelineResult = {
  nonce: string;
  status: string;
  account: string;
  accounts: string[];
  timestamp: string;
  session_id: string;
  holdings: TpPipelineHolding[];
};

type TpSession = {
  policyId?: string | null;
  lastVerified: string;
  tpResult: TpPipelineResult;
};

function isTpPipelineHolding(obj: any): obj is TpPipelineHolding {
  return (
    obj &&
    typeof obj === 'object' &&
    typeof obj.account === 'string' &&
    typeof obj.contract === 'string' &&
    Array.isArray(obj.tokens) &&
    obj.tokens.every(isTpToken)
  );
}

function isTpPipelineResult(obj: any): obj is TpPipelineResult {
  return (
    obj &&
    typeof obj === 'object' &&
    typeof obj.nonce === 'string' &&
    typeof obj.status === 'string' &&
    typeof obj.account === 'string' &&
    Array.isArray(obj.accounts) &&
    obj.accounts.every((item: any) => typeof item === 'string') &&
    typeof obj.timestamp === 'string' &&
    typeof obj.session_id === 'string' &&
    Array.isArray(obj.holdings) &&
    obj.holdings.every(isTpPipelineHolding)
  );
}

function isTpSession(obj: any): obj is TpSession {
  return (
    obj &&
    typeof obj === 'object' &&
    (!obj.policyId || typeof obj.policyId === 'string') &&
    typeof obj.lastVerified === 'string' &&
    isTpPipelineResult(obj.tpResult)
  );
}

// null and undefined should be treated the same in this case
function arePolicyIdsSame(a: any, b: any): boolean {
  return (!a && !b) || (typeof a === 'string' && typeof b === 'string' && a === b);
}

function createEmptyAccount(address: string): TpAccount {
  return {
    address,
    tokens: {},
  };
}

function parseAuthResult(result: TpPipelineResult): {
  mainAccount: TpAccount;
  accountMap: Record<string, TpAccount>;
  nonce: string;
  status: TpStatus | string;
} | null {
  if (!result.account) {
    log('No account from pipeline tp backend result');
    return null;
  }

  // parse account map
  const accountMap: Record<string, TpAccount> = {};
  if (result.accounts?.length) {
    for (const resultAccount of result.accounts) {
      const address = resultAccount.toLowerCase();
      accountMap[address] = createEmptyAccount(address);
    }
  }
  if (result.holdings?.length) {
    for (const resultHolding of result.holdings) {
      const address = resultHolding.account.toLowerCase();
      const contract = resultHolding.contract.toLowerCase();

      // this.accountMap[address] ??= createEmptyAccount(address);
      if (!accountMap[address]) {
        accountMap[address] = createEmptyAccount(address);
      }

      // this.accountMap[address].tokens[contract] ??= [];
      if (!accountMap[address].tokens[contract]) {
        accountMap[address].tokens[contract] = [];
      }

      if (resultHolding.tokens?.length) {
        accountMap[address].tokens[contract].push(...resultHolding.tokens);
      }
    }
  }

  // main account
  const mainAccountAddress = result.account.toLowerCase();
  const mainAccount = accountMap[mainAccountAddress] ?? createEmptyAccount(mainAccountAddress);
  const nonce = result.nonce;
  const status = result.status;

  return { mainAccount, accountMap, nonce, status };
}

function isInitted(loadState: LoadState): boolean {
  return loadState === LoadState.Loaded;
}

function isValidDate(d: any): boolean {
  return d instanceof Date && !isNaN(d.getTime());
}

export class RestTokenproofService implements TokenproofService {
  private policyId: string = null;
  private nonce: string = null;
  private status: TpStatus | string = TpStatus.Idle;
  private reason: TpReason | string = TpReason.Idle;

  private loginWaiter = new ConditionWaiter(LoginState.Idle, loginStateFinished);
  private initWaiter = new ConditionWaiter(LoadState.Idle, isInitted);

  private mainAccount: TpAccount = null;
  private accountMap: Record<string, TpAccount> = {};

  constructor(private analyticsService: AnalyticsService, private saleService: SaleService) {}

  async init(opts?: { fromLogin?: boolean }) {
    if (this.initted) {
      return;
    }

    if (this.initWaiter.get() === LoadState.Loading) {
      await this.initWaiter.wait();
      return;
    }
    this.initWaiter.set(LoadState.Loading);

    // determine if we should retry on expire. tpRetryOnExpire is an overal override
    const retryOnExpire = (opts?.fromLogin ?? false) && tpRetryOnExpire;

    // load from local storage
    await this.initParseAccountsFromStorage({ retryOnExpire });

    if (this.mainAccount) {
      this.loginWaiter.set(LoginState.LoggedIn);
    } else {
      this.loginWaiter.set(LoginState.LoggedOut);
    }

    this.initWaiter.set(LoadState.Loaded);
  }

  isLoggedIn(): boolean {
    return this.loginState === LoginState.LoggedIn;
  }

  getAccount(address?: string): TpAccount | null {
    if (address) {
      return this.accountMap[address] ?? null;
    }
    return this.mainAccount;
  }

  getAccountMap(): Record<string, TpAccount> {
    return this.accountMap;
  }

  getNonce() {
    return this.nonce;
  }

  getStatus(): string | null {
    return this.status;
  }

  getReason(): string | null {
    return this.reason;
  }

  getAnalyticsUsage(mintPageState: MintPageState): AnalyticsUsageName {
    let analyticsUsage: AnalyticsUsageName;

    switch (mintPageState) {
      case MintPageState.PreWhitelist:
      case MintPageState.Whitelist:
      case MintPageState.WhitelistChecking:
      case MintPageState.WhitelistChecked:
      case MintPageState.WhitelistNoSpots:
        analyticsUsage = 'whitelist';
        break;
      case MintPageState.PreMint:
      case MintPageState.Mint:
      case MintPageState.MintEntry:
      case MintPageState.MintShare:
      case MintPageState.Minting:
      case MintPageState.Minted:
      case MintPageState.MintNoSupply:
      case MintPageState.PostMint:
        analyticsUsage = 'mint';
        break;
      default:
        analyticsUsage = 'unknown';
        break;
    }

    return analyticsUsage;
  }

  /**
   * This is safe to call multiple times, it won't duplicate fetches during the login process
   * @param opts
   * @returns
   */
  async login(opts?: {
    appId?: string;
    env?: string;
    force?: boolean;
    mintPageState?: MintPageState;
    tokenType?: string;
    policyId?: string;
  }): Promise<TpAccount | null> {
    if (!this.initted) {
      await this.init({ fromLogin: true });
    }

    if (!opts?.force && this.loginState === LoginState.LoggedIn && arePolicyIdsSame(opts?.policyId, this.policyId)) {
      return this.mainAccount;
    }
    if (loginStateInProgress(this.loginState)) {
      await this.loginWaiter.wait();
      return this.mainAccount;
    }
    const params = {
      usage: this.getAnalyticsUsage(opts?.mintPageState ?? MintPageState.Idle),
      tokenType: opts.tokenType,
    };
    this.analyticsService.track(AnalyticsEventName.TokenproofStart, params);
    this.mainAccount = null;
    this.policyId = opts?.policyId;
    this.status = TpStatus.Idle;
    this.reason = TpReason.Idle;
    this.accountMap = {};
    this.loginWaiter.set(LoginState.LoggingIn);
    await this.initNonceFromLogin(opts);

    const verified = this.verifyStatus();
    if (!verified) {
      log('invalid after verifying');
      this.analyticsService.track(AnalyticsEventName.TokenproofError, {
        status: this.status,
        reason: this.reason,
        ...params,
      });
      this.loginWaiter.set(LoginState.LoggedOut);
      return null;
    }

    await this.initAccountsFromNonceIntoStorage();
    await this.initParseAccountsFromStorage({ expectedPolicyId: this.policyId });

    if (this.mainAccount) {
      this.loginWaiter.set(LoginState.LoggedIn);
      this.analyticsService.track(AnalyticsEventName.TokenproofSuccess, params);
      Sentry.setContext('tokenproof', {
        wallet: this.mainAccount.address,
        nonce: this.nonce,
        status: this.status,
        reason: this.reason,
        policyId: this.policyId,
      });
    } else {
      this.analyticsService.track(AnalyticsEventName.TokenproofError, {
        status: this.status,
        reason: this.reason,
        ...params,
      });
      Sentry.setContext('tokenproof', {
        wallet: this.mainAccount?.address,
        nonce: this.nonce,
        status: this.status,
        reason: this.reason,
        policyId: this.policyId,
      });
      this.loginWaiter.set(LoginState.LoggedOut);
    }
    return this.mainAccount;
  }

  async logout(): Promise<void> {
    this.status = TpStatus.Idle;
    this.reason = TpReason.Idle;
    this.mainAccount = null;
    this.accountMap = {};
    this.loginWaiter.set(LoginState.LoggedOut);
    safeLocalStorage.removeItem(tpLocalStorageSessionKey);
  }

  private async initNonceFromLogin(opts?: { appId?: string; env?: string; force?: boolean; policyId?: string }) {
    try {
      console.log('Opts', opts);
      const result = (await tokenproof.login({
        appId: opts?.appId ?? environment.tokenproofAppId,
        env: opts?.env ?? environment.tokenproofEnv,
        policy: opts?.policyId,
      })) as TpLoginResult;
      log('tokenproof result:', result);
      this.status = result.status;
      this.reason = result.reason;
      this.nonce = result.nonce ?? null;
    } catch (err) {
      log('Error occurred during tokenproof login:', err);
      this.status = TpStatus.Unknown;
      this.reason = TpReason.Unknown;
    }
  }

  private async initAccountsFromNonceIntoStorage() {
    if (!this.nonce) {
      throw new Error('tokenproof service: Missing nonce');
    }
    const result = await pipelineApiCall({
      method: 'GET',
      endpoint: PipelineEndpoint.Tokenproof,
      tokens: {
        nonce: this.nonce,
      },
      retryOpts: {
        maxRetries: 3,
        retryDelay: 1000,
        retryCallback(reason, retries, maxRetries) {
          log(`initAccountsFromNonceIntoStorage: failed. retrying...(${retries}/${maxRetries}) (${reason?.message ?? reason})`);
        },
      },
    });

    if (!isTpPipelineResult(result)) {
      log('initAccountsFromNonceIntoStorage: invalid data format');
      safeLocalStorage.removeItem(tpLocalStorageSessionKey);
      return;
    }

    const session: TpSession = {
      lastVerified: new Date().toISOString(),
      tpResult: result,
      policyId: this.policyId,
    };
    const sessionStr = JSON.stringify(session);
    safeLocalStorage.setItem(tpLocalStorageSessionKey, window.btoa(sessionStr));
  }

  private getSessionfromStorage(): TpSession | null {
    const sessionStrB64 = safeLocalStorage.getItem(tpLocalStorageSessionKey);
    if (!sessionStrB64) {
      return null;
    }

    let sessionStr: string;
    try {
      sessionStr = window.atob(sessionStrB64);
    } catch (e) {
      log('invalid 64 string from stored session text:', sessionStr, e.message);
      return null;
    }

    let session: unknown;
    try {
      session = JSON.parse(sessionStr);
    } catch (e) {
      log('invalid json from stored session text:', sessionStr, e.message);
      return null;
    }

    if (!isTpSession(session)) {
      log('invalid session format:', sessionStr);
      return null;
    }

    return session;
  }

  private initStatusFromStorage() {
    const session = this.getSessionfromStorage();
    if (session) {
      this.status = session.tpResult.status;
    }
  }

  private verifyStatus() {
    if (this.status === TpStatus.Rejected) {
      log(`Rejected (${this.status}, ${this.reason})`);
      return false;
    }
    if (this.status === TpStatus.Closed) {
      log('User canceled');
      return false;
    }
    if (this.status !== TpStatus.Authenticated) {
      log('Unexpected status:', this.status, this.reason);
      return false;
    }

    return true;
  }

  private async initParseAccountsFromStorage(opts?: { retryOnExpire?: boolean; currentTries?: number; expectedPolicyId?: string }) {
    const session = this.getSessionfromStorage();
    if (!session) {
      return;
    }
    const result = session.tpResult;
    if (!result) {
      return;
    }

    const lastVerifiedStr = session.lastVerified;

    let lastVerified: Date = undefined;
    if (lastVerifiedStr) {
      lastVerified = new Date(lastVerifiedStr);
    }
    if (!isValidDate(lastVerified)) {
      log('Not a valid last verified date');
    }

    if (!lastVerified) {
      if (result.timestamp) {
        lastVerified = new Date(result.timestamp);
      } else {
        log('No last verified date or timestamp in original result');
        return;
      }
    }

    if (!isValidDate(lastVerified)) {
      log('Not a valid last verified date (from original result)');
      return;
    }

    if (opts && 'expectedPolicyId' in opts && !arePolicyIdsSame(opts.expectedPolicyId, session.policyId)) {
      log(`Policy ID not the same. Expected: "${opts.expectedPolicyId}"; Session: "${session.policyId}"`);
      return;
    }

    const accountsData = parseAuthResult(result);

    if (!accountsData) {
      log('invalid auth result');
      safeLocalStorage.removeItem(tpLocalStorageSessionKey);
      return;
    }

    this.nonce = accountsData.nonce;
    this.policyId = session.policyId;

    log('lastVerified', lastVerified);

    if (Date.now() >= lastVerified.getTime() + tpExpiresIn) {
      const retryOnExpire = opts?.retryOnExpire ?? tpRetryOnExpire;
      const currentTries = opts?.currentTries ?? 0;
      if (retryOnExpire && currentTries < tpExpireMaxRetries) {
        log('Expired. Trying to get new data from existing nonce');
        await this.initAccountsFromNonceIntoStorage();
        this.initStatusFromStorage();
        const verified = this.verifyStatus();
        if (verified) {
          await this.initParseAccountsFromStorage({ retryOnExpire: opts?.retryOnExpire, currentTries: currentTries + 1 });
        } else {
          log('Expired or rejected.');
          safeLocalStorage.removeItem(tpLocalStorageSessionKey);
        }
      } else {
        log('Expired.');
        this.nonce = null;
        if (retryOnExpire) {
          // only clear if we retried already
          safeLocalStorage.removeItem(tpLocalStorageSessionKey);
        }
      }
      return;
    }

    this.mainAccount = accountsData.mainAccount;
    this.accountMap = accountsData.accountMap;
  }

  private get loginState() {
    return this.loginWaiter.get();
  }

  private get initted() {
    return isInitted(this.initWaiter.get());
  }
}
