import { AppActionType, useAppDispatch, useAppState } from '@context/AppContext';
import './SecretMenu.scss';

export const SecretMenu = () => {
  const appDispatch = useAppDispatch();
  const { showSecretMenu } = useAppState();

  if (!showSecretMenu) {
    return null;
  }

  const hideSecretMenu = () => {
    appDispatch({
      type: AppActionType.UpdateShowSecretMenu,
      show: !showSecretMenu,
    });
  };

  return (
    <div className="SecretMenu">
      <div className="f header">
        <div className="f title">
          <h1>Secret Menu</h1>
        </div>
        <div className="f close">
          <button onClick={hideSecretMenu}>X</button>
        </div>
      </div>
      <div className="f content">{/*insert content here*/}</div>
    </div>
  );
};
