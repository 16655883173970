import { EmailSignUpSection } from './EmailSignUpSection';

export function PremintSignUpSection({
  setShowSpinner,
}: {
  setShowSpinner: (show: boolean) => void,
}) {
  return (
    <EmailSignUpSection title="Waitlist Signup" setShowSpinner={setShowSpinner} />
  )
}
