import { useEffect, useState } from 'react';
import { useClassName } from '../../hooks/useClassName';
import VideoPlayer from '../VideoPlayer';
import loadingVideoSrc from '@assets/create/OpeningVideoCreate_1mbs.mp4';
import loadingVideoPoster from '@assets/create/OpeningVideoCreate_1mbs.jpg';
import './LoadingVideoOverlay.scss';
import { LoadingVideo, StaticLoadingVideo } from '@storyverseco/svs-navbar';

export type LoadingVideoOverlayProps = {
  loop?: boolean;
  show?: boolean;
  video?: LoadingVideo | string | null;
  onVideoEnd?: () => void;
  nftVideoSettings?: VideoSettings;
};

type VideoSettings = {
  src: string;
  poster?: string;
};

const videoMap: Record<StaticLoadingVideo, VideoSettings> = {
  [StaticLoadingVideo.Default]: {
    src: loadingVideoSrc,
    poster: loadingVideoPoster,
  },
};

export function LoadingVideoOverlay({ show, loop, video, onVideoEnd, nftVideoSettings }: LoadingVideoOverlayProps) {
  if (!video) {
    video = StaticLoadingVideo.Default;
  }

  const [showVideoDom, setShowVideoDom] = useState(true);
  const [videoRef, setVideoRef] = useState<HTMLVideoElement>(null);
  const overlayClassName = useClassName('loading-video-overlay', show && 'show');

  const staticVideoSettings = videoMap[video] ?? videoMap[StaticLoadingVideo.Default];
  const videoSettings = nftVideoSettings || staticVideoSettings;

  useEffect(() => {
    if (!videoRef) {
      return;
    }

    function onVideoEnded() {
      onVideoEnd?.();
    }

    // note: "ended" only fires if not looping
    videoRef.addEventListener('ended', onVideoEnded, false);

    return () => {
      videoRef.removeEventListener('ended', onVideoEnded, false);
    };
  }, [videoRef]);

  useEffect(() => {
    if (show === showVideoDom) {
      // already showing or hidden
      return;
    }

    // mismatch means it needs to transition, handled via css
    if (showVideoDom && !show) {
      // transition event listeners are unreliable, so we use timeout
      const timeoutId = setTimeout(() => {
        setShowVideoDom(false);
      }, 500); // matches transition duration in css

      return () => clearTimeout(timeoutId);
    } else {
      setShowVideoDom(true);
    }
  }, [show, showVideoDom, setShowVideoDom]);

  return (
    (showVideoDom && videoSettings && (
      <div className={overlayClassName}>
        <VideoPlayer src={videoSettings.src} poster={videoSettings.poster} autoPlay muted loop={loop} preload="auto" playsInline ref={setVideoRef} />
      </div>
    )) ||
    null
  );
}

export default LoadingVideoOverlay;
