import { createRef } from 'react'
import type { Ref } from 'react'


export const CheckmarkSvg = ({
  id,

  outer,
  gradientProgress,
  checkmark,
  disabled
}: {
  id: string,
  outer: Ref<SVGSVGElement>
  gradientProgress: Ref<SVGStopElement>
  checkmark: Ref<SVGPathElement>,
  disabled?: boolean
}) => (
  <svg viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg' className='svgCheckmark' ref={outer}>
    <path d='M21.1283 0.0898438C17.1365 0.0898437 13.2343 1.27356 9.91526 3.4913C6.59618 5.70904 4.00927 8.86119 2.48166 12.5492C0.954058 16.2371 0.554367 20.2953 1.33313 24.2104C2.1119 28.1255 4.03415 31.7218 6.85679 34.5444C9.67944 37.3671 13.2757 39.2893 17.1908 40.0681C21.106 40.8468 25.1641 40.4472 28.8521 38.9196C32.54 37.3919 35.6922 34.805 37.9099 31.486C40.1277 28.1669 41.3114 24.2647 41.3114 20.2729C41.3114 14.92 39.185 9.78637 35.3999 6.00131C31.6148 2.21626 26.4812 0.0898438 21.1283 0.0898438Z' fill={`url(#checkmark_gradient_${id})`} />
    
    {!disabled && <path d='M11 19.7723L18.2083 26.9806L31.1917 14' stroke='white' strokeWidth='3' ref={checkmark} />}
    
    <defs>
      <linearGradient id={`checkmark_gradient_${id}`} gradientTransform='rotate(90)'>
        <stop offset='0' stopColor='#6347F6' />

        {!disabled && <stop stopColor='#2B30F5' offset='0' ref={gradientProgress} />}
        <stop offset='0' stopColor='#403E4E' />
      </linearGradient>
    </defs>
  </svg>
)
