import { SvsBridge, SvsWalletService, SvsWriterApi } from '@storyverseco/svs-navbar';
import { AdminService } from '../admin/AdminService';
import { AnalyticsService } from '../analytics/AnalyticsService';
import { ApiType } from '../ApiType';
import { ChainalysisService } from '../chainalysis/ChainalysisService';
import { EmailSubService } from '../emailsub/EmailSubService';
import { MetadataService } from '../metadata/MetadataService';
import { SaleService } from '../sale/SaleService';
import { TokenproofService } from '../tokenproof/TokenproofService';
import { UserService } from '../user/UserService';

export interface Suite {
  readonly apiType?: ApiType;

  readonly userService: UserService;
  readonly adminService: AdminService;
  readonly metadataService: MetadataService;
  readonly analyticsService: AnalyticsService;
  readonly chainalysisService: ChainalysisService;
  readonly navbarService: SvsBridge;
  readonly walletService: SvsWalletService;
  readonly writerService: SvsWriterApi;
  readonly saleService: SaleService;
  readonly tokenproofService: TokenproofService;
  readonly emailSubService: EmailSubService;

  mainSuite?: Suite;
}

const suites: {
  [key in ApiType]?: Suite;
} = {};

let mainSuite: Suite;

export function registerSuite(suite: Suite): void {
  if (!suite.apiType) {
    throw new Error('Missing apiType while registering suite');
  }
  if (suites[suite.apiType]) {
    throw new Error(`Suite type "${suite.apiType}" already registered`);
  }
  suites[suite.apiType] = suite;
  if (mainSuite) {
    suite.mainSuite = mainSuite;
  }
}

export function getSuite(apiType: ApiType): Suite {
  if (!suites[apiType]) {
    throw new Error(`No suite registered with type "${apiType}"`);
  }
  return suites[apiType];
}

export function setMainSuite(suite: Suite): void {
  mainSuite = suite;
  for (const s of Object.values(suites)) {
    s.mainSuite = mainSuite;
  }
}

export function getMainSuite(): Suite {
  return mainSuite;
}
