import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { ModalRootId } from '../../App';

import './ModalPortal.scss';

export const ModalCloseButton = (
  {onClick}: {onClick?: React.MouseEventHandler<HTMLDivElement>}
) => <div className="modal-portal-close-button" onClick={onClick}/>;

export const ModalPortal = ({children}: {children: React.ReactNode}) => {
  const el = useRef<HTMLDivElement>();
  if (!el.current) {
    el.current = document.createElement('div');
  }

  useEffect(() => {
    const modalRoot = document.getElementById(ModalRootId);
    modalRoot.appendChild(el.current);
    // hide scrollbar on expand
    document.body.classList.add('overflow-hidden');
    return () => {
      modalRoot.removeChild(el.current)
      // show scrollbar again
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  return ReactDOM.createPortal(children, el.current);
}
