import React from 'react'

import './FaqSection.styles.scss'
import { Section } from '@components/Section'
import { GradientTitle } from '@components/GradientTitle'
import { Accordion } from '@components/Accordion'
import ExternalLink from '@components/ExternalLink'

export const FaqSection = () => (
  <Section className='FaqSection' id='faq'>
    <GradientTitle>
      Frequently <br />
      Asked <br />
      Questions
    </GradientTitle>

    <Accordion label={'What is Storyverse?'}>
      Storyverse is a project that allows users to create, animate, and share stories with their NFTs. It’s like having an entire game and animation studio at your fingertips. Easy to use tools are made available so you can focus on creating.
    </Accordion>
    <Accordion label={'How do I create a story?'}>
      Jump on the site and start creating!
    </Accordion>
    <Accordion label={'What is a Founders Pass?'}>
      These are Storyverse’s NFTs. Holders get: <br />
      &#11088;️ Early access to new features <br />
      &#11088; Future airdrops <br />
      &#11088; Exclusive perks <br />
      &#11088; Whitelist opportunities
    </Accordion>
    <Accordion label={'When is the mint?'}>
      Our first PLOT sale sold out within minutes in April, 2022. Future mints have yet to be announced. Stay up to date with our <ExternalLink href='https://twitter.com/storyverse_xyz'>Twitter</ExternalLink> and <ExternalLink href='https://discord.com/invite/storyverse'>Discord</ExternalLink>!
    </Accordion>
    <Accordion label={'What is the total supply?'}>
      10,000. Currently only 3,000 have been claimed. The other 7,000 have yet to be announced.
    </Accordion>
    <Accordion label={'What’s the utility?'}>
      Storyverse allows for anyone to create and share stories with their NFTs. You get access to creator tools that require zero coding. Updates to the creator app are made weekly and the team continues to add new features. See our <ExternalLink href='/#roadmap' target='_self'>Roadmap</ExternalLink> for some of the upcoming features we are bringing to Storyverse.
    </Accordion>
    <Accordion label={'Can I animate my own NFT?'}>
      Currently, you can edit stories with NFTs that the team owns. You can check them out on <ExternalLink href='https://opensea.io/collection/storyverse-plots'>OpenSea</ExternalLink>. We are currently working with different projects to allow their NFTs to join Storyverse.
    </Accordion>

    <p>Have another question? Join our <ExternalLink href='https://discord.com/invite/storyverse'>Discord</ExternalLink> and ask us!</p>
  </Section>
)
