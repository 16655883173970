import { SvsNavbarEvent, SvsProviderType } from '@storyverseco/svs-navbar';
import { useCallback, useEffect, useState } from 'react';
import { useUserState } from '@services/user/UserContext';
import useUserService from './useUserService';
import { debug } from '@common/LogWrapper';
import { ConditionWaiter } from '@common/ConditionWaiter';
import { LoadState, loadStateFinished, loadStateInProgress } from '@common/LoadState';
import { mainSuite } from '@services/ServiceFactory';

const log = debug('app:hooks:useUserHook');
const providerChangeWarningThreshold = 500; // ms

const inittedWaiter = new ConditionWaiter(LoadState.Idle, loadStateFinished);
const providerChangeWaiter = new ConditionWaiter(LoadState.Idle, loadStateFinished);
let currentProviderType: SvsProviderType | null = null;

let lastProviderChangeTime = 0;

async function init(): Promise<LoadState> {
  if (loadStateInProgress(inittedWaiter.get())) {
    await inittedWaiter.wait();
  }
  if (loadStateFinished(inittedWaiter.get())) {
    return;
  }

  const { navbarService } = mainSuite;
  navbarService.on(SvsNavbarEvent.ProviderTypeChanged, (providerType) => {
    currentProviderType = providerType;
  });

  currentProviderType = await navbarService.api.getSvsProvider();

  inittedWaiter.set(LoadState.Loaded);
  providerChangeWaiter.set(LoadState.Loaded);

  return inittedWaiter.get();
}

export function useUserHook({ providerType }: { providerType: SvsProviderType }) {
  const userService = useUserService();
  const userState = useUserState();
  const [initted, setInitted] = useState(loadStateFinished(inittedWaiter.get()));
  const [providerReady, setProviderReady] = useState(loadStateFinished(providerChangeWaiter.get()));
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (initted) {
      return;
    }

    init().then((loadState) => {
      log('loadState:', LoadState[loadState]);
      setInitted(loadStateFinished(loadState));
    });
  }, [initted]);

  useEffect(() => {
    if (providerType === currentProviderType) {
      return;
    }

    setProviderReady(false);

    // in case change is already in progress
    inittedWaiter
      .wait()
      .then(() => providerChangeWaiter.wait())
      .then(() => {
        if (providerType === currentProviderType) {
          return currentProviderType;
        } else {
          log(`Changing provider from "${currentProviderType}" to "${providerType}"`);
          if (Date.now() - lastProviderChangeTime <= providerChangeWarningThreshold) {
            console.warn(`Rapid provider type change detected (changing from "${currentProviderType}" to "${providerType}")`);
          }
          lastProviderChangeTime = Date.now();
          return userService.changeSvsProvider({ providerType });
        }
      })
      .then(() => {
        setProviderReady(true);
      })
      .catch((e) => {
        log('svs provider type error:', e);
      });
  }, [providerType]);

  useEffect(() => {
    function onWCError(error: Error) {
      setError(error);
    }
    mainSuite.navbarService.on(SvsNavbarEvent.LegacyWCError, onWCError);
    return () => {
      mainSuite.navbarService.off(SvsNavbarEvent.LegacyWCError, onWCError);
    };
  }, []);

  const logIn = useCallback<typeof userService.logIn>(userService.logIn.bind(userService), []);
  const logOut = useCallback<typeof userService.logOut>(userService.logOut.bind(userService), []);

  return {
    logIn,
    logOut,
    wallet: userState.wallet,
    loginState: userState.loginState,
    loggedIn: userState.loggedIn,
    userState,
    initted,
    providerReady,
    ready: initted && providerReady,
    error,
  };
}
