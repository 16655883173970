import AvatarImg from './avatar.png';
import ThumbImg from './thumb.png';

export default {
  avatar: AvatarImg,
  thumb: ThumbImg,
  author: 'Troy Caylak',
  url: '/fezlove',
  footnote: "Turhan Caylak, 2-time SAG nominee, stars in HBO's Barry.",
};
