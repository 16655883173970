import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { loginStateInProgress } from '@common/LoginState';
import { SearchParam } from '@common/SearchParam';
import { useUserState } from '@services/user/UserContext';
import { useUserService } from '@hooks/useUserService';
import { Page } from '../Page';

import './LoginPage';

export function LogoutPage() {
  const userState = useUserState();
  const userService = useUserService();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  useEffect(() => {
    if (loginStateInProgress(userState.loginState)) {
      return;
    }
    if (!userState.loggedIn) {
      const redirect = params.get(SearchParam.Redirect);
      // using OR for falsy empty strings
      navigate(redirect || '/');
    } else {
      userService.logOut();
    }
  }, [userState, userService, navigate, params]);

  return (
    <Page title="Log Out" className="logout container py-2">
      <p>Logging out...</p>
    </Page>
  );
}
