import baseDebug from 'debug';
import * as Sentry from '@sentry/react';

type ReducedArgs = {
  messages: string[];
  errors: Error[];
};

function isPlainObject(value: any) {
  return value?.constructor === Object;
}

function argToString(arg: any): string {
  if (isPlainObject(arg)) {
    try {
      return JSON.stringify(arg);
    } catch (e) {
      return '[unstringifiable object]';
    }
  }

  if (Array.isArray(arg)) {
    return `Array([ ${arg.map(argToString).join(', ')} ])`;
  }

  const type = typeof arg;
  if (type === 'number') {
    if (isNaN(arg)) {
      return 'NaN';
    }

    // in case toString was overridden and threw an error
    try {
      return String(arg);
    } catch (e) {
      return '[unstringable number]';
    }
  }

  // add quotes around string
  if (type === 'string') {
    return `"${arg}"`;
  }

  if (type === 'bigint') {
    // in case bigint wasn't stringable for whatever reason
    try {
      return `${String(arg)}n`;
    } catch (e) {
      return '[unstringable bigint]';
    }
  }

  let str: string;
  try {
    str = String(arg);
  } catch (e) {
    str = '[unstringable value]';
  }

  return str;
}

function argReducer({ messages, errors }: ReducedArgs, arg: any): ReducedArgs {
  if (arg instanceof Error) {
    return {
      messages: [...messages, `[${arg.name || 'Error'}: ${arg.message}]`],
      errors: [...errors, arg],
    };
  }

  return {
    messages: [...messages, argToString(arg)],
    errors,
  };
}

/**
 * Wrapper for debug logger. Automatically captures messages and errors/exceptions to Sentry
 * with appropriate severity levels. Also ensures logs are being captured regardless of
 * console visibility.
 * @param logger The logger function that debug() generates.
 * @returns A wrapped logger.
 */
export function logWrapper(logger: baseDebug.Debugger): baseDebug.Debugger {
  const wrappedLogger = (...args: Parameters<baseDebug.Debugger>) => {
    if (Array.isArray(args)) {
      const reducedArgs = args.reduce<ReducedArgs>(argReducer, { messages: [], errors: [] });
      const message = `${logger.namespace}: ${reducedArgs.messages.join(' ')}`;
      Sentry.addBreadcrumb({
        message,
        level: reducedArgs.errors.length ? 'error' : 'debug',
      });
      for (const error of reducedArgs.errors) {
        Sentry.captureException(error);
      }
    }
    logger(...args);
  };
  // passthrough
  Object.defineProperties(wrappedLogger, {
    color: {
      set: (value) => (logger.color = value),
      get: () => logger.color,
    },
    diff: {
      set: (value) => (logger.diff = value),
      get: () => logger.diff,
    },
    namespace: {
      set: (value) => (logger.namespace = value),
      get: () => logger.namespace,
    },
  });
  wrappedLogger.log = logger.log?.bind(logger);
  wrappedLogger.extend = logger.extend?.bind(logger);
  wrappedLogger.destroy = logger.destroy?.bind(logger);

  return wrappedLogger as baseDebug.Debugger;
}

function createDebug(): baseDebug.Debug {
  const debug = (namespace: string) => logWrapper(baseDebug(namespace));

  // passthroughs
  debug.coerce = baseDebug.coerce?.bind(baseDebug);
  debug.disable = baseDebug.disable?.bind(baseDebug);
  debug.enable = baseDebug.enable?.bind(baseDebug);
  debug.enabled = baseDebug.enabled?.bind(baseDebug);
  debug.formatArgs = baseDebug.formatArgs?.bind(baseDebug);
  debug.log = baseDebug.log?.bind(baseDebug);
  debug.selectColor = baseDebug.selectColor?.bind(baseDebug);
  debug.humanize = baseDebug.humanize?.bind(baseDebug);

  // passthroughs
  Object.defineProperties(debug, {
    names: {
      get: () => baseDebug.names,
      set: (value) => (baseDebug.names = value),
    },
    skips: {
      get: () => baseDebug.skips,
      set: (value) => (baseDebug.skips = value),
    },
    formatters: {
      get: () => baseDebug.formatters,
      set: (value) => (baseDebug.formatters = value),
    },
  });

  return debug as baseDebug.Debug;
}

export const debug = createDebug();

export default logWrapper;
