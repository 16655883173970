import { useEffect, useState } from 'react';
import { DeltaParts, min2Digits, parseCountdownToDate } from '@common/CountdownUtils';

export function useCountdownText(targetTime: number) {
  const [deltaParts, setDeltaParts] = useState<DeltaParts | null>(null);

  useEffect(() => {
    if (Date.now() > targetTime) {
      return;
    }

    const now = Date.now();
    setDeltaParts(parseCountdownToDate(targetTime, now));

    const intervalId = setInterval(() => {
      const now = Date.now();
      const delta = targetTime - now;
      setDeltaParts(parseCountdownToDate(targetTime, now));
      if (delta <= 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [targetTime]);

  if (!deltaParts) {
    return '...';
  }

  const showDays = deltaParts.days > 0;
  const showHours = showDays || deltaParts.hours > 0;
  const showMinutes = showHours || deltaParts.minutes > 0;

  const textParts: string[] = [];
  if (showDays) {
    textParts.push(`${min2Digits(deltaParts.days)} ${deltaParts.days === 1 ? 'day' : 'days'}`);
  }
  if (showHours) {
    textParts.push(`${min2Digits(deltaParts.hours)} ${deltaParts.hours === 1 ? 'hour' : 'hours'}`);
  }
  if (showMinutes) {
    textParts.push(`${min2Digits(deltaParts.minutes)} ${deltaParts.minutes === 1 ? 'min' : 'mins'}`);
  }
  textParts.push(`${min2Digits(deltaParts.seconds)} ${deltaParts.seconds === 1 ? 'sec' : 'secs'}`);

  return textParts.join(' ');
}
