import logo from '../../assets/logo.svg';
import './Spinner.scss';

interface Props {
  spinnerClass?: string;
}

export function Spinner(props: Props) {
  return (
    <div id="container" className="spinner-container">
      <img className={`spinner ${props.spinnerClass ?? ''}`} src={logo} />
    </div>
  );
}
