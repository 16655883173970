import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { mainSuite } from '@services/ServiceFactory';
import { SvsNavbarEvent } from '@storyverseco/svs-navbar';
import { debug } from '@common/LogWrapper';

const log = debug('app:hooks:usenavbarpagechanger');

export function useNavbarPageChanger() {
  const navigate = useNavigate();
  useEffect(() => {
    function onPageRequested({ path }) {
      log(`requested to change to "${path}"`);
      // absolute url, replace location
      const isUrl = path.startsWith('http');
      if (isUrl) {
        window.location.href = path;
      } else {
        // otherwise navigate to local route
        navigate(path);
      }
    }
    mainSuite.navbarService.on(SvsNavbarEvent.PageRequested, onPageRequested);

    return () => {
      mainSuite.navbarService.off(SvsNavbarEvent.PageRequested, onPageRequested);
    };
  }, [navigate]);
}
