import { CSSProperties, MouseEvent, useMemo } from 'react';
import { mainSuite } from '@services/ServiceFactory';
import { TpAccount, TpReason, TpStatus } from '@services/tokenproof/TokenproofService';
import { useClassName } from '@hooks/useClassName';
import { debug } from '@common/LogWrapper';
import { useMintState } from '@context/mint/MintContext';
import './TokenproofButton.scss';

const log = debug('app:components:TokenproofButton');

export type TokenproofButtonProps = {
  overrideOnClick?: () => void;
  onPreLogin?: (e: MouseEvent<HTMLButtonElement>) => void;
  onLoginSuccess?: (account: TpAccount) => void;
  onLoginCanceled?: (status: string, reason: string) => void;
  onLoginError?: (err: any) => void;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  policyId?: string;
};

export function TokenproofButton({
  overrideOnClick,
  onPreLogin,
  onLoginSuccess,
  onLoginCanceled,
  onLoginError,
  style,
  className,
  disabled,
  policyId,
}: TokenproofButtonProps) {
  const tokenproofService = mainSuite.tokenproofService;
  const classNames = useClassName('tokenproof-button', className);
  const mintState = useMintState();

  async function login(e: MouseEvent<HTMLButtonElement>) {
    if (overrideOnClick) {
      overrideOnClick();
      return;
    }

    onPreLogin?.(e);
    try {
      const account = await tokenproofService.login({
        mintPageState: mintState.pageState,
        tokenType: mintState.sale?.tokenType,
        policyId: policyId ?? mintState.sale?.tokenproofPolicyId,
      });
      if (account) {
        onLoginSuccess?.(account);
      } else {
        const status = tokenproofService.getStatus();
        const reason = tokenproofService.getReason();
        onLoginCanceled?.(status, reason);
      }
    } catch (err) {
      log('Error occurred while logging in', err);
      onLoginError(err);
    }
  }

  return (
    <button className={classNames} style={style} onClick={login} disabled={disabled}>
      <img className="tokenproof-button-icon" src="https://cdn.tokenproof.xyz/img/tokenproofIconWhite.png" />
      <span className="tokenproof-button-label">Authenticate with tokenproof</span>
    </button>
  );
}

export default TokenproofButton;
