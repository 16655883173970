import { useRef, useEffect, useMemo, useState } from 'react';
import { useClassName } from '@hooks/useClassName';
import { debug } from '@common/LogWrapper';
import './VimeoReactPlayer.scss';

const log = debug('app:components:VimeoReactPlayer');

export type VimeoReactPlayerProps = React.HTMLProps<HTMLDivElement> & {
  url: string;
  autoPlay?: never;
  autoPlayType?: 'always' | 'inview' | 'never';
  iframeProps?: React.HTMLProps<HTMLIFrameElement>;
};

export function VimeoReactPlayer({ url, autoPlayType, className, loop, iframeProps, ...props }: VimeoReactPlayerProps) {
  const classNames = useClassName('vimeo-react-player', className);
  const videoFrameRef = useRef<HTMLIFrameElement>();

  // recalculate videoUrl
  const videoUrl = useMemo(() => {
    const urlObj = new URL(url);

    // set loop query param
    if (loop) {
      urlObj.searchParams.set('loop', '1');
    } else {
      urlObj.searchParams.delete('loop');
    }

    // mute so autoplay is allowed
    if (autoPlayType === 'always' || autoPlayType === 'inview') {
      urlObj.searchParams.set('muted', '1');
    }
    // if muted was already set, then it should be kept muted

    return urlObj.toString();
  }, [autoPlayType, loop, url]);

  useEffect(() => {
    if (!autoPlayType || autoPlayType === 'never') {
      return;
    }

    const videoFrame = videoFrameRef.current;
    let unobserve: () => void = null;
    let stale = false;

    const loadVimeoPlayer = async () => {
      const { default: VimeoPlayer } = await import('@vimeo/player');
      if (stale) {
        return;
      }

      const player = new VimeoPlayer(videoFrame);

      if (autoPlayType === 'inview') {
        const observerEntryCallback = (entry: IntersectionObserverEntry) => {
          if (entry.target !== videoFrame) {
            return;
          }

          try {
            if (entry.isIntersecting) {
              player.play().catch((e) => {
                log('Vimeo player play error:', e.message);
              });
            } else {
              player.pause().catch((e) => {
                log('Vimeo player pause error:', e.message);
              });
            }
          } catch (e) {
            log('Error occurred during vimeo player access:', e);
          }
        };
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach(observerEntryCallback);
          },
          {
            threshold: 0.3,
          },
        );

        observer.observe(videoFrame);
        unobserve = () => {
          observer.unobserve(videoFrame);
          observer.disconnect();
        };
      } else {
        // autoPlayType === 'always'
        player.play();
      }
    };

    loadVimeoPlayer();

    return () => {
      stale = true;
      unobserve?.();
    };
  }, [videoUrl, autoPlayType]);

  return (
    <section {...props} className={classNames}>
      <iframe src={videoUrl} ref={videoFrameRef} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen {...iframeProps} />
    </section>
  );
}

export default VimeoReactPlayer;
