import { GameSiteUrlParams } from '@storyverseco/svs-consts';
import React, { MutableRefObject, Ref, useImperativeHandle, useMemo } from 'react';
import { useMintState } from '@context/mint/MintContext';
import { environment } from '@environment';
import { HostedApp } from '@components/hosted-app/HostedApp';
import VimeoReactPlayer from '@components/vimeo-react-player/VimeoReactPlayer';
import { useClassName } from '@hooks/useClassName';

export const defaultVimeoUrl = 'https://player.vimeo.com/video/790937705?color=2B30F5&muted=1';

export enum MintVideoContentType {
  Vimeo = 'vimeo',
  Viewer = 'viewer',
}

export type MintVideoContentRef = {
  type: MintVideoContentType;
};

export type MintVideoContentProps = {
  requireTokens?: boolean;
  vimeoUrl?: string;
  className?: string;
  storyClassName?: string;
  vimeoClassName?: string;
  type?: MintVideoContentType;
};

function BaseMintVideoContent(
  { requireTokens = false, vimeoUrl, className, storyClassName, vimeoClassName, type }: MintVideoContentProps,
  ref: Ref<MintVideoContentRef>,
) {
  const mintState = useMintState();
  const allClassNames = useClassName('mint-video-content', className);
  const storyClassNames = useClassName('mint-story-content', storyClassName, allClassNames);
  const vimeoClassNames = useClassName('mint-vimeo-content', vimeoClassName, allClassNames);

  const [hostedAppUrl] = useMemo(() => {
    if (!mintState.storyKey) {
      return [null, null];
    }

    const viewSearchParams = new URLSearchParams();
    viewSearchParams.append(GameSiteUrlParams.WalletAddress, mintState.storyKey.address);
    viewSearchParams.append(GameSiteUrlParams.StoryId, mintState.storyKey.id);
    viewSearchParams.append(GameSiteUrlParams.Ai, 'true');
    return [`${environment.viewUrl}?${viewSearchParams.toString()}`, `/view/${mintState.storyKey.address}/${mintState.storyKey.id}/ai`];
  }, [mintState.storyKey]);

  const { url, contentType } = useMemo(() => {
    // if parameters were passed, just return them
    if (type) {
      return {
        contentType: type,
        url: vimeoUrl || defaultVimeoUrl,
      };
    }

    // #1, there's a special video placeholder
    if (mintState.sale?.saleMedia?.sharePreviewVimeoUrl) {
      return {
        url: mintState.sale?.saleMedia?.sharePreviewVimeoUrl,
        contentType: MintVideoContentType.Vimeo,
      };
    }

    // #2, if no sharePreviewVimeoUrl, and we have a story url, show viewier
    if (hostedAppUrl && (!requireTokens || mintState.eligibleAdded)) {
      return {
        url: null,
        contentType: MintVideoContentType.Viewer,
      };
    }

    // #3, if none of above, show sale vimeo
    if (mintState.sale?.saleMedia?.vimeoUrl) {
      return {
        url: mintState.sale?.saleMedia?.vimeoUrl,
        contentType: MintVideoContentType.Vimeo,
      };
    }

    // #4 basically a fallback, show default storyverse video
    return {
      url: defaultVimeoUrl,
      type: MintVideoContentType.Vimeo,
    };
  }, [hostedAppUrl, requireTokens, mintState.eligibleAdded, type, vimeoUrl]);

  useImperativeHandle(
    ref,
    () => ({
      type: contentType,
    }),
    [contentType],
  );

  if (contentType === MintVideoContentType.Viewer) {
    return <HostedApp src={hostedAppUrl} className={storyClassNames} />;
  }

  return <VimeoReactPlayer url={url} className={vimeoClassNames} autoPlayType="inview" loop />;
}

export const MintVideoContent = React.forwardRef(BaseMintVideoContent);
