import Sale, { SaleDates } from './Sale';
import { debug } from './LogWrapper';

const log = debug('app:common:SaleUtils');

export const saleKindOffset = 2;
export const saleKindMask = 0b11 << saleKindOffset;
export const relativeMask = 0b11;

export enum SaleDateFlag {
  None = 0,

  // relative to time
  Before = 0b01,
  During = 0b10,
  After = 0b11,

  /*
  sale kind
  */
  WhitelistSignUp = 0b10 << saleKindOffset,
  WhitelistMint = 0b11 << saleKindOffset,
  PublicMint = 0b01 << saleKindOffset,

  // compound flags

  // whitelist signup
  BeforeWhitelistSignUp = Before | WhitelistSignUp,
  DuringWhitelistSignUp = During | WhitelistSignUp,
  /**
   * Only happens when there is no whitelist or public mint dates.
   */
  AfterWhitelistSignUp = After | WhitelistSignUp,

  // whitelist mint
  BeforeWhitelistMint = Before | WhitelistMint,
  DuringWhitelistMint = During | WhitelistMint,
  /**
   * Only happens when there is no public mint dates.
   */
  AfterWhitelistMint = After | WhitelistMint,

  // public mint
  BeforePublicMint = Before | PublicMint,
  DuringPublicMint = During | PublicMint,
  AfterPublicMint = After | PublicMint,
}

console.log({
  None: SaleDateFlag.None,

  // relative to time
  Before: SaleDateFlag.Before,
  During: SaleDateFlag.During,
  After: SaleDateFlag.After,

  /*
  sale kind
  */
  WhitelistSignUp: SaleDateFlag.WhitelistSignUp,
  WhitelistMint: SaleDateFlag.WhitelistMint,
  PublicMint: SaleDateFlag.PublicMint,

  // compound flags

  // whitelist signup
  BeforeWhitelistSignUp: SaleDateFlag.BeforeWhitelistSignUp,
  DuringWhitelistSignUp: SaleDateFlag.DuringWhitelistSignUp,
  /**
   * Only happens when there is no whitelist or public mint dates.
   */
  AfterWhitelistSignUp: SaleDateFlag.AfterWhitelistSignUp,

  // whitelist mint
  BeforeWhitelistMint: SaleDateFlag.BeforeWhitelistMint,
  DuringWhitelistMint: SaleDateFlag.DuringWhitelistMint,
  /**
   * Only happens when there is no public mint dates.
   */
  AfterWhitelistMint: SaleDateFlag.AfterWhitelistMint,

  // public mint
  BeforePublicMint: SaleDateFlag.BeforePublicMint,
  DuringPublicMint: SaleDateFlag.DuringPublicMint,
  AfterPublicMint: SaleDateFlag.AfterPublicMint,
});

export function separateSaleDateFlags(flags: SaleDateFlag): { relative: SaleDateFlag; saleKind: SaleDateFlag } {
  return {
    relative: flags & relativeMask,
    saleKind: flags & saleKindMask,
  };
}

export function getRelativeFlagFromDates(dates: SaleDates, time: number): SaleDateFlag {
  const startTime = new Date(dates.startDate).getTime();
  const endTime = dates.endDate ? new Date(dates.endDate).getTime() : null;

  if (time < startTime) {
    return SaleDateFlag.Before;
  }

  if (!endTime || time < endTime) {
    return SaleDateFlag.During;
  }

  return SaleDateFlag.After;
}

export function getSaleDateFlagsFromTime(sale: Sale, time = Date.now()): SaleDateFlag {
  let saleState = SaleDateFlag.None;
  let relative = SaleDateFlag.None;

  const saleStateDateTuples: [SaleDateFlag, SaleDates][] = [
    [SaleDateFlag.PublicMint, sale.publicMint],
    [SaleDateFlag.WhitelistMint, sale.whitelist?.mint],
    [SaleDateFlag.WhitelistSignUp, sale.whitelist?.signUp],
  ];

  for (const [currentSaleState, dates] of saleStateDateTuples) {
    if (!dates) {
      continue;
    }
    const currentRelative = getRelativeFlagFromDates(dates, time);
    // prioritize SaleDateFlag.Before of previous existing saleState
    // if this current relative is SaleDateFlag.After
    if (currentRelative === SaleDateFlag.After && relative === SaleDateFlag.Before) {
      // we can bail here already, no need to check other dates
      break;
    }

    // by this point, our relative should be this current sale state
    // and setup for next check and potentially our final result
    saleState = currentSaleState;
    relative = currentRelative;

    // if During or After, we can bail, no need to check other dates
    if (relative !== SaleDateFlag.Before) {
      break;
    }

    // otherwise, check with next sale state
  }

  return saleState | relative;
}

export function getDatesFromSaleKind(sale: Sale, saleKind: SaleDateFlag) {
  // in case saleKind isn't purely saleKind
  switch (saleKind & saleKindMask) {
    case SaleDateFlag.WhitelistSignUp:
      return sale.whitelist?.signUp;
    case SaleDateFlag.WhitelistMint:
      return sale.whitelist?.mint;
    case SaleDateFlag.PublicMint:
      return sale.publicMint;
    default:
      return null;
  }
}

export function salePrioWeightSorter(sales: Sale[], time = Date.now()): Sale[] {
  // map sale date flags of each sale for filtering reference
  const saleFlagsMap: Record<string, SaleDateFlag> = sales.reduce(
    (map, sale) => ({
      ...map,
      [sale.saleId]: getSaleDateFlagsFromTime(sale, time),
    }),
    {},
  );

  /*
  Prioritize public mint, whitelist mint, then whitelist signup
  */
  const saleKindPrioOrder = [SaleDateFlag.PublicMint, SaleDateFlag.WhitelistMint, SaleDateFlag.WhitelistSignUp];

  /*
  Prioritize During, then Before
  */
  const relativePrioOrder = [SaleDateFlag.During, SaleDateFlag.Before];

  const sortedSales = sales.slice(); // shallow clone
  sortedSales.sort((saleA, saleB) => {
    // featured above anything else, including expired
    if (saleA.featured && !saleB.featured) {
      return -1;
    }
    if (!saleA.featured && saleB.featured) {
      return 1;
    }

    const saleAFlags = saleFlagsMap[saleA.saleId];
    const saleBFlags = saleFlagsMap[saleB.saleId];

    const { saleKind: saleKindA, relative: relativeA } = separateSaleDateFlags(saleAFlags);
    const { saleKind: saleKindB, relative: relativeB } = separateSaleDateFlags(saleBFlags);

    /*
    deprioritize expired sales.
    Anything with the After flag would be expired in some form or another,
    as Before flag implies there's an upcoming signup/mint
    and During flag means it's currently the signup/mint.
    */
    if (relativeA === SaleDateFlag.After && relativeB !== SaleDateFlag.After) {
      return 1;
    }
    if (relativeA !== SaleDateFlag.After && relativeB === SaleDateFlag.After) {
      return -1;
    }

    /*
    prioritize higher weights
    */
    const deltaWeight = saleB.saleWeight - saleA.saleWeight;
    if (deltaWeight !== 0) {
      return deltaWeight;
    }

    /*
    prioritize sale kinds according to priority order
    */
    for (const prioSaleKind of saleKindPrioOrder) {
      if (saleKindA === prioSaleKind && saleKindB !== prioSaleKind) {
        return -1;
      }
      if (saleKindA !== prioSaleKind && saleKindB === prioSaleKind) {
        return 1;
      }
    }

    /*
    prioritize relative according to priority order
    */
    for (const prioRelative of relativePrioOrder) {
      if (relativeA === prioRelative && relativeB !== prioRelative) {
        return -1;
      }
      if (relativeA !== prioRelative && relativeB === prioRelative) {
        return 1;
      }
    }

    // they have the same saleKind and relative at this point

    /*
    Compare times
    */
    const saleDatesA = getDatesFromSaleKind(saleA, saleKindA);
    const saleDatesB = getDatesFromSaleKind(saleB, saleKindB);

    // they have the same relative
    if (relativeA === SaleDateFlag.During) {
      // if one has no end date, prioritize the other
      if (saleDatesA?.endDate && !saleDatesB?.endDate) {
        return -1;
      }
      if (!saleDatesA?.endDate && saleDatesB?.endDate) {
        return 1;
      }
      // if both have end dates, prioritize the earlier one
      if (saleDatesA?.endDate && saleDatesB?.endDate) {
        const endTimeA = new Date(saleDatesA.endDate).getTime();
        const endTimeB = new Date(saleDatesB.endDate).getTime();
        const deltaEndTime = endTimeA - endTimeB;
        if (deltaEndTime !== 0) {
          return deltaEndTime;
        }
      }
    }

    // they have the same relative
    if (relativeA === SaleDateFlag.Before) {
      // if one has no dates, prioritize the other
      if (saleDatesA && !saleDatesB) {
        return -1;
      }
      if (!saleDatesA && saleDatesB) {
        return 1;
      }
      // if both have dates, prioritize the earlier one
      if (saleDatesA && saleDatesB) {
        const startTimeA = new Date(saleDatesA.startDate).getTime();
        const startTimeB = new Date(saleDatesB.startDate).getTime();
        const deltaStartTime = startTimeA - startTimeB;
        if (deltaStartTime !== 0) {
          return deltaStartTime;
        }
      }
    }

    // if both expired, choose the latest end date
    if (relativeA === SaleDateFlag.After) {
      // if one has no end date, prioritize the other
      if (saleDatesA?.endDate && !saleDatesB?.endDate) {
        return -1;
      }
      if (!saleDatesA?.endDate && saleDatesB?.endDate) {
        return 1;
      }

      // if both have end dates, prioritize the latest one (closest to current time)
      if (saleDatesA?.endDate && saleDatesB?.endDate) {
        const endTimeA = new Date(saleDatesA.endDate).getTime();
        const endTimeB = new Date(saleDatesB.endDate).getTime();
        const deltaEndTime = endTimeB - endTimeA;
        if (deltaEndTime !== 0) {
          return deltaEndTime;
        }
      }
    }

    // both are exactly the same at this point
    return 0;
  });

  return sortedSales;
}

/**
 * Extend individual sales with partial sale data, using `saleId` as the key
 * for extending base sales. Only sale IDs in `baseSales` will be used.
 *
 * @param baseSales The base sale data.
 * @param restModSales partial sale data for extending base sale data.
 * @returns extended sales.
 */
export function extendSales(baseSales: Sale[], ...restModSales: Partial<Sale>[][]): Sale[] {
  const modSales = restModSales.flat();
  const modSaleMap = modSales.reduce(
    (map, sale) => ({
      ...map,
      [sale.saleId]: {
        ...map[sale.saleId],
        ...sale,
      },
    }),
    {} as Record<string, Partial<Sale>>,
  );

  // only include sales that are in the base sales.
  const sales = baseSales.map((sale) => {
    const modSale = modSaleMap[sale.saleId];
    if (!modSale) {
      return sale;
    }

    return {
      ...sale,
      ...modSale,
    };
  });

  return sales;
}

export function mergeData(origin: Sale[], target: Sale[]) {
  const mergedOrigin = origin.map((sale) => {
    const targetSale = target.find((s) => s.saleId === sale.saleId);
    if (!targetSale) {
      return sale;
    }
    return {
      ...sale,
      ...targetSale,
    };
  });
  const remainder = target.filter((sale) => mergedOrigin.every((s) => s.saleId !== sale.saleId));
  return [...mergedOrigin, ...remainder];
}
