import React, { createRef, FormEvent } from 'react'

import './ContactSection.styles.scss'
import { Section } from '@components/Section'
import { useAppState } from '@context/AppContext'

export const ContactSection = () => {
  const appState = useAppState()
  const formRef = createRef<HTMLFormElement>()
  const nameInputRef = createRef<HTMLInputElement>()
  const emailInputRef = createRef<HTMLInputElement>()
  const subjectInputRef = createRef<HTMLInputElement>()
  const bodyInputRef = createRef<HTMLTextAreaElement>()
  const submitButtonRef = createRef<HTMLButtonElement>()

  const formSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault() // Prevent page from reloading

    // todo: Submit form here


    // After form submission
    formRef.current.reset()

    nameInputRef.current.disabled = true
    emailInputRef.current.disabled = true
    subjectInputRef.current.disabled = true
    bodyInputRef.current.disabled = true

    submitButtonRef.current.disabled = true
    submitButtonRef.current.innerText = 'Submitted'
  }

  const formChange = () => {
    submitButtonRef.current.disabled = !formRef.current.checkValidity()
  }

  if (!appState.features.showContactsSection) {
    return null
  }

  return (
    <Section className='ContactSection' id='contact'>
      <h1>Contact us</h1>

      <form onSubmit={formSubmit} onChange={formChange} ref={formRef}>
        <input type='text' placeholder='Your Name' ref={nameInputRef} required />
        <input type='email' placeholder='Your Email' ref={emailInputRef} required />
        <input type='text' placeholder='Subject' ref={subjectInputRef} required />
        <textarea placeholder='Message' ref={bodyInputRef} rows={5} required />

        <button type='submit' disabled ref={submitButtonRef}>Submit</button>
      </form>
    </Section>
  )
}
