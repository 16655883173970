import { environment } from '../environments/environment';
import { ApiType } from '../services/ApiType';

export function hasProvider(): boolean {
  if (environment.userServiceApi === ApiType.Mock) {
    return true;
  }
  return !!window.ethereum;
}

export const noChainId = -1;
export const mockChainId = 999999;

export function hasSameChainId(id1: number, id2: number): boolean {
  return id1 !== noChainId && id2 !== noChainId && id1 === id2;
}

export function createMetaMaskDeeplink(path: string): string {
  return `https://metamask.app.link/dapp/${window.location.hostname}${path}`
}
