import { MintCountdown } from './MintCountdown'

export function PreWhitelistSection() {
  return (
    <div className="prewhitelist-section">
      <MintCountdown />
      <p>Pre whitelist</p>
    </div>
  );
}
