

export type MockLoginUser = {
  address: string,
  name: string,
  service?: boolean
}

let userIdPool = 1;

function createAddress() {
  const userId = userIdPool++;
  return `0x${userId.toString(16).padStart(2, '0')}`;
}

export const mockUsers: MockLoginUser[] = [
  {
    address: createAddress(),
    name: 'Service Account',
    service: true
  },
  {
    address: createAddress(),
    name: 'Account A'
  },
  {
    address: createAddress(),
    name: 'Account B'
  }
];
  
export const serviceAddressKey = 'serviceAddress';

export const usersDataKey = 'usersData';
