
export enum UserPermission {
  User = 'user:user',
  Admin = 'admin:admin',
  Service = 'admin:service'
}

export function hasPermission(userPerms: UserPermission[], permission: UserPermission) {
  return userPerms.indexOf(permission) !== -1;
}

export function hasEveryPermission(userPerms: UserPermission[], permissions: UserPermission[]) {
  return permissions.every(permission => hasPermission(userPerms, permission));
}
