export const TeamMembers = {
  head: [
    {
      name: 'Michael Carter',
      role: 'Founder'
    },
    {
      name: 'Justin Waldron',
      role: 'Founder'
    }
  ],
  normal: [
    {
      name: 'Hartford',
      role: 'Head Of Operations'
    },
    {
      name: 'Dpid',
      role: 'Studio Lead'
    },
    {
      name: 'Kanto',
      role: 'Marketing Director'
    },
    {
      name: 'Mondo',
      role: 'Art Director'
    },
    {
      name: 'Holmes',
      role: 'UI Lead'
    },
    {
      name: 'Herberh2',
      role: 'Product Manager'
    },
    {
      name: 'Kakarotto',
      role: 'Community Manager'
    },
    {
      name: 'Anastasiia',
      role: 'BD / Partnerships'
    },
    {
      name: 'Elko',
      role: 'VP Of BD / Partnerships'
    },
    {
      name: 'JB',
      role: 'Developer'
    },
    {
      name: 'cheshirecatalyst',
      role: 'Developer'
    },
    {
      name: 'Mukunda',
      role: 'Developer'
    },
    {
      name: 'probably_kira',
      role: 'Developer'
    }
  ]
}
