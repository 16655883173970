import AvatarImg from './avatar.png';
import ThumbImg from './thumb.png';

export default {
  avatar: AvatarImg,
  thumb: ThumbImg,
  author: 'Mirabel Starling',
  url: '/barmanronan',
  footnote: null,
};
