import { useState, useEffect } from 'react';
import { useAppState } from '@context/AppContext';

export type SearchParamItem = {
  key: string,
  value: string | undefined | null,
}

export function usePassthroughParams(paramKeys: string[] = [], includedParams?: SearchParamItem[]) {
  const appState = useAppState()
  const [searchParamsStr, setSearchParamsStr] = useState<string | null>(null)

  useEffect(() => {
    if (!appState?.initialSearchParams) {
      return;
    }
    if (searchParamsStr) {
      return;
    }

    const searchParams = new URLSearchParams();
    if (includedParams) {
      for (const param of includedParams) {
        searchParams.append(param.key, param.value)
      }
    }
    for (const paramKey of paramKeys) {
      if (appState.initialSearchParams.has(paramKey)) {
        searchParams.append(paramKey, appState.initialSearchParams.get(paramKey))
      }
    }
    setSearchParamsStr(searchParams.toString());
  }, [
    appState,
    searchParamsStr,
    setSearchParamsStr,
    // stringify array if used as dependency
    // https://github.com/facebook/react/issues/14476#issuecomment-471199055
    JSON.stringify(paramKeys),
    JSON.stringify(includedParams),
  ])

  return searchParamsStr
}
